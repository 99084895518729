import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  TooltipComponent,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Progress, Table, Select, Input, Flex, Spin } from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import quotationApis from "../../api/master/quotations";
import moment from "moment";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
  headerStatus: string;
  orgName: string;
}

const RFQView: FC = () => {
  const [sm, setSm] = useState(false);

  const [widgetData, setWidgetData] = useState<WidgetData[]>([]);

  useEffect(() => {
    fetchCounts();
  }, []);

  const fetchCounts = async () => {
    try {
      const { response, status } = await quotationApis.fetchCounts();
      if (status) {
        const counts = response.data;
        const colors = ["#OO65BD", "#20639B", "#FA991C", "#F6D55C", "#OOCC99"];
        let formatData = counts.map((item: any, index: number) => ({
          title: item.title,
          key: item.title,
          value: item.count,
          percentage: item.progress.toFixed(0),
          color: colors[index % colors.length],
        }));
        setWidgetData(formatData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate("/view-rfqs-details", { state: { rfq } });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 200,
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleRFQClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "orgName",
      key: "orgName",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      // fixed: "left",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`Description-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`Description-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
      align: "left",
    },
    {
      title: "Preferred Brands / Make",
      dataIndex: "preferredBrands",
      key: "preferredBrands",
      width: 200,
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      align: "left",
      render: (text: string) => {
        const formattedDate = dayjs(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    // {
    //   title: "Cost Center",
    //   dataIndex: "costCenterName",
    //   key: "costCenterName",
    //   width: 250,
    //   sorter: (a, b) => a.costCenterName.localeCompare(b.costCenterName),
    //   align: "left",
    //   render: (text: string, record: any, index: any) => {
    //     return (
    //       <>
    //         <span id={`costCenterName-tooltip-${index}`}>
    //           {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
    //         </span>
    //         {text?.length > 20 && (
    //           <TooltipComponent
    //             iconClass="info-circle text-info"
    //             icon="info"
    //             id={`costCenterName-tooltip-${index}`}
    //             direction="top"
    //             text={text}
    //             containerClassName={""}
    //           />
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: "Payment Terms",
      dataIndex: "paymentTerms",
      key: "paymentTerms",
      width: 150,
      fixed: "right",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, record) => (
        <span style={{ color: record.statusColor }}>{text}</span>
      ),
      width: 150,
      align: "left",
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-rfqs"
      title="Quotations"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <QuotationsAside updateSm={setSm} sm={sm} />
        </div>
      }
      extra={{
        tableScrollX: 1100,
      }}
      topRightJSX={
        <Button
          color="primary"
          onClick={() => {
            navigate("/self-quotation");
          }}
        >
          <Icon name="plus"></Icon>
          <span>New Quote</span>
        </Button>
      }
      widget={{
        isActive: true,
        list: widgetData,
      }}
    />
  );
};

export default RFQView;
