import makeApiCall from "../master/axiosIndex";

const purchaseOrderApis = {
  fetchPurchaseOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllSupplierOrders",
    }),
  fetchOrder: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/order-vs-billing/fetchOrder?orderNumber=${id}`,
    }),
  fetchWidgetCount: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/countOrdersByStatus",
    }),
  fetchAllUserApprovalOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllUserApprovalOrders",
    }),
  purchaseOrderUpdate: (orderNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/order-vs-billing/amendmentOrder/${orderNumber}`,
      data,
    }),
  fetchAllSupplierPendingOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllSupplierPendingOrders",
    }),
  acceptOrder: (orderNumber: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/acceptOrder?orderNumber=${orderNumber}`,
    }),
  rejectOrder: (orderNumber: string, rejectedReason: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/rejectOrder?orderNumber=${orderNumber}&rejectedReason=${rejectedReason}`,
    }),
  fetchAllSupplierOrdersForInvoice: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllSupplierOrdersForInvoice",
    }),
  sendOrderEmailNotification: (orderNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/order-vs-billing/sendOrderEmailNotification/${orderNumber}`,
      data,
    }),
  fetchAllOrgOrdersWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/order-vs-billing/fetchAllOrdersWithPagination",
      method: "get",
      params,
    }),
  fetchAllSupplierPendingOrdersWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/order-vs-billing/fetchAllSupplierPendingOrdersWithPagination",
      method: "get",
      params,
    }),
  fetchAllSupplierOrdersForInvoiceWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/order-vs-billing/fetchAllSupplierOrdersForInvoiceWithPagination",
      method: "get",
      params,
    }),
  fetchDerivedOrderStatusDropdown: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchDerivedOrderStatusDropdown",
    }),
};

export default purchaseOrderApis;
