import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, RSelect } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import FormTextArea from "../../form-components/TextAreaComponent";
import miscService from "../../../api/misc/misc";
import { Button } from "reactstrap";
import CropperComponent from "../../components/CropperComponent";
const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const [orgTypeOptions, setOrgTypeOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([
    { label: "Bussiness", value: "Bussiness" },
    { label: "Individual", value: "Individual" },
  ]);
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const orgLogoValue = watch("orgLogo");
  const toggleModal = () => setModal(!modal);

  const handleImageSave = (image: string) => {
    setValue("orgLogo", image);
    toggleModal();
  };

  useEffect(() => {
    console.log("orgLogoValue", orgLogoValue);
    setCroppedImage(orgLogoValue);
  }, [orgLogoValue]);
  const getOrgTypes = async () => {
    try {
      const orgTypes = await miscService.getOygTypes();
      if (orgTypes.status && orgTypes.data.length > 0) {
        const transformedData = orgTypes.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.orgtype,
        }));
        setOrgTypeOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

  const submitForm = (data: any) => {
    props.next();
  };

  useEffect(() => {
    getOrgTypes();
  }, []);

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-4">
          <FormSelect
            name="customerType"
            label="Customer Type"
            required={true}
            options={customerOptions}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="orgName"
            label="Organisation Name"
            required={true}
            // disabled={FullFormDetails?.gstinVerified == "true"}
            type="text"
            minLength={2}
            // maxLength={30}
            placeholder="Enter Organisation name"
          />
        </div>
        <div className="col-md-4">
          <FormSelect
            name="orgType"
            label="Organisation Type"
            options={orgTypeOptions}
            required={true}
            placeholder="-Select-"
          />
        </div>
        <div className="col-md-4" style={{ display: "none" }}>
          <FormInput
            name="orgLogo"
            label="Organisation Logo"
            required={false}
            type="text"
            placeholder="Upload Logo"
          />
        </div>
        <div
          className="col-md-4"
          style={{ marginTop: "28px", display: "flex" }}
        >
          {!croppedImage && (
            <Button color="btn-dim btn" onClick={toggleModal}>
              <span>Upload Logo </span>
              <Icon name="upload-cloud" />
            </Button>
          )}
          {croppedImage && (
            <div style={{ marginTop: "10px" }}>
              <div
                style={{
                  marginLeft: "25px",
                  height: "60px",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                }}
              >
                <img
                  src={croppedImage}
                  alt="Cropped"
                  style={{ height: "60px" }}
                />
              </div>
              <div>
                <Button color="btn-dim btn" onClick={toggleModal}>
                  <span>Change</span>
                  <Icon
                    name="upload-cloud"
                    className="pointer"
                    onClick={toggleModal}
                  />
                </Button>
                <Button
                  color="btn-dim btn"
                  onClick={() => {
                    setCroppedImage(null);
                    setValue("orgLogo", null);
                  }}
                >
                  <span>Cancel</span>
                  <Icon
                    name="cross"
                    className="pointer"
                    onClick={() => {
                      setCroppedImage(null);
                      setValue("orgLogo", null);
                    }}
                  />
                </Button>
              </div>
            </div>
          )}
          <FormInput name="orgLogo" type="hidden" label="" />
        </div>
        {/* <div className="col-md-3">
              <FormSelect
                name="supplierID"
                label="Preferred Suppliers"
                required={true}
                options={supplierOptions}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                name="averageLeadDays"
                label="Delivery Lead Days (AVG)"
                type="number"
                placeholder="Enter Delivery Lead Days"
                required={true}
              />
            </div> */}
        {/* <div className="col-md-12">
          <FormTextArea
            name="description"
            label="Description"
            placeholder="Enter Description"
            required={true}
          />
        </div> */}
      </Row>
      <CropperComponent
        modal={modal}
        toggle={toggleModal}
        onSave={handleImageSave}
      />
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default GeneralInformation;
