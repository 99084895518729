import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  Button,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap";
import { Icon } from "../Component";
import { Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../../pages/form-components/InputComponent";
import { ReactTyped } from "react-typed";
import { FormProvider, useForm } from "react-hook-form";
import miscService from "../../api/misc/misc";
import { useDispatch } from "react-redux";
import { setUploadHighlight } from "../../store/slices/misc/highlightSlice";
import exportFromJSON from "export-from-json";
import supplierApis from "../../api/master/supplier";

const Configuration: any = {
  "material-list": {
    title: "Bulk Material Creation",
    requestType: "materialCreate",
  },
};

const BulkUpload = ({
  showAsButton = true,
  moduleKey,
}: {
  moduleKey?: any;
  showAsButton?: boolean;
}) => {
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [blockEdit, setBlockEdit] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    message: "",
  });
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods;
  const files = watch("attachment");

  useEffect(() => {
    setResponse({ status: "", message: "" });
  }, [files]);

  const [showModal, setShowModal] = useState(false);

  const fetchCategoryList = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          categoryName: item.category,
          categoryId: item.id.toString(),
        }));
        setCategories(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (moduleKey) {
      case "material-list":
        fetchCategoryList();
        break;
      default:
        alert("(Bulk upload useEffect ) Invalid module key " + moduleKey);
    }
  }, [moduleKey]);

  const uploadFile = async (data: any) => {
    try {
      const _file = data?.attachment?.[0];
      const formData: any = new FormData();
      formData.append(
        "filesData",
        JSON.stringify({
          module: "masters",
          requestType: Configuration[moduleKey].requestType,
          action: "Preview",
          reportId: "",
          processedExcelData: {},
        })
      );
      formData.append("files", _file);
      const res = await miscService.bulkUpload(formData);
      const { status, error, message } = res || {};
      if (status) {
        dispatch(setUploadHighlight(true));
        setResponse({
          status: "success",
          message: "File uploaded successfully",
        });
        return;
      }
      setResponse({
        status: "error",
        message: error,
      });
      setBlockEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ModalContent = ({ moduleKey }: any) => {
    switch (moduleKey) {
      case "material-list":
        return (
          <>
            <p>
              You're about to create multiple material records at once. To
              ensure a smooth import, please{" "}
              <a
                href="https://procurexuatstg.blob.core.windows.net/procurexuatblob/material_upload_template.xlsx"
                target="_blank"
                style={{
                  textDecoration: "underline",
                }}
              >
                Download Template
              </a>{" "}
              and fill in the required material data accurately and completely,
              then upload the file below. you can check{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  const exportType = exportFromJSON.types.csv;
                  exportFromJSON({
                    data: categories,
                    fileName: "Categories",
                    exportType,
                  });
                }}
                target="_self"
                style={{ textDecoration: "underline" }}
              >
                Categories
              </a>{" "}
              for reference
            </p>

            <div className="text-right mt-3"></div>
            <p>
              <strong>Note : </strong>
              All fields in the template are mandatory. Each material must have
              unique information to avoid errors during the import process.
            </p>

            <Col md={4}>
              {files && files[0]?.name && (
                <div className="form-group m-0">
                  <div className="form-label-wrapper">
                    <label className="form-label" htmlFor={"attachment"}>
                      File Upload Area
                    </label>
                  </div>
                  <div
                    className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                    style={{ borderRadius: "3px" }}
                  >
                    <span>{files && files[0]?.name}</span>
                    {!blockEdit && (
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: `${files && files[0]?.name ? "none" : "block"}`,
                }}
              >
                <FormInput
                  type="file"
                  // required
                  name="attachment"
                  label="File Upload Area"
                  placeholder="File Upload Area"
                  accept=".xlsx" // ,.xls,.csv
                />
              </div>
            </Col>
          </>
        );

      default:
        <></>;
    }
    return <></>;
  };

  return (
    <>
      {showAsButton ? (
        <UncontrolledDropdown size={"md"}>
          {" "}
          <div
            onClick={() => {
              setShowModal(true);
            }}
            className="btn btn-white btn-outline-light"
          >
            <Icon name="upload-cloud"></Icon>
            <span>Import</span>
          </div>
        </UncontrolledDropdown>
      ) : (
        <Link
          to={`#`}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <Icon name="upload-cloud"></Icon>
          <span>Import</span>
        </Link>
      )}
      <Modal
        title={Configuration[moduleKey]?.title}
        open={showModal}
        footer={null}
        onCancel={() => {
          setShowModal(false);
          setBlockEdit(false);
          methods.reset();
          setLoading(false);
          setResponse(() => ({
            status: "",
            message: "",
          }));
        }}
        width={"60%"}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((values) => {
              setResponse(() => ({
                status: "",
                message: "",
              }));
              setBlockEdit(true);
              setLoading(true);
              uploadFile(values);
            })}
          >
            
            <ModalContent moduleKey={moduleKey} />
            <Alert
              isOpen={response?.status === "error"}
              color="danger"
              className="start-0 w-100 py-1 mt-2"
              style={{
                top: 0,
                textAlign: "left",
                fontSize: "14px",
                cursor: "default",
              }}
            >
              <ReactTyped
                strings={[response?.message]}
                typeSpeed={30}
                showCursor={false}
              />
            </Alert>

            <Alert
              isOpen={response?.status === "success"}
              color="success"
              className="start-0 w-100 py-1 mt-2"
              style={{
                top: 0,
                textAlign: "left",
                fontSize: "14px",
                cursor: "default",
              }}
            >
              <p>
                Please check status of uploaded file in the{" "}
                <span
                  className="inlineTableCellLink"
                  onClick={() => {
                    navigate("/bulk-upload-list", {
                      state: {
                        tab: "Bulk Upload",
                      },
                    });
                  }}
                >
                  Uploads History
                </span>
              </p>
            </Alert>

            <Row className="mt-4">
              <Col>
                {response?.status != "success" && (
                  <ul className="actions clearfix d-flex">
                    <li>
                      {loading ? (
                        <Button color="primary" type="button" size="md">
                          <Spinner size="sm" color="light" />
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!files?.[0]}
                        >
                          Upload
                        </Button>
                      )}
                    </li>
                    {!loading && (
                      <li className="m-3 mt-0">
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowModal(false);
                            methods.reset();
                          }}
                          className="btn-dim btn btn-secondary"
                        >
                          Cancel
                        </Button>
                      </li>
                    )}
                  </ul>
                )}
              </Col>
            </Row>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default BulkUpload;
