import React, { useCallback, useEffect, useState } from "react";
import { set, useFieldArray, useFormContext } from "react-hook-form";
import { Row, Col, Button, Spinner } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import SignatureModal from "./SignatureModal";
import CropperComponent from "../../components/CropperComponent";
import misc from "../../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import { Form, Input, InputNumber, Table } from "antd";
import type { TableColumnsType } from "antd";
import MaterialModal from "./MaterialModal";
import FormInput from "../../form-components/InputComponent";
import {
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { formatCurrency } from "../../../utils/Utils";
import TaxConfigurationModal from "./TaxConfigurationModel";
import dayjs from "dayjs";
import BulkMaterialUpload from "../BulkMaterialUpload";

type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
  id: any;
}

const Materials: React.FC = (props: any) => {
  const [form2] = Form.useForm();
  const [editAdditionalCharges, setEditAdditionalCharges] = useState(true);
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [activeMaterial, setActiveMaterial] = useState<any>({
    action: null,
    index: null,
    item: null,
  });
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [showConfigureTaxModel, setShowConfigureTaxModel] =
    useState<boolean>(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [allCurrencyData, setAllCurrencyData] = useState<CurrencyData[]>([]);
  const [signature, setSignature] = useState({
    isActive: false,
    label: "Authorised Signatory",
    image: null,
  });
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "INR",
    currency_symbol: "",
    currency_format: "",
    example: "",
    id: "",
  });
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const { control, reset, setValue, watch, handleSubmit } = useFormContext();

  const previousFormDate = watch();

  useEffect(() => {
    console.log("previousFormDate", previousFormDate);
    const {
      taxConfigurationObj,
      signatureObj,
      currencyObj,
      additionalCharges,
    } = previousFormDate;
    if (additionalCharges) {
      const _additionalCharges = JSON.parse(additionalCharges);
      console.log(_additionalCharges);
      _additionalCharges?.forEach((field: any) => {
        form2.setFieldsValue({
          [`AdditionalCharges.${field.id}.name`]: field.name,
          [`AdditionalCharges.${field.id}.price`]: field.price,
        });
      });
      setEditAdditionalCharges(false);
      setAdditionalCharges(_additionalCharges);
    }

    if (taxConfigurationObj) {
      onConfigureTaxSave(JSON.parse(taxConfigurationObj), false);
    }
    if (signatureObj) {
      setSignature(JSON.parse(signatureObj));
    }
    if (currencyObj) {
      setCurrency(JSON.parse(currencyObj));
    }
  }, []);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "items",
  });

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      width: 150,
      render: (text) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Min Purchase Qty",
      dataIndex: "minQty",
      key: "minQty",
      width: 200,
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsn",
      key: "hsn",
      width: 200,
    },

    {
      title: "price",
      dataIndex: "price",
      key: "price",
      width: 150,
      render: (text) => formatCurrency(Number(text), "INR"),
    },
    {
      title: "Basic Amount",
      key: "amount",
      width: 150,
      render: (text, record) => {
        return (
          <p className="justify-end">
            {formatCurrency(Number(record?.quantity * record?.price), "INR")}
          </p>
        );
      },
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgstAmount",
          key: "cgstAmount",
          width: 150,
          render: (text: any, record: any) => {
            return (
              <p className="justify-end">
                {formatCurrency(Number(text), "INR")}
              </p>
            );
          },
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgstAmount",
          key: "sgstAmount",
          width: 150,
          render: (text: any, record: any) => {
            return (
              <p className="justify-end">
                {formatCurrency(Number(text), "INR")}
              </p>
            );
          },
        },
      ],
    },
    {
      title: "Line Total",
      key: "lineTotal",
      width: 150,
      render: (text, record: any) => {
        return (
          <p className="justify-end">
            {formatCurrency(Number(record?.lineTotal) || 0, "INR")}
          </p>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setActiveMaterial({
                action: "edit",
                index: index,
                item: record,
              });
            }}
          >
            <Icon name="edit" />
          </Button>
          <Button size="sm" color="" onClick={() => remove(index)}>
            <Icon name="trash" />
          </Button>
        </>
      ),
    },
  ]);

  const onConfigureTaxSave = (data: any, updateField = true) => {
    setValue("taxConfigurationObj", JSON.stringify(data));
    setTaxConfiguration(data);
    setShowConfigureTaxModel(false);
    if (updateField) {
      fields.map((item: any, index: number, array: any) => {
        if (data?.gstType == "IGST") {
          let igst = item.cgst;
          let igstPercentage = item.cgstPercentage * 2;
          let igstAmount = item.cgstAmount * 2;
          let cgst = null;
          let sgst = null;
          let cgstPercentage = null;
          let cgstAmount = null;
          let sgstPercentage = null;
          let sgstAmount = null;
          update(index, {
            ...item,
            igst,
            igstPercentage,
            igstAmount,
            cgst,
            sgst,
            cgstPercentage,
            cgstAmount,
            sgstPercentage,
            sgstAmount,
          });
        } else if (data?.gstType == "CGST+SGST") {
          let igst = null;
          let igstPercentage = null;
          let igstAmount = null;
          let cgst = item.igst;
          let sgst = item.igst;
          let cgstPercentage = item.igstPercentage / 2;
          let cgstAmount = item.igstAmount / 2;
          let sgstPercentage = item.igstPercentage / 2;
          let sgstAmount = item.igstAmount / 2;

          update(index, {
            ...item,
            igst,
            igstPercentage,
            igstAmount,
            cgst,
            sgst,
            cgstPercentage,
            cgstAmount,
            sgstPercentage,
            sgstAmount,
          });
        }
      });
    }

    setColumns((pre) => {
      // if (!data?.cess?.length) return pre;
      let newColArray = [...pre].slice(0, 6);
      let additionalCol: any = [];
      if (data?.gstType == "IGST") {
        additionalCol.push({
          title: "IGST",
          children: [
            {
              title: "%",
              dataIndex: "igstPercentage",
              key: "igstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "igstAmount",
              key: "igstAmount",
              width: 150,
              render: (text: any, record: any) => {
                return (
                  <p className="justify-end">
                    {formatCurrency(Number(text), currency?.currency_code)}
                  </p>
                );
              },
            },
          ],
        });
      } else {
        additionalCol.push({
          title: "CGST",
          children: [
            {
              title: "%",
              dataIndex: "cgstPercentage",
              key: "cgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "cgstAmount",
              key: "cgstAmount",
              width: 150,
              render: (text: any, record: any) => {
                return (
                  <p className="justify-end">
                    {formatCurrency(Number(text), currency?.currency_code)}
                  </p>
                );
              },
            },
          ],
        });
        additionalCol.push({
          title: "SGST",
          children: [
            {
              title: "%",
              dataIndex: "sgstPercentage",
              key: "sgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "sgstAmount",
              key: "sgstAmount",
              width: 150,
              render: (text: any, record: any) => {
                return (
                  <p className="justify-end">
                    {formatCurrency(Number(text), currency?.currency_code)}
                  </p>
                );
              },
            },
          ],
        });
      }
      if (data?.cess.length) {
        data.cess.forEach((item: any) => {
          if (!item.cessName) return;
          additionalCol.push({
            title: item.cessName,
            children: [
              {
                title: "%",
                dataIndex: item.cessName,
                key: item.cessName,
                width: 100,
                render: (text: any) => `${text || 0}%`,
              },
              {
                title: "Amount",
                dataIndex: `${item.cessName}Amount`,
                key: `${item.cessName}Amount`,
                width: 150,
              },
            ],
          });
        });
      }
      additionalCol.push({
        title: "Line Total",
        key: "lineTotal",
        width: 150,
        render: (text: any, record: any) => {
          return (
            <p className="justify-end">
              {formatCurrency(
                Number(record?.lineTotal),
                currency?.currency_code
              )}
            </p>
          );
        },
      });
      additionalCol.push({
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: (text: any, record: any, index: any) => (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setActiveMaterial({
                  action: "edit",
                  index: index,
                  item: record,
                });
              }}
            >
              <Icon name="edit" />
            </Button>
            <Button size="sm" color="" onClick={() => remove(index)}>
              <Icon name="trash" />
            </Button>
          </>
        ),
      });

      return newColArray.concat(additionalCol);
    });
  };

  const updateColumnsWithCurrencySymbol = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.dataIndex == "unitPrice" || col.dataIndex == "amount") {
          return {
            ...col,
            render: (text: any) => (
              <div className="justify-end">
                {formatCurrency(text, currency?.currency_code)}
              </div>
            ),
          };
        }
        if (col.key == "lineTotal") {
          return {
            title: "Line Total",
            key: "lineTotal",
            width: 150,
            render: (text: any, record: any) => {
              return (
                <p className="justify-end">
                  {formatCurrency(
                    Number(record?.lineTotal),
                    currency?.currency_code
                  )}
                </p>
              );
            },
          };
        }
        if (col.children) {
          let children = col.children.map((child: any) => {
            if (child.title == "Amount") {
              return {
                ...child,
                render: (text: any) => (
                  <div className="justify-end">
                    {formatCurrency(text, currency?.currency_code)}
                  </div>
                ),
              };
            }
            return child;
          });
          return {
            ...col,
            children,
          };
        }
        return col;
      });
    });
  }, [currency]);

  const addNewRow = () => {
    setActiveMaterial({
      action: "add",
      index: null,
      item: null,
    });
  };

  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status } = res;
      if (status) {
        let allCurrencyData = data.map((item: any) => {
          return {
            description: item.description,
            currency_code: item.currency_code,
            currency_symbol: item.currency_symbol,
            currency_format: item.currency_format,
            example: item.example,
            id: item.id,
          };
        });
        setAllCurrencyData(allCurrencyData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategorys = async () => {
    try {
      const { data, status } = await misc.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setMaterialCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const saveAdditionalCharges = () => {
    try {
      const newAdditionalCharges: any = [];
      additionalCharges.forEach((field: any, index: number) => {
        const name = form2.getFieldValue(`AdditionalCharges.${field?.id}.name`);
        const price = form2.getFieldValue(
          `AdditionalCharges.${field?.id}.price`
        );
        if (name && price) {
          newAdditionalCharges.push({
            name,
            price,
            id: field?.id,
          });
        }
      });
      setValue("additionalCharges", JSON.stringify(newAdditionalCharges));
      setAdditionalCharges(() => {
        return newAdditionalCharges;
      });
      if (newAdditionalCharges.length != 0) {
        setEditAdditionalCharges(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCategorys();
    fetchCurrencyData();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    setValue("signatureObj", JSON.stringify(signature));
  }, [signature]);

  useEffect(() => {
    updateColumnsWithCurrencySymbol();
  }, [currency, updateColumnsWithCurrencySymbol]);

  return (
    <>
      <Block>
        <form className="form-validate" onSubmit={handleSubmit(() => {})}>
          <Row>
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-between"
            >
              <button
                className="btn-dim btn btn-outline-primary"
                onClick={() => setShowConfigureTaxModel(true)}
                style={{ padding: "8px 12px", whiteSpace: "nowrap" }}
                type="button"
              >
                <Icon name="percent" /> &nbsp; &nbsp; Configure TAX
              </button>
              <div className="d-flex justify-content-end">
                {/* <BulkMaterialUpload
                  moduleKey="rfq-creation"
                  append={append}
                  extra={{ fields,taxConfiguration,formData: previousFormDate }}
                /> */}
              </div>
            </Col>
            <span style={{ display: "none" }}>
              <Col lg="4" className="inline-select">
                <label className="form-label" style={{ margin: "0" }}>
                  Currency
                </label>
                <FormSelect
                  name="currency"
                  label=""
                  options={allCurrencyData.map((item: any) => ({
                    label: item.description,
                    value: item.currency_code,
                  }))}
                  required={false}
                  placeholder="Indian Rupee"
                  onChange={(value) => {
                    const currency = allCurrencyData.find(
                      (item) => item.currency_code === value
                    );
                    if (!currency) return;
                    setValue("currencyObj", JSON.stringify(currency));
                    // alert(JSON.stringify(currency));
                    setCurrency(currency);
                  }}
                />
              </Col>
              <FormInput name="currencyObj" label="" type="text" />
              <FormInput name="taxConfigurationObj" label="" type="text" />
              <FormInput name="signatureObj" label="" type="text" />
              <Col
                lg="4"
                style={{ marginTop: "10px" }}
                className="inline-select"
              >
                <label className="form-label" style={{ margin: "0" }}>
                  Currency Format ( e.g ) :
                </label>
                <p
                  className="text-primary"
                  style={{ fontSize: "18px", marginLeft: "10px" }}
                >
                  {currency?.example || "₹1,23,456.78"}
                </p>
              </Col>
            </span>
          </Row>
          {showConfigureTaxModel && (
            <TaxConfigurationModal
              data={taxConfiguration}
              isOpen={showConfigureTaxModel}
              setIsOpen={setShowConfigureTaxModel}
              onSave={onConfigureTaxSave}
            />
          )}

          <Row>
            <div className="table-responsive poTable">
              <div style={{ width: screenSize - 120 + "px", margin: "auto" }}>
                {columns && (
                  <Table
                    className="customTable"
                    columns={columns}
                    dataSource={fields}
                    scroll={{ x: "max-content" }}
                    locale={{
                      emptyText: (
                        <span
                          style={{
                            height: "300px",
                            color: "#333",
                            fontSize: "16px",
                          }}
                        >
                          Please click on "Add New Item" to add Materials.
                        </span>
                      ),
                    }}
                    bordered
                    size="small"
                    pagination={false}
                  />
                )}
              </div>

              <Row
                style={{
                  margin: "20px",
                }}
              >
                <Col className="justify-between">
                  <div className="w-100">
                    <a onClick={addNewRow} className="addNewItem">
                      <Icon name="plus-round" />
                      <span>Add New Item</span>
                    </a>
                    <Form form={form2} component={false} requiredMark={false}>
                      <Row className="mt-2">
                        <div>
                          <div className="d-flex align-items-center">
                            <h6 className="m-1 mb-0">
                              Additional Charges(If any)
                              {editAdditionalCharges ||
                              additionalCharges?.length == 0 ? (
                                <a
                                  type={undefined}
                                  onClick={async () => {
                                    try {
                                      const newAdditionalCharges: any =
                                        await form2.validateFields();
                                      setValue(
                                        "notes",
                                        JSON.stringify(newAdditionalCharges)
                                      );
                                      if (
                                        additionalCharges.find(
                                          (field: any, ind: number) => {
                                            const name = form2.getFieldValue(
                                              `AdditionalCharges.${field?.id}.name`
                                            );
                                            const price = form2.getFieldValue(
                                              `AdditionalCharges.${field?.id}.price`
                                            );
                                            return !name || !price;
                                          }
                                        )
                                      ) {
                                        return;
                                      }

                                      setAdditionalCharges([
                                        ...additionalCharges,
                                        {
                                          name: "",
                                          price: null,
                                          id: nanoid(4),
                                        },
                                      ]);
                                    } catch (error) {}
                                  }}
                                  className="addNewItem mb-0 mt-0 m-4"
                                >
                                  <PlusSquareOutlined />
                                </a>
                              ) : (
                                <a
                                  type={undefined}
                                  onClick={() => {
                                    setEditAdditionalCharges(true);
                                  }}
                                  className="addNewItem"
                                >
                                  <EditOutlined />
                                </a>
                              )}
                            </h6>
                          </div>
                          {additionalCharges.map(
                            (field: any, index: number) => (
                              <Row key={index} className="mt-2 mb-0 w-100">
                                <div className="col-md-4 col-6">
                                  <Form.Item
                                    name={`AdditionalCharges.${field.id}.name`}
                                    label={<strong>Name</strong>}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Name is required",
                                      },
                                    ]}
                                    className="mb-0"
                                  >
                                    <Input
                                      disabled={!editAdditionalCharges}
                                      className="w-100"
                                      autoComplete="off"
                                      placeholder="Additional Charge Name"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="col-md-4 col-6">
                                  <Form.Item
                                    name={`AdditionalCharges.${field.id}.price`}
                                    label={<strong>Price (INR)</strong>}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Amount is required",
                                      },
                                      {
                                        type: "number",
                                        min: 1,
                                        message:
                                          "Amount should be greater than 0",
                                      },
                                    ]}
                                    className="mb-0"
                                  >
                                    <InputNumber
                                      disabled={!editAdditionalCharges}
                                      className="w-100"
                                      placeholder="Amount"
                                      formatter={(value) =>
                                        `${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )
                                      }
                                      parser={(value: any) =>
                                        value!.replace(/\$\s?|(,*)/g, "")
                                      }
                                      onKeyPress={(event) => {
                                        if (!/^[0-9]+$/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      autoComplete="off"
                                    />
                                  </Form.Item>
                                </div>
                                <Col
                                  md={2}
                                  xs={12}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <a
                                    style={{
                                      display: editAdditionalCharges
                                        ? "inline"
                                        : "none",
                                    }}
                                    href="#dropdownitem"
                                    onClick={() => {
                                      form2.setFieldsValue({
                                        [`AdditionalCharges.${field.id}.name`]:
                                          "",
                                        [`AdditionalCharges.${field.id}.price`]:
                                          null,
                                      });
                                      const newAdditionalCharges: any = [];
                                      additionalCharges.forEach(
                                        (fld: any, ind: number) => {
                                          if (fld.id != field.id) {
                                            const name = form2.getFieldValue(
                                              `AdditionalCharges.${fld.id}.name`
                                            );
                                            const price = form2.getFieldValue(
                                              `AdditionalCharges.${fld.id}.price`
                                            );
                                            if (name && price) {
                                              newAdditionalCharges.push({
                                                name,
                                                price,
                                                id: fld.id,
                                              });
                                            }
                                          }
                                        }
                                      );
                                      setAdditionalCharges(
                                        newAdditionalCharges
                                      );
                                    }}
                                    className="addNewItem mt-0"
                                  >
                                    <DeleteOutlined />
                                  </a>
                                </Col>
                              </Row>
                            )
                          )}
                        </div>
                      </Row>
                      {additionalCharges.length > 0 &&
                        editAdditionalCharges && (
                          <Button
                            className="mt-3 mb-0"
                            color="primary"
                            size="sm"
                            onClick={saveAdditionalCharges}
                          >
                            Save
                          </Button>
                        )}
                    </Form>
                  </div>
                  <div className="w-40">
                    <div className="d-flex justify-content-between">
                      <div className="w-50 justify-end">
                        <strong>Taxable Amount</strong>
                      </div>
                      <span className="r_align">
                        {formatCurrency(
                          fields.reduce((a, b: any) => a + Number(b.amount), 0),
                          currency?.currency_code
                        )}
                      </span>
                    </div>
                    {fields.reduce((a, b: any) => a + Number(b.igstAmount), 0) >
                    0 ? (
                      <div className="d-flex justify-content-between">
                        <div className="w-50 justify-end">
                          <strong>IGST</strong>
                        </div>
                        <span className="r_align">
                          {formatCurrency(
                            fields.reduce(
                              (a, b: any) => a + Number(b.igstAmount),
                              0
                            ),
                            currency?.currency_code
                          )}
                        </span>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>CGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              fields.reduce(
                                (a, b: any) => a + Number(b.cgstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>SGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              fields.reduce(
                                (a, b: any) => a + Number(b.sgstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {additionalCharges?.map((field: any) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>{field.name}</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              Number(field.price),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                      );
                    })}
                    <div
                      className="d-flex justify-content-between"
                      style={{ fontSize: "18px", marginTop: "10px" }}
                    >
                      <div className="w-50 justify-end">
                        <strong>Total</strong>
                      </div>
                      <strong className="r_align">
                        {formatCurrency(
                          fields.reduce(
                            (a, b: any) => a + Number(b.lineTotal),
                            0
                          ) +
                            additionalCharges.reduce(
                              (a: any, b: any) => Number(a) + Number(b.price),
                              0
                            ),
                          currency?.currency_code
                        )}
                      </strong>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="m-4 justify-content-end ">
                {signature?.isActive ? (
                  <div className="signatureBtnWrapper">
                    <div className="d-flex justify-content-between align-items-center">
                      <strong>Signature</strong>
                      <a
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSignature((prev) => ({
                            ...prev,
                            isActive: false,
                            image: null,
                            label: "Authorised Signatory",
                          }));
                          setValue("signatureObj", {
                            isActive: false,
                            image: null,
                            label: "Authorised Signatory",
                          });
                        }}
                      >
                        <Icon
                          name="cross"
                          className="pointer"
                          onClick={() => {
                            setSignature((prev) => ({
                              ...prev,
                              isActive: false,
                              image: null,
                              label: "Authorised Signatory",
                            }));
                            setValue("signatureObj", {
                              isActive: false,
                              image: null,
                              label: "Authorised Signatory",
                            });
                          }}
                        />
                      </a>
                    </div>
                    <div
                      style={{
                        minHeight: signature?.image ? "0px" : "120px",
                        display: signature?.image ? "block" : "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!signature?.image && (
                        <Button
                          color="btn-dim btn"
                          onClick={() => {
                            setShowCropModel(true);
                          }}
                        >
                          <Icon
                            name="upload-cloud"
                            className="pointer"
                            onClick={() => {
                              setShowCropModel(true);
                            }}
                          />
                          <span>Upload</span>
                        </Button>
                      )}
                      {signature?.image && (
                        <>
                          <div className="signatueImgWrapper border">
                            <img
                              src={signature?.image}
                              alt="Signed"
                              style={{ width: "160px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="btn-dim btn"
                              onClick={() => {
                                setSignature((pre) => {
                                  return { ...pre, image: null };
                                });
                              }}
                            >
                              <span>Reset</span>
                              <Icon
                                name="redo"
                                className="pointer"
                                onClick={() => {
                                  setSignature((pre) => {
                                    return { ...pre, image: null };
                                  });
                                }}
                              />
                            </Button>
                          </div>
                          <div>
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="signatureLabel"
                              >
                                Signature Label
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="signatureLabel"
                                onChange={(e) => {
                                  setSignature((pre) => {
                                    return {
                                      ...pre,
                                      label: e.target.value,
                                    };
                                  });
                                }}
                                defaultValue={
                                  signature?.label || "Authorised Signatory"
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {!signature?.image && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            color="btn-dim btn"
                            onClick={() => setShowSignatureModel(true)}
                          >
                            <Icon name="pen" />
                            <span>Use Signature Pad</span>
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid #e5ecf7",
                      backgroundColor: "#f7faff",
                      padding: "10px",
                      marginLeft: "10px",
                      width: "22%",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      color="btn-dim btn"
                      onClick={() => {
                        setSignature((pre) => {
                          return { ...pre, isActive: true };
                        });
                      }}
                    >
                      <Icon
                        name="edit"
                        className="pointer"
                        onClick={() => {
                          setSignature((pre) => {
                            return { ...pre, isActive: true };
                          });
                        }}
                      />
                      <span>Add Signature</span>
                    </Button>
                  </div>
                )}
              </Row>
            </div>
          </Row>

          {showSignatureModel && (
            <SignatureModal
              modal={showSignatureModel}
              toggle={() => setShowSignatureModel(!showSignatureModel)}
              onSave={(data: string) => {
                setSignature((pre: any) => {
                  return { ...pre, image: data };
                });
                setShowSignatureModel(false);
              }}
            />
          )}
          <div className="actions clearfix">
            <ul>
              <li>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    try {
                      saveAdditionalCharges();
                      if (fields.length == 0) {
                        Swal.fire({
                          icon: "info",
                          title: "Material",
                          text: "Please Add Material",
                        });
                        return;
                      }
                      if (!signature?.image) {
                        Swal.fire({
                          icon: "info",
                          title: "Signature",
                          text: "Signature is required",
                        });
                        return;
                      }
                      props.next();
                    } catch (error) {}
                  }}
                >
                  Next
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={props.prev}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
      {showCropModel && (
        <CropperComponent
          modal={showCropModel}
          toggle={() => setShowCropModel(!showCropModel)}
          onSave={(data: string) => {
            setSignature((pre: any) => {
              return { ...pre, image: data };
            });
            setShowCropModel(false);
          }}
        />
      )}
      {activeMaterial.action && (
        <MaterialModal
          modal={!!activeMaterial.action}
          closeModal={() => {
            setActiveMaterial({
              action: null,
              index: null,
              item: null,
            });
          }}
          taxConfiguration={taxConfiguration}
          materialCategoryOptions={materialCategoryOptions}
          append={(data: any) => {
            for (let i = 0; i < fields.length; i++) {
              let item: any = fields[i];
              if (item.materialName == data.materialName) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Material Already Added",
                });
                return;
              }
            }

            append(data);
          }}
          update={(index: any, data: any) => {
            const ItemsList = [...fields];
            const _data = ItemsList?.filter(
              (item: any, idx) => idx != index
            )?.find((item: any) => {
              if (item.materialName == data.materialName) return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            update(index, data);
          }}
          formData={activeMaterial}
          materialsList={fields?.map((row: any) => row?.materialName)}
        />
      )}
    </>
  );
};

export default Materials;
