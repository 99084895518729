import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon, Button, TooltipComponent } from "../../../components/Component";
import Swal from "sweetalert2";
import usersApis from "../../../api/master/users";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import dayjs from "dayjs";
const Users: FC = () => {
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, setSm] = useState(false);

  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBuyerById(id);
      }
    });
  };
  const deleteBuyerById = async (id: any) => {
    try {
      const { status, message } = await usersApis.deleteBuyerById(id);
      if (status) {
        Swal.fire("Deleted!", "Buyer has been deleted.", "success");
        setRefresh((pre) => !pre);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response.data.message, "error");
    }
  };

  const [originalData, setOriginalData] = useState([]);
  const navigate = useNavigate();

  const viewChange = () => {
    if (window.innerWidth < 990) {
    } else {
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const createUser = () => {
    navigate(`/createUsers`);
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "referenceID",
      key: "referenceID",
      width: 200,
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      width: 250,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
      width: 250,
    },
    {
      title: "Reporting Manager",
      dataIndex: "reportingManagerName",
      key: "reportingManagerName",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 250,
      render: (text: any, record: any) => (
        <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 200,
      render: (text: any, record: any) => (
        <span
          className={`tb-status text-${
            record.status === "Active" ? "success" : "danger"
          }`}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      width: 100,
      render: (text: any, record: any) => (
        <ul className="nk-tb-actions">
          <li
            className="nk-tb-action-toggle"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/userProfile/${record.userID}`)
            }
          >
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"edit" + record.id}
              icon="focus"
              direction="top"
              text="View Details"
              iconClass={undefined}
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/userProfile/${record.userID}`}
                    >
                      <Icon name="focus"></Icon>
                      <span>View Details</span>
                    </Link>
                  </li>
                  {allowedActions?.cancel ? (
                    <li onClick={() => handleDelete(record.userID)}>
                      <Link to={`#`}>
                        <Icon name="delete"></Icon>
                        <span>Delete user</span>
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="users-list"
      title="Users"
      refresh={refresh}
      columns={columns}
      extra={{
        tableScrollX: 1100,
      }}
      topRightJSX={
        allowedActions?.add ? (
          <Button color="primary" onClick={() => createUser()}>
            <Icon name="plus"></Icon>
            <span>Add </span>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};

export default Users;
