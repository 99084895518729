import makeApiCall from "./axiosIndex";

const rolesApi = {
  fetchRolesData: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchRole" }),
  deleteRoleById: (RoleId: string): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteRoleById?id=${RoleId}`,
    }),
  createRole: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createRole",
      data,
    }),
  updateRoleById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateRoleById`,
      params: { id },
      data,
    }),
  fetchRoleById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchRoleById`,
      params: { id: id },
    }),
  fetchRoleWithDeptId: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchRoleWithDeptId`,
      params: { id },
    }),
  getModuleConfig: (): Promise<any> => {
    return makeApiCall<any>({
      method: "get",
      url: "/seller/fetchModuleConfig",
    });
  },
  createReportingHierarchy: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createReportingHierarchy",
      data,
    }),
  fetchReportingHierarchy: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/seller/fetchReportingHierarchy",
    }),
  updateReportingHierarchy: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: "/seller/updateReportingHierarchy",
      params: { id },
      data,
    }),
  fetchReportingHierarchyById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchReportingHierarchyById`,
      params: { id },
    }),
  deleteReportingHierarchyById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteReportingHierarchyById`,
      params: { id },
    }),
  fetchHierarchyUsersByModuleId:(moduleId:any,siteId:any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchHierarchyUsersByModuleId`,
      params: { id: moduleId,siteId },
    }),
};

export default rolesApi;
