import React, { FC, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Col, Row } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import miscService from "../../../api/misc/misc";
import { useForm, FormProvider } from "react-hook-form";
import usersApis from "../../../api/master/users";

// const AddAddressModal = ({ isOpen, toggle, onSubmit, existingAddresses = [] }: any) => {
const AddAddressModal: FC<any> = ({
  modal,
  closeModal,
  append,
  update,
  formData,
  existingAddresses = [],
}) => {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const [stateOptions, setStateOptions] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState<any>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const watchCountry = watch("country");
  const watchState = watch("state");

  useEffect(() => {
    if (modal && formData.item) {
      const {
        attention,
        country,
        addressLine1,
        addressLine2,
        city,
        state,
        district,
        zipCode,
        phone,
        addressType,
        faxNumber,
        contactPersonName,
      } = formData.item;
      setValue("attention", attention);
      setValue("country", country);
      setValue("addressLine1", addressLine1);
      setValue("addressLine2", addressLine2);
      setValue("city", city);
      setValue("state", state);
      setValue("district", district);
      setValue("zipCode", zipCode);
      setValue("phone", phone);
      setValue("addressType", addressType);
      setValue("faxNumber", faxNumber);
      setValue("contactPersonName", contactPersonName);
    }
  }, [modal]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (watchCountry) {
      getStates(watchCountry);
    }
  }, [watchCountry]);

  useEffect(() => {
    if (watchState) {
      getDistricts(watchState);
    }
  }, [watchState]);

  const getCountries = async () => {
    try {
      const _countries = await miscService.getCountries();
      if (_countries.status && _countries.data.length > 0) {
        const transformedData = _countries.data.map((item: any) => ({
          value: item.countryId.toString(),
          label: item.countryName,
        }));
        setCountryOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching countries:", error.message);
    }
  };

  const getStates = async (countryId: any) => {
    try {
      const states = await miscService.getStatesByCountry(countryId);
      if (states.status && states.data.length > 0) {
        const transformedData = states.data.map((item: any) => ({
          value: item.stateId.toString(),
          label: item.stateName,
        }));
        setStateOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching states:", error.message);
    }
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      if (districts.status && districts.data.length > 0) {
        const transformedData = districts.data.map((item: any) => ({
          value: item.districtId.toString(),
          label: item.districtName,
        }));
        setDistrictOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching districts", error.message);
    }
  };

  const onSubmitForm = (data: any) => {
    // onSubmit(data);
    // reset();
    // toggle();
    console.log("data", data);
    data.countryName = countryOptions.find(
      (country: any) => country.value == data?.country
    )?.label;
    data.stateName = stateOptions.find(
      (state: any) => state.value == data?.state
    )?.label;
    data.districtName = districtOptions.find(
      (district: any) => district.value == data?.district
    )?.label;
    console.log("formData.action", formData.action, formData.index);
    // return;
    if (formData.action == "add" || formData.index == null) {
      console.log("Appending");
      append({
        key: Date.now(),
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        country: data.country,
        zipCode: data.zipCode,
        attention: data.attention,
        phone: data.phone,
        addressType: data.addressType,
        faxNumber: data.faxNumber,
        contactPersonName: data.contactPersonName,
        district: data.district,
        countryName: data.countryName,
        stateName: data.stateName,
        districtName: data.districtName,
      });
    } else {
      console.log("Updating");
      update(formData.index, {
        ...formData.item,
        key: Date.now(),
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        country: data.country,
        zipCode: data.zipCode,
        attention: data.attention,
        phone: data.phone,
        addressType: data.addressType,
        faxNumber: data.faxNumber,
        contactPersonName: data.contactPersonName,
        district: data.district,
        countryName: data.countryName,
        stateName: data.stateName,
        districtName: data.districtName,
      });
    }
    closeModal();
  };

  const getAddressTypeOptions = () => {
    const hasBillingAddress = existingAddresses.some(
      (address: any) => address.addressType === "Billing"
    );

    if (hasBillingAddress) {
      return [{ label: "Delivery", value: "Shipping" }];
    }

    return [
      { label: "Billing", value: "Billing" },
      { label: "Delivery", value: "Shipping" },
    ];
  };

  return (
    <Modal
      isOpen={modal}
      toggle={closeModal}
      className="modal-dialog-centered"
      size="xl"
      backdrop="static"
    >
      <ModalHeader>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Address"
            : "Update Address"}
        </h5>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Row className="gy-4">
              <Col md="3">
                <FormSelect
                  label="Address Type"
                  name="addressType"
                  options={getAddressTypeOptions()}
                  onChange={(value) => setValue("addressType", value)}
                  required
                />
              </Col>
              <Col md="3">
                <FormInput
                  label="Contact Person Name"
                  name="contactPersonName"
                  required
                />
              </Col>
              <Col md="3">
                <FormInput
                  label="Contact Person Phone"
                  name="phone"
                  pattern={/^\d+$/} // Allows only digits
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                  }}
                  type="text"
                  required
                />
              </Col>
              <Col md="3">
                <FormInput label="Fax Number" name="faxNumber" />
              </Col>
              <Col md="3">
                <FormInput
                  label="Delivery Location Name"
                  name="attention"
                  required
                />
              </Col>

              <Col md="3">
                <FormInput
                  label="Address Line 1"
                  name="addressLine1"
                  required
                />
              </Col>
              <Col md="3">
                <FormInput label="Address Line 2" name="addressLine2" />
              </Col>
              <Col md="3">
                <FormInput label="City" name="city" required />
              </Col>
              <Col md="3">
                <FormSelect
                  label="Country"
                  name="country"
                  options={countryOptions}
                  onChange={(value) => setValue("country", value)}
                  required
                />
              </Col>
              <Col md="3">
                <FormSelect
                  label="State"
                  name="state"
                  options={stateOptions}
                  onChange={(value) => setValue("state", value)}
                  required
                  disabled={!watchCountry}
                  placeholder={
                    !watchCountry ? "Select Country First" : "Select State"
                  }
                />
              </Col>
              <Col md="3">
                <FormSelect
                  label="District"
                  name="district"
                  options={districtOptions}
                  onChange={(value) => setValue("district", value)}
                  required
                  disabled={!watchState}
                  placeholder={
                    !watchState ? "Select State First" : "Select District"
                  }
                />
              </Col>
              <Col md="3">
                <FormInput label="Pin Code" name="zipCode" required />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action === "edit" && formData.index != null && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  {(formData.action == "add" || formData.index == null) && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AddAddressModal;
