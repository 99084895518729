import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Icon,
  Button,
  TooltipComponent,
} from "../../../components/Component";
import Swal from "sweetalert2";
import materialsApis from "../../../api/master/materials";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import dayjs from "dayjs";
const Materials: FC = () => {
  const [filterSupplyCategories, setfilterSupplyCategories] = useState<any>([]);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const navigate = useNavigate();

  const handleDelete = (materialID: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteProductById(materialID);
      }
    });
  };
  const deleteProductById = async (id: any) => {
    try {
      const { status, message } = await materialsApis.deleteProductById(id);
      if (status) {
        Swal.fire("Deleted!", message, "success");
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Error", error.message, "error");
    } finally {
      setRefreshToggle(!refreshToggle);
    }
  };

  const addMaterial = () => {
    navigate(`/addMaterials`);
  };

  const columns = [
    {
      title: "Material Code",
      dataIndex: "productCode",
      key: "productCode",
      width: 200,
    },
    {
      title: "Material Type",
      dataIndex: "materialName",
      key: "materialName",
      width: 250,
    },
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 250,
    },
    {
      title: "Material Name",
      dataIndex: "productName",
      key: "productName",
      width: 250,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "unitOfMeasureName",
      key: "unitOfMeasureName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 250,
      render: (text: any, record: any) => (
        <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 200,
      render: (text: any, record: any) => (
        <span
          className={`tb-status text-${
            record.status === "Active" ? "success" : "danger"
          }`}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      width: 100,
      render: (text: any, record: any) => (
        <>
          <ul className="nk-tb-actions">
            <li
              className="nk-tb-actions-toggle"
              onClick={() =>
                navigate(
                  `${process.env.PUBLIC_URL}/vewMaterialDetails/${record.materialID}`
                )
              }
            >
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-trigger btn-icon"
                id={"view" + record.id}
                icon="focus"
                direction="top"
                text="View Details"
                iconClass={undefined}
              />
            </li>
            <li>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="dropdown-toggle btn btn-icon btn-trigger"
                >
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/vewMaterialDetails/${record.materialID}`}
                      >
                        <Icon name="focus"></Icon>
                        <span>View Details</span>
                      </Link>
                    </li>
                    {allowedActions?.cancel ? (
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#edit"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDelete(record.materialID);
                          }}
                        >
                          <Icon name="trash"></Icon>
                          <span>Delete</span>
                        </DropdownItem>
                      </li>
                    ) : null}
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li>
          </ul>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchSupplyCategoriesOptions();
  }, []);

  const fetchSupplyCategoriesOptions = async () => {
    try {
      const res = await materialsApis.fetchCategoryFilteredInProduct();
      const { data, status, message } = res;
      if (status) {
        let dateresp = data.map((item: any) => {
          return {
            label: item.categoryName,
            value: item.categoryId,
          };
        });
        setfilterSupplyCategories(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="material-list"
      title="Materials"
      columns={columns}
      extra={{
        tableScrollX: 1100,
        excelExport: true,
        bulkUpload:true 
      }}
      selectFilters={[
        {
          title: "Material Categories",
          options: filterSupplyCategories,
        },
      ]}
      refresh={refreshToggle}
      topRightJSX={
        allowedActions?.add ? (
          <Button color="primary" onClick={() => addMaterial()}>
            <Icon name="plus"></Icon>
            <span>Add </span>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};

export default Materials;
