import React from "react";

const Header: React.FC = (props: any) => {
  return (
    <>
      <div id="poForms" className="steps clearfix">
        <ul>
          <li className={props.current >= 1 ? "first done" : "first"}>
            <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
              <h5>General Details</h5>
            </a>
          </li>
          <li className={props.current >= 2 ? "done" : ""}>
            <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
              <span className="current-info audible">current step: </span>
              <h5>Material Details</h5>
            </a>
          </li>
          <li className={props.current >= 3 ? "done" : ""}>
            <a href="#wizard-01-h-3" onClick={(ev) => ev.preventDefault()}>
              <span className="current-info audible">current step: </span>
              <h5>Others</h5>
            </a>
          </li>
          <li className={props.current === 4 ? "last done" : "last"}>
            <a href="#wizard-01-h-4" onClick={(ev) => ev.preventDefault()}>
              <span className="current-info audible">current step: </span>
              <h5>Preview</h5>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Header;
