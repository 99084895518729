import React, { useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Col,
  RSelect,
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import GeneralInformation from "./GeneralInformation";
import LegalInformation from "./LegalInformation";
import ContactInformation from "./ContactInformation";
import DeliveryAddress from "../delivery-address/DeliveryAddress";
import AddressInformation from "./AddressInformation";

const config = {
  before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const AddCustomers: React.FC = (props: any) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });
  useEffect(() => {}, []);

  const backList = () => {
    navigate(`/customers`);
  };

  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Add Customer</BlockTitle>
              <BlockDes>
                Onboard customers platform, collecting essential information to
                create customers profiles.
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => backList()}
              >
                <Icon name="menu-alt-r"></Icon>
                <span>List of customers</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            {/* <div className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix"> */}
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                <Steps config={config}>
                  <Step component={GeneralInformation} />
                  <Step component={LegalInformation} />
                  <Step component={ContactInformation} />
                  <Step component={AddressInformation} />
                </Steps>
              </FormProvider>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default AddCustomers;
