import React from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Button, Row } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import FormInput from "../../form-components/InputComponent";

const ContactInformation: React.FC = (props: any) => {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    console.log("data", data);
    props.next();
  };
  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          {/* {fields.map((field, index) => ( */}
          <div className="row mb-3">
            <div className="col">
              <FormSelect
                name="salutation"
                label="Select Salutation"
                required={true}
                options={[
                  { label: "Mr.", value: "Mr." },
                  { label: "Mrs.", value: "Mrs." },
                  { label: "Ms.", value: "Ms." },
                  { label: "Miss.", value: "Miss." },
                  { label: "Dr.", value: "Dr." },
                ]}
                placeholder="Select Salutation"
              />
            </div>
            <div className="col">
              <FormInput
                name="firstName"
                label="First Name"
                required={true}
                type="text"
                placeholder="First Name"
              />
            </div>
            <div className="col">
              <FormInput
                name="lastName"
                label="Last Name"
                required={true}
                type="text"
                placeholder="Last Name"
              />
            </div>
            <div className="col">
              <FormInput
                name="eamilId"
                label="Email ID"
                required={true}
                type="email"
                placeholder="Email ID"
              />
            </div>
            <div className="col">
              <FormInput
                name="workPhone"
                label="Work Phone"
                // required={true}
                pattern={/^\d+$/} // Allows only digits
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                }}
                type="text"
                placeholder="Work Phone"
              />
            </div>
            <div className="col">
              <FormInput
                name="mobile"
                label="Mobile"
                required={true}
                pattern={/^\d+$/} // Allows only digits
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                }}
                type="text"
                placeholder="Mobile"
              />
            </div>
          </div>
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default ContactInformation;
