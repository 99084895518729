import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { Icon } from "../../components/Component";
import { Badge } from "reactstrap";
import miscService from "../../api/misc/misc";

export const navData = [
  {
    name: "For Your Information",
    icon: "alert",
    // badge: {
    //   text: function () {
    //     let defaultData = 10;
    //     return 10;
    //   },
    //   theme: "primary",
    // },
  },
  {
    name: "For Your Action",
    icon: "star",
  },
];

const NotificationAside: React.FC<any> = ({
  aside,
  setAside,
  currentTab,
  setCurrentTab,
  counts,
}) => {
  const [messageView, setMessageView] = useState(false);
  const [data, setData] = useState<any>([]);
  const [filteredTabData, setFilteredTabData] = useState();
  const [filterTab, setFilterTab] = useState("1");

  return (
    <div
      className={`nk-ibx-aside toggle-screen-lg ${
        aside ? "content-active" : ""
      }`}
    >
      <div className="nk-ibx-head">
        <h5 className="mb-0">Notifications</h5>
      </div>
      <SimpleBar className="nk-ibx-nav">
        <React.Fragment>
          <ul className="nk-ibx-menu">
            {navData.map((item) => (
              <li
                className={currentTab === item.name ? "active" : ""}
                key={item.name}
              >
                <a
                  className="nk-ibx-menu-item"
                  href="#menu-item"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setCurrentTab(item.name);
                    setAside(false);
                    setMessageView(false);
                  }}
                >
                  <Icon name={item.icon}></Icon>
                  <span className="nk-ibx-menu-text">{item.name}</span>
                  {/* <Badge pill color="primary">
                  {item.name === "For Your Information" ? counts.fyiCount : counts.fyaCount}
                  </Badge> */}
                  {(item.name === "For Your Information" &&
                    counts.fyiCount > 0) ||
                  (item.name !== "For Your Information" &&
                    counts.fyaCount > 0) ? (
                    <Badge pill color="primary">
                      {item.name === "For Your Information"
                        ? counts.fyiCount
                        : counts.fyaCount}
                    </Badge>
                  ) : null}
                </a>
              </li>
            ))}
          </ul>
        </React.Fragment>
      </SimpleBar>
    </div>
  );
};

export default NotificationAside;
