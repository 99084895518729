import React, { useEffect, useState } from "react";
import NotificationAside from "./NotificationAside";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import NotificationList from "./NotificationList";

const NotificationMain = () => {
  const [counts, setCounts] = useState({ fyiCount: 0, fyaCount: 0 });
  const [aside, setAside] = useState(false);
  const [currentTab, setCurrentTab] = useState("For Your Information"); // Manage currentTab here
  useEffect(() => {
    resizeFunc();
    window.addEventListener("resize", resizeFunc);
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const resizeFunc = () => {
    setAside(false);
  };
  return (
    <React.Fragment>
      <Head title="Inbox"></Head>
      <Content>
        <div className="nk-ibx">
          <NotificationAside
            aside={aside}
            setAside={setAside}
            currentTab={currentTab} // Pass currentTab to NotificationAside
            setCurrentTab={setCurrentTab} // Pass the setter function
            counts={counts} 
          />
          {aside && (
            <div
              className="toggle-overlay"
              onClick={() => setAside(!aside)}
            ></div>
          )}
          <NotificationList currentTab={currentTab} setCounts={setCounts}  />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default NotificationMain;
