import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebaseApp from "./firebaseConfig";
import { store } from "./store/store";
import { setNotification } from "./store/slices/misc/notificationSlice";

export const initializeFCM = async () => {
  try {
    console.log("Requesting notification permission...");
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Notification permission not granted.");
      return;
    }

    console.log("Registering service worker...");
    const registration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js"
    );
    await navigator.serviceWorker.ready;
    console.log("Service Worker registered:", registration);

    const app = firebaseApp;
    const messaging = getMessaging(app);
    // console.log("Messaging instance:", messaging);

    try {
      console.log("Fetching FCM token...");
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BL6_zdgyWrK5Fa2FJIA_iXpxt7XG1Zjjm0CJoDFHogzKyzc1kGbAwIUokvdg3QvnPvnZWb0LrD93Cb8sPQQPfRM",
        serviceWorkerRegistration: registration,
      });
      // console.log("fcmToken", fcmToken);
      if (fcmToken) {
        sessionStorage.setItem("fcmToken", fcmToken);
        console.log("FCM Token retrieved:", fcmToken);
      } else {
        console.warn("Failed to retrieve FCM token.");
      }
    } catch (error) {
      console.error("Error fetching FCM token:", error);
    }
  } catch (error) {
    console.error("Error initializing FCM:", error);
  }
};

export const onMessageListener = () => {
  console.log("Setting up onMessageListener...");
  return new Promise((resolve, reject) => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Foreground notification received:", payload);

      // Update the Redux state
      store.dispatch(setNotification(true));

      if (Notification.permission === "granted") {
        const { title, body, icon } = payload.notification || {};
        console.log("Displaying notification:", { title, body, icon });
        new Notification(title || "Notification", {
          body: body || "You have a new message",
          icon: icon || "/firebase-logo.png",
        });
      } else {
        console.warn("Notifications are not permitted.");
      }

      resolve(payload);
    });
  });
};
