import React from "react";

import * as XLSX from "xlsx";

import { saveAs } from "file-saver";

const ExportToXlsx = (jsonData, fileName = "data") => {
  try {
    const ws = XLSX.utils.json_to_sheet(jsonData);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(data, fileName + ".xlsx");
  } catch (error) {
    console.log("error", error);
  }
};

export default ExportToXlsx;
