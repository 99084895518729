import React, { useEffect, useState } from "react";
import { Icon, TooltipComponent } from "../../components/Component";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import miscService from "../../api/misc/misc";
import dayjs from "dayjs";
import { Flex, message, Spin } from "antd";
import InboxMessages from "./InboxMessages";

const moduleColors: Record<string, string> = {
  "Purchase Order": "primary",
  Invoice: "success",
  Payments: "warning",
  Quotation: "danger",
  "Purchase Request": "info",
  "Material Issue": "secondary",
  Handover: "indigo",
  Budget: "pink",
  Default: "light",
};

const NotificationList: React.FC<any> = ({ currentTab, setCounts }) => {
  const [check, setChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tabData, setTabData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 9,
    direction: "P",
    paginationId: null,
    pageDiff: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [mailId, setMailId] = useState(1);
  const [messageView, setMessageView] = useState(false);
  const [filter, setFilter] = useState("All");

  const handleFilterChange = (moduleName: string, moduleId: any) => {
    setFilter(moduleName);
    fetchNotificationList({ ...pagination, moduleId }, currentTab);
  };

  useEffect(() => {
    notificationCounts();
  }, []);

  const notificationCounts = async () => {
    try {
      const { response, status } = await miscService.notificationCount();
      if (status) {
        setCounts({
          fyiCount: response.fyiCount,
          fyaCount: response.fyaCount,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    // Reset pagination and fetch data when currentTab changes
    setPagination({
      currentPage: 1,
      pageSize: 9,
      direction: "P",
      paginationId: null,
      pageDiff: 0,
    });

    // Fetch data directly after resetting pagination
    fetchNotificationList(
      {
        currentPage: 1,
        pageSize: 9,
        direction: "P",
        paginationId: null,
        pageDiff: 0,
      },
      currentTab
    );
  }, [currentTab]);

  useEffect(() => {
    // Fetch data when pagination or refresh changes
    fetchNotificationList(pagination, currentTab);
  }, [pagination, refresh]);

  useEffect(() => {
    setMessageView(false);
  },[currentTab])

  const fetchNotificationList = async (paginationParams: any, tab: any) => {
    setLoading(true);
    const params = {
      ...paginationParams,
      sortOrder: "DESC",
      actionRequired: tab === "For Your Information" ? 0 : 1,
    };
    try {
      const { response, status } = await miscService.notificationList(params);
      if (status) {
        const { list, count } = response;
        setTabData(list);
        setTotalItems(count);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const toggleRefresh = () => {
    setRefresh(true);
    setTimeout(() => setRefresh(false), 3000);
  };

  const onSearchChange = (e: any) => {
    let value = e.target.value;
    setSearchText(value);
  };

  const toggleSearch = () => {
    setSearchToggle(!searchToggle);
  };
  const [aside, setAside] = useState(false);
  const pageNumbers: any = [];

  // for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
  //   pageNumbers.push(i);
  // }

  const handlePageChange = (newPage: number) => {
    const isForward = newPage > pagination.currentPage;
    const direction = isForward ? "F" : "B";
    const pageDiff = Math.abs(newPage - pagination.currentPage) - 1;
    const paginationId = isForward
      ? tabData[tabData.length - 1]?.paginationId // Use last item ID for forward
      : tabData[0]?.paginationId; // Use first item ID for backward

    setPagination({
      ...pagination,
      currentPage: newPage,
      direction,
      pageDiff,
      paginationId,
    });
  };

  const nextPage = () => {
    if (pagination.currentPage < Math.ceil(totalItems / pagination.pageSize)) {
      handlePageChange(pagination.currentPage + 1);
    }
  };

  const prevPage = () => {
    if (pagination.currentPage > 1) {
      handlePageChange(pagination.currentPage - 1);
    }
  };

  const onListClick = async (ev: any, item: any) => {
    console.log("item", item);
    ev.preventDefault();
    toggleMessageView();
    setMailId(item.notificationId);
    toggleUnread(item.notificationId);
    notificationCounts();
    setTabData((prevTabData: any) =>
      prevTabData.map((i: any) =>
        i.notificationId === item.notificationId ? { ...i, viewed: true } : i
      )
    );

    try {
      await messageRead(item.notificationId);
      // If the API call is successful, we already updated the UI optimistically
    } catch (error) {
      console.error("Error marking notification as read:", error);
      // Optionally, revert the change in case of an API failure
    }
  };

  const messageRead = async (id: any) => {
    try {
      const { response, status } = await miscService.notificationRead(id);
      if (status) {
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const toggleMessageView = () => {
    setMessageView(!messageView);
  };

  const toggleUnread = (id: any) => {
    // let defaultData = data;
    // let item = defaultData.findIndex((item) => item.id === id);
    // defaultData[item].message.meta.unread = true;
    // setData([...defaultData]);
  };

  return (
    <div className="nk-ibx-body bg-white">
      {messageView ? (
        <InboxMessages mailId={mailId} toggleMessageView={toggleMessageView} />
      ) : (
        <>
          {loading ? (
            <>
              <Flex align="center" gap="middle" className="pageLoader">
                <Spin size="large" className="m-auto" />
              </Flex>
            </>
          ) : (
            <React.Fragment>
              <div className="nk-ibx-head" style={{ minHeight: "75px" }}>
                <div className="nk-ibx-head-actions">
                  <div className="nk-ibx-head-check">
                    <div className="custom-control custom-control-sm custom-checkbox">
                      <input
                        type="checkbox"
                        checked={check}
                        // disabled={data.length === 0}
                        className="custom-control-input nk-dt-item-check"
                        id="conversionAll"
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="conversionAll"
                      ></label>
                    </div>
                  </div>
                  <ul className="nk-ibx-head-tools g-1">
                    <li>
                      <a
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleRefresh();
                        }}
                        className="btn btn-icon btn-trigger"
                      >
                        <Icon name="undo"></Icon>
                      </a>
                    </li>

                    <li className="d-none d-sm-block">
                      <a
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          // selectorDeleteInbox();
                        }}
                        className={`btn btn-icon btn-trigger`}
                      >
                        <Icon name="trash"></Icon>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="nk-ibx-head-tools g-1">
                    <li>
                      <a
                        href="#prev"
                        onClick={(ev) => {
                          ev.preventDefault();
                          prevPage();
                        }}
                        className={`btn btn-trigger btn-icon btn-tooltip ${
                          pagination.currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Icon name="arrow-left"></Icon>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#next"
                        onClick={(ev) => {
                          ev.preventDefault();
                          nextPage();
                        }}
                        className={`btn btn-trigger btn-icon btn-tooltip ${
                          pagination.currentPage ===
                          Math.ceil(totalItems / pagination.pageSize)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <Icon name="arrow-right"></Icon>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-trigger btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="me-n1">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          href="#item"
                          onClick={(ev) => ev.preventDefault()}
                          className="btn btn-trigger btn-icon toggle"
                        >
                          <Icon name="filter" />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <DropdownItem
                            onClick={() => handleFilterChange("All", undefined)} // Send 0 for "All"
                            className={filter === "All" ? "active" : ""}
                          >
                            All
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleFilterChange("Budget", 3)}
                            className={filter === "Budget" ? "active" : ""}
                          >
                            Budget
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleFilterChange("Purchase Request", 2)}
                            className={filter === "Purchase Request" ? "active" : ""}
                          >
                            Purchase Request
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleFilterChange("Purchase Order", 7)
                            }
                            className={
                              filter === "Purchase Order" ? "active" : ""
                            }
                          >
                            Purchase Order
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleFilterChange("Quotation", 5)}
                            className={filter === "Quotation" ? "active" : ""}
                          >
                            Quotation
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleFilterChange("Invoice", 2)}
                            className={filter === "Invoice" ? "active" : ""}
                          >
                            Invoice
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleFilterChange("Payments", 3)}
                            className={filter === "Payments" ? "active" : ""}
                          >
                            Payments
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
                <div className={`search-wrap ${searchToggle ? "active" : ""}`}>
                  <div className="search-content">
                    <a
                      href="#item"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleSearch();
                      }}
                      className="search-back btn btn-icon toggle-search"
                    >
                      <Icon name="arrow-left"></Icon>
                    </a>
                    <input
                      type="text"
                      className="form-control border-transparent form-focus-none"
                      placeholder="Search"
                      onChange={(e) => onSearchChange(e)}
                    />
                    <button className="search-submit btn btn-icon">
                      <Icon name="search"></Icon>
                    </button>
                  </div>
                </div>
              </div>
              <React.Fragment>
                <SimpleBar className="nk-ibx-list">
                  {tabData.length > 0 ? (
                    tabData.map((item: any) => {
                      return (
                        <>
                          <div
                            className={`nk-ibx-item ${
                              item.viewed ? "" : "is-unread"
                            }`}
                          >
                            <a
                              href="#item"
                              onClick={(ev) => {
                                onListClick(ev, item);
                              }}
                              className="nk-ibx-link current"
                            >
                              <span></span>
                            </a>
                            <div className="nk-ibx-item-elem nk-ibx-item-check">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  // checked={item.message.meta.checked}
                                  className="custom-control-input nk-dt-item-check"
                                  id={`${item.notificationId}`}
                                  key={Math.random()}
                                  // onChange={(e) => checkMessage(item.id, e.target.checked)}
                                />
                                <label className="custom-control-label"></label>
                              </div>
                            </div>
                            <div
                              className="nk-ibx-item-elem nk-ibx-item"
                              style={{ background: "none", border: "none" }}
                            >
                              <div className="sub-text">{item.senderName}</div>
                            </div>
                            <div
                              className="nk-ibx-item-elem nk-ibx-item-fluid"
                              style={{ fontSize: " 0.875rem" }}
                            >
                              <div className="nk-ibx-context-group">
                                <div className="nk-ibx-context-badges">
                                  <Badge
                                    color={
                                      moduleColors[item.moduleName] ||
                                      moduleColors.Default
                                    }
                                  >
                                    {item.moduleName}
                                  </Badge>
                                </div>
                                <div className="nk-ibx-context">
                                  <span className="nk-ibx-context-text">
                                    <span className="heading">
                                      {item.messageTitle}
                                    </span>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="nk-ibx-item-elem nk-ibx-item-time">
                              <div
                                className="sub-text"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {item.createdAt &&
                                  dayjs(item.createdAt).format(
                                    "DD-MM-YYYY HH:mm"
                                  )}
                              </div>
                            </div>
                            <div className="nk-ibx-item-elem nk-ibx-item-tools">
                              <div className="ibx-actions">
                                <ul className="ibx-actions-visible gx-2">
                                  <li>
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag={"a"}
                                        href="#item"
                                        onClick={(ev) => ev.preventDefault()}
                                        className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#item"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                // toggleMessageView();
                                                // setMailId(item.id);
                                              }}
                                            >
                                              <Icon name="eye"></Icon>
                                              <span>View</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#item"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                // deleteInbox(item.id);
                                              }}
                                            >
                                              <Icon name="trash"></Icon>
                                              <span>Delete</span>
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      No Data
                    </div>
                  )}
                </SimpleBar>
              </React.Fragment>
            </React.Fragment>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationList;
