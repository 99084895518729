import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { Link, useNavigate } from "react-router-dom";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import Cookies from "js-cookie";
import { findUpper } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/slices/misc/currentUser";
import authService from "../../../../api/auth/authService";
import { getMessaging, deleteToken } from "firebase/messaging";
const User = () => {
  const downloadHighlighted = useSelector(
    (state) => state.highlight.downloadHighlighted
  );
  const uploadHighlighted = useSelector(
    (state) => state.highlight.uploadHighlighted
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, error } = useSelector((state) => state.currentUser);
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const logOut = async (e) => {
    e.preventDefault();
    await authService.logout();
    navigate(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "/" : "/"}`);
    Cookies.remove("access-token");

    const messaging = getMessaging();
    const fcmToken = sessionStorage.getItem("fcmToken");
    if (fcmToken) {
      // Delete the FCM token
      await deleteToken(messaging);
      console.log("FCM token deleted on logout");

      // Remove from sessionStorage
      sessionStorage.removeItem("fcmToken");
    }

    // Unregister all active service workers
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        await registration.unregister();
        console.log("Service Worker unregistered");
      }
    }

    // Clear local storage and session storage
    localStorage.clear();
    sessionStorage.clear();

    // Clear service worker cache
    if ("caches" in window) {
      caches.keys().then((keyList) => {
        return Promise.all(keyList.map((key) => caches.delete(key)));
      });
    }

    // Clear IndexedDB (optional, if used)
    if (window.indexedDB) {
      indexedDB.databases().then((databases) => {
        databases.forEach((db) => indexedDB.deleteDatabase(db.name));
      });
    }

    // Dispatch Redux action to reset state
    dispatch(logout());

    // Perform a forced page reload (Ctrl + Shift + R equivalent)
    window.location.reload(true); // Forces a reload ignoring cached content
  };
  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        {/* <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
        </div> */}
        <div className="user-card sm">
          <div className="user-avatar">
            <span>
              {findUpper(
                data?.firstName?.replace(/\b\w/g, (l) => l.toUpperCase()) +
                  " " +
                  data?.lastName?.replace(/\b\w/g, (l) => l.toUpperCase())
              )}
            </span>
            <span
              className={
                downloadHighlighted || uploadHighlighted ? "highlight-dot" : ""
              }
            ></span>
          </div>
          <div className="user-info">
            <span className="lead-text">
              {data?.firstName + " " + data?.lastName}
            </span>
            <span className="sub-text" title={data?.genericRole}>
              {data?.roleName}
            </span>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>
                {findUpper(
                  data?.firstName?.replace(/\b\w/g, (l) => l.toUpperCase()) +
                    " " +
                    data?.lastName?.replace(/\b\w/g, (l) => l.toUpperCase())
                )}
              </span>
            </div>
            <div className="user-info">
              <span className="lead-text">
                {data?.firstName + " " + data?.lastName}
              </span>
              <span className="sub-text" title={data?.email}>
                {data?.email?.length > 17
                  ? data?.email?.substring(0, 17) + "..."
                  : data?.email}
              </span>
            </div>
            {/* <div className="user-action" onClick={() => setOpen(false)}>
              <Link
                to={`${process.env.PUBLIC_URL}/user-profile-setting`}
                className="btn btn-icon me-n2"
              >
                <Icon name="setting"></Icon>
              </Link>
            </div> */}
          </div>
        </div>
        {/* <div className="dropdown-inner user-account-info">
          <h6 className="overline-title-alt">Account Balance</h6>
          <div className="user-balance">
            1,494.23 <small className="currency currency-usd">USD</small>
          </div>
          <div className="user-balance-sub">
            Locked{" "}
            <span>
              15,495.39 <span className="currency currency-usd">USD</span>
            </span>
          </div>
          <a href="#checkout" onClick={(ev) => ev.preventDefault()} className="link">
            <span>Withdraw Balance</span> <Icon name="wallet-out"></Icon>
          </a>
        </div> */}
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link={"/user-profile-regular"}
              icon="user-alt"
              onClick={toggle}
            >
              View Profile
            </LinkItem>
            <LinkItem
              link={"/user-profile-setting"}
              icon="setting-alt"
              onClick={toggle}
            >
              Security Setting
            </LinkItem>

            <LinkItem
              link={"/excelexport-requested-list"}
              icon="download"
              onClick={toggle}
            >
              <div className="d-flex">
                Exports
                <div className="position-relative " style={{ width: "10px" }}>
                  <span
                    className={downloadHighlighted ? "highlight-dot" : ""}
                  ></span>
                </div>
              </div>
            </LinkItem>
            <LinkItem link={"/bulk-upload-list"} icon="upload" onClick={toggle}>
              <div className="d-flex">
                Uploads
                <div className="position-relative " style={{ width: "10px" }}>
                  <span
                    className={uploadHighlighted ? "highlight-dot" : ""}
                  ></span>
                </div>
              </div>
            </LinkItem>

            <LinkItem
              link={"/user-profile-activity"}
              icon="activity-alt"
              onClick={toggle}
            >
              Login Activity
            </LinkItem>
            {/* <li>
              <a
                className={`dark-switch ${
                  theme.skin === "dark" ? "active" : ""
                }`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li> */}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href="#" onClick={logOut}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
