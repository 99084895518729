import makeApiCall from "./axiosIndex";
const quotationApis = {
  fetchList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/list`,
    }),
  fetchCounts: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getCounts`,
    }),
  quotedList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/quotedList`,
    }),
  requestedList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/requestedList`,
    }),
  fetchPendingApprovals: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/pendingApprovals`,
    }),
  approve: (quotationId: any, supplierQuoteId: number): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-buyer/approveSupplierQuote`,
      data: { quotationId, supplierQuoteId },
    }),
  reject: (quotationId: any, supplierQuoteId: any, reason: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-buyer/rejectSupplierQuote`,
      data: { quotationId, supplierQuoteId, reason },
    }),
  latestByQuotationId: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/latestByQuotationId`,
      params: { quotationId },
    }),
  submitSupplierQuote: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/quote",
      method: "post",
      data: data,
    }),
  fetchQuotedQuotationId: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/fetchQuotedQuotationId`,
      params: { quotationId },
    }),
  quoteNewVersion: (data: any) =>
    makeApiCall({
      url: "/quotation-seller/quoteNewVersion",
      method: "post",
      data,
    }),
  regretSupplier: (quotationId: any, supplierId: any): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/quotation-buyer/regretSupplier`,
      params: { quotationId, supplierId },
    }),
  emailNotify: (supplierQuoteId: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/quotation-seller/emailNotify/${supplierQuoteId}`,
      data,
    }),
  fetchPaginationList: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/requestedPaginationList",
      method: "get",
      params,
    }),
  fetchQuotedPaginationList: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/quotedPaginationList",
      method: "get",
      params,
    }),
  paginationPendingApprovals: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/paginationPendingApprovals",
      method: "get",
      params,
    }),
  fetchStatusFilterOptions: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/status`,
    }),
  fetchSelfRfqList: (): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/fetchSelfRfqList",
      method: "get",
    }),
  submitSupplierSelfQuotion: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/selfRfq",
      method: "post",
      data: data,
    }),
  rfqPriceBulkUpload: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/rfqPriceBulkUpload",
      method: "post",
      data: data,
    }),
convertToSelfInvoice: (data: any): Promise<any> =>
  makeApiCall<any>({
    url: "/quotation-seller/convertToSelfInvoice",
    method: "post",
    data: data,
  }),

};

export default quotationApis;
