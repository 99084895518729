import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  Button,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap";
import { Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { FormProvider, useForm } from "react-hook-form";
import exportFromJSON from "export-from-json";
import { Icon } from "../../components/Component";
import FormInput from "../form-components/InputComponent";
import quotationApis from "../../api/master/quotations";
import ExportToXlsx from "../../utils/exportToXlsx";
import materialsApis from "../../api/master/materials";

const BulkMaterialUpload = ({
  moduleKey,
  append,
  extra,
}: {
  moduleKey?: any;
  append?: any;
  extra?: any;
}) => {
  const [categories, setCategories] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [materials, setMaterials] = useState<any>([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [blockEdit, setBlockEdit] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    message: "",
  });

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods;
  const files = watch("attachment");

  const fetchMaterialList = async () => {
    try {
      const { data, status } = await materialsApis.fetchProductFiltered();
      if (status) {
        const materialOption = data.map((item: any) => ({
          "Category Name": item?.categoryName,
          materialName: item?.productName,
          materialId: item?.materialID?.toString(),
        }));
        setMaterials(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (moduleKey == "rfq-creation") {
      fetchMaterialList();
    }
  }, [moduleKey]);

  useEffect(() => {
    setResponse({ status: "", message: "" });
  }, [files]);

  const [showModal, setShowModal] = useState(false);

  const handleUpload = async (value: any) => {
    try {
      if (!value?.attachment || !value?.attachment[0]) {
        setResponse({
          status: "error",
          message: "Please select a file",
        });
        setBlockEdit(false);
        setLoading(false);
        return;
      }
      uploadFile(value?.attachment, setResponse, "File Uploaded Successfully");
    } catch (error) {}
  };

  const uploadFile = async (
    attachment: any,
    setResponse: any,
    successResponse: any
  ) => {
    try {
      const _file = attachment?.[0];
      const formData: any = new FormData();

      formData.append("files", _file);
      let request: any = {
        ...extra,
      };
      formData.append("request", JSON.stringify(request));

      const res = await quotationApis.rfqPriceBulkUpload(formData);
      const { status, message, response: data } = res || {};
      if (status) {
        setResponse({
          status: "success",
          message: successResponse,
        });
        switch (moduleKey) {
          case "rfq-creation":
            console.log(data);
            alert("File uploaded successfully");
            break;
          default:
            append({
              ...data,
            });
        }
        return;
      }
      setResponse({
        status: "error",
        message: message || "Failed to upload file",
      });
      setBlockEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ModalContent = ({ moduleKey }: any) => {
    switch (moduleKey) {
      case "rfq-creation":
        const { taxConfiguration } = extra;
        let url =
          taxConfiguration?.gstType == "IGST"
            ? "https://procurexuatstg.blob.core.windows.net/procurexuatblob/SupplierRfqNewQuoteWithIgst.xlsx"
            : "https://procurexuatstg.blob.core.windows.net/procurexuatblob/SupplierRfqNewQuote.xlsx";

        return (
          <>
            <p>
              You're about to create multiple supplier records at once. To
              ensure a smooth import, please{" "}
              <a
                href={url}
                target="_self"
                style={{
                  textDecoration: "underline",
                }}
              >
                Download Template
              </a>{" "}
              and fill in the required supplier data accurately and completely,
              then upload the file below. you can check{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  ExportToXlsx(materials, "Materials");
                }}
                target="_self"
                style={{ textDecoration: "underline" }}
              >
                Materials
              </a>{" "}
              for reference
            </p>

            <div className="text-right mt-3"></div>
            <p>
              <strong>Note : </strong>
              All fields in the template are mandatory. Each supplier must have
              unique information to avoid errors during the import process.
            </p>
          </>
        );
      default:
        return (
          <>
            <p>
              You're about to submit a quotation for multiple materials. To
              ensure a smooth import, please upload the file below.
            </p>

            <div className="text-right mt-3"></div>
            <p>
              <strong>Note : </strong> Do not modify column names or any
              existing data. All fields in the template are mandatory.
            </p>
          </>
        );
    }
  };

  return (
    <>
      <UncontrolledDropdown size={"md"}>
        {" "}
        <div
          onClick={() => {
            setShowModal(true);
          }}
          className="btn btn-white btn-outline-light"
        >
          <Icon name="upload-cloud"></Icon>
          <span>Import</span>
        </div>
      </UncontrolledDropdown>

      <Modal
        title="Upload Material Data"
        open={showModal}
        footer={null}
        onCancel={() => {
          setShowModal(false);
          setBlockEdit(false);
          methods.reset();
          setLoading(false);
          setResponse(() => ({
            status: "",
            message: "",
          }));
        }}
        width={"70%"}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(async (values) => {
              setResponse(() => ({
                status: "",
                message: "",
              }));
              setBlockEdit(true);
              setLoading(true);
              handleUpload(values);
            })}
          >
            <ModalContent moduleKey={moduleKey} />
            <Col md={4}>
              {files && files[0]?.name && (
                <div className="form-group m-0">
                  <div className="form-label-wrapper">
                    <label className="form-label" htmlFor={"attachment"}>
                      File Upload Area
                    </label>
                  </div>
                  <div
                    className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                    style={{ borderRadius: "3px" }}
                  >
                    <span>{files && files[0]?.name}</span>
                    {!blockEdit && (
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: `${files && files[0]?.name ? "none" : "block"}`,
                }}
              >
                <FormInput
                  type="file"
                  name="attachment"
                  label="File Upload Area"
                  placeholder="File Upload Area"
                  accept=".xlsx, .xls, .csv"
                />
              </div>
            </Col>
            <Alert
              isOpen={response?.status === "error"}
              color="danger"
              className="start-0 w-100 py-1 mt-2"
              style={{
                top: 0,
                textAlign: "left",
                fontSize: "14px",
                cursor: "default",
              }}
            >
              <ReactTyped
                strings={[response?.message]}
                typeSpeed={30}
                showCursor={false}
              />
            </Alert>

            <Alert
              isOpen={response?.status === "success"}
              color="success"
              className="start-0 w-100 py-1 mt-2"
              style={{
                top: 0,
                textAlign: "left",
                fontSize: "14px",
                cursor: "default",
              }}
            >
              <p>
                {response?.message}, You can{" "}
                <span
                  className="inlineTableCellLink"
                  onClick={() => {
                    setShowModal(false);
                    setBlockEdit(false);
                    methods.reset();
                    setLoading(false);
                    setResponse(() => ({
                      status: "",
                      message: "",
                    }));
                  }}
                >
                  close
                </span>{" "}
                this popup
              </p>
            </Alert>

            <Row className="mt-4">
              <Col>
                {response?.status == "success" ? (
                  <></>
                ) : (
                  <ul className="actions clearfix d-flex">
                    <li>
                      {loading ? (
                        <Button color="primary" type="button" size="md">
                          <Spinner size="sm" color="light" />
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!files?.[0]}
                        >
                          Upload
                        </Button>
                      )}
                    </li>
                    {!loading && (
                      <li className="m-3 mt-0">
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowModal(false);
                            methods.reset();
                          }}
                          className="btn-dim btn btn-secondary"
                        >
                          Cancel
                        </Button>
                      </li>
                    )}
                  </ul>
                )}
              </Col>
            </Row>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default BulkMaterialUpload;
