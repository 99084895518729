import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { Popover } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ApprovalDrawer from "./ApprovalDrawer";
import misc from "../../../api/misc/dropDowns";
import quotationApis from "../../../api/master/quotations";
import { useSelector, useDispatch } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import { nanoid } from "@reduxjs/toolkit";

interface SelectOptions {
  label: string;
  value: string;
}

const TAX_SLAP_DROPDOWN = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "5%",
    value: "1",
  },
  {
    label: "12%",
    value: "2",
  },
  {
    label: "18%",
    value: "3",
  },
  {
    label: "28%",
    value: "4",
  },
];

const GeneralDetails: FC = (props: any) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [billingAddressList, setBillingAddressList] = useState<any>({});
  const [selfRfqList, setSelfRfqList] = useState<any>([]);
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const AllFormDetails = watch();
  const deliveryAddressId = watch("deliveryAddressId");
  const billingAddressId = watch("billingAddressId");
  const approvalRequired = watch("approvalRequired");
  const orgId = watch("orgId");
  const invoiceDate = watch("invoiceDate");
  const quotationNumberWatch = watch("quotationNumber");
  const dispatch: any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const submitForm = (data: any) => {
    setValue("approvalsData", JSON.stringify(approvals));
    if (approvalRequired == "true" && approvals?.data?.length === 0) {
      return;
    }
    props?.next();
  };

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  useEffect(() => {
    if (orgId) getAddressOptions(orgId);
  }, [orgId]);

  useEffect(()=>{
    if(deliveryAddressId){
      setDeliveryAddress(deliveryAddressId,deliveryAddressList);
    }
  },[deliveryAddressId,deliveryAddressList])

  const setFormValues = () => {
    const { approvalsData } = AllFormDetails;
    if (approvalsData) {
      const _approvals = JSON.parse(approvalsData);
      setApprovals(_approvals);
    }
  };

  useEffect(() => {
    getSelfRfqList();
    setFormValues();
    getCustomerOptions();
  }, []);

  const getCustomerOptions = async () => {
    try {
      const res = await misc.fetchCustomersByOrgId();
      const { status, data } = res || {};
      if (status) {
        setCustomerOptions(
          data?.map((customer: any) => ({
            label: customer?.customerName,
            value: customer?.customerId,
            orgId: customer?.customerOrgId,
          }))
        );
      }
    } catch (error) {}
  };

  const getSelfRfqList = async () => {
    try {
      const res = await quotationApis.fetchSelfRfqList();
      const { status, response } = res || {};
      if (status) {
        setSelfRfqList(
          response?.map((item: any) => ({
            label: item?.rfqId,
            value: item?.quotationId,
            supplierQuoteId: item?.supplierQuoteId,
          }))
        );
      }
    } catch (error) {}
  };

  const getRfqDetails = async (quotationId: any, supplierQuoteId: any) => {
    try {
      const res = await quotationApis.convertToSelfInvoice({
        quotationId,
        supplierQuoteId,
      });
      const { status, response: data } = res || {};
      if (status) {
        const { header, lines } = data || {};
        const {
          rfqId,
          orgId,
          deliveryAddressId,
          igstValue,
          additionalChargesArray,
        } = header || {};
        if (igstValue > 0) {
          setValue(
            "taxConfigurationObj",
            '{"taxType":"GST","gstType":"IGST","cess":[{"cessType":"","cessName":""}]}'
          );
        }
        const orgName = customerOptions?.find(
          (item: any) => item.orgId == orgId
        );
        setValue("rfqId", rfqId);
        setValue("orgId", orgName?.value);
        setValue("orgName", orgName?.label);
        setValue("customerOrgId", orgName?.orgId);
        setValue("deliveryAddressId", deliveryAddressId);
        setValue("additionalCharges", JSON.stringify(additionalChargesArray));
        const items = lines?.map((line: any) => {
          const igstValue = TAX_SLAP_DROPDOWN?.find(
            (dropdownItem) => dropdownItem?.label == line?.igstPercentage + "%"
          )?.value;
          const gst = TAX_SLAP_DROPDOWN?.find((dropdownItem) => {
            return (
              dropdownItem?.label ==
              Number(line?.cgstPercentage) + Number(line?.sgstPercentage) + "%"
            );
          })?.value;
          const sgstValue = gst;
          const cgstValue = gst;
          return {
            key: nanoid(),
            materialCategory: line?.categoryId,
            materialName: line?.materialId,
            materialLabel: line?.materialName,
            hsnCode: line?.hsnCode,
            quantity: Number(line?.quantity),
            unitPrice: Number(line?.unitPrice),
            uomId: line?.uomId,
            uomLabel: line?.uomName,
            amount: line?.amount,
            igst: igstValue,
            igstPercentage: line?.igstPercentage,
            igstAmount: line?.igstAmount,
            cgst: cgstValue,
            cgstPercentage: line?.cgstPercentage,
            cgstAmount: line?.cgstAmount,
            sgst: sgstValue,
            sgstPercentage: line?.sgstPercentage,
            sgstAmount: line?.sgstAmount,
            cess: [],
            lineTotal: line?.lineTotal,
            attachment: false,
          };
        });

        setValue("items", items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAddressOptions = async (id: any) => {
    try {
      const res = await misc.fetchCustomerAddressesById(id);
      const { status, data } = res || {};
      if (status) {
        setDeliveryAddressList(
          data?.filter((item: any) => item?.addressType == "Shipping")
        );
        let _billingAddress = data?.find(
          (item: any) => item?.addressType == "Billing"
        );
        setBillingAddressList(_billingAddress);
        setValue("billingAddressId", _billingAddress?.id);
        setValue("billingAddress", _billingAddress);
      }
    } catch (error) {}
  };

  const ShowAddressPopOver = ({ id, text, options }: any) => {
    if (!id) {
      return <></>;
    }

    let address = options?.find((item: any) => item.id == id);

    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city},{address?.district?.districtName},{" "}
              {address?.state?.stateName},{address?.country?.countryName} -{" "}
              {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };

  const setDeliveryAddress = (id: any,deliveryAddressList:any) => {
    try {
      let address = deliveryAddressList?.find((item: any) => item?.id == id);
      setValue("deliveryAddress", address);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormSelect
              name="quotationNumber"
              label={`RFQ Number`}
              options={selfRfqList}
              // required={true}
              placeholder="Select RFQ Number"
              onChange={(e: any) => {
                let orgName = selfRfqList?.find((item: any) => item.value == e);
                setValue("rfqName", orgName?.label);
                setValue("supplierQuoteId", orgName?.supplierQuoteId);
                if (e) getRfqDetails(e, orgName?.supplierQuoteId);
              }}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="orderId"
              label={`PO Number`}
              required={true}
              placeholder="Enter PO Number"
            />
          </Col>
          {!sequenceAccess?.includes("Invoice") ? (
            <Col lg="4">
              <FormInput
                name="invoiceId"
                label={`Invoice Number`}
                required={true}
                placeholder="Enter Invoice Number"
              />
            </Col>
          ) : (
            <></>
          )}
          {/* <Col lg="4">
            <FormInput
              name="referenceId"
              label={`RFQ Number`}
              required={true}
              placeholder="Enter Reference Number"
            />
          </Col> */}

          <Col lg="8">
            <FormInput
              name="description"
              label="Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={200}
              placeholder="Enter Description"
              tooltipText="Enter a brief description of the purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="invoiceType"
              label={`Invoice Type`}
              options={[
                {
                  label: "Proforma Invoice",
                  value: "proforma",
                },
                {
                  label: "Commercial Invoice",
                  value: "commercial",
                },
              ]}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the supplier or vendor for the purchase order."
              disabled={AllFormDetails?.quotationId ? true : false}
            />
          </Col>
          {/* <Col lg="4">
            <FormInput
              name="eWayBillNumber"
              label={`e-Way Bill Number`}
              placeholder="Enter e-Way Bill Number"
            />
          </Col>
          <Col lg="4">
            <FormInput name="irn" label={`IRN`} placeholder="Enter IRN" />
          </Col> */}
          <Col lg="4">
            <FormSelect
              name="orgId"
              label={`Customer`}
              options={customerOptions}
              required={true}
              disabled={quotationNumberWatch}
              placeholder="Select Customer"
              onChange={(e: any) => {
                let orgName = customerOptions?.find(
                  (item: any) => item.value == e
                );
                setValue("orgName", orgName?.label);
                setValue("customerOrgId", orgName?.orgId);
              }}
            />
          </Col>

          <Col lg="4">
            <FormDatePicker
              name="invoiceDate"
              label="Invoice Date"
              required={true}
              maxDate={new Date()}
            />
          </Col>

          <Col lg="4">
            <FormDatePicker
              name="dueDate"
              disabled={!invoiceDate}
              minDate={invoiceDate}
              label="Payment Due Date"
              required={true}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              disabled={!orgId || quotationNumberWatch}
              name="deliveryAddressId"
              label={`Delivery Address`}
              options={deliveryAddressList?.map((item: any) => ({
                label: item?.deliveryLocationName,
                value: item?.id,
              }))}
              popOverOptions={
                <ShowAddressPopOver
                  id={deliveryAddressId}
                  matchKey="deliveryAddress"
                  options={deliveryAddressList}
                  text="View Selected Delivery Address"
                />
              }
              required={true}
              placeholder="Select Delivery Address"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              disabled
              name="billingAddressId"
              label={`Billing Address`}
              options={[
                {
                  label: billingAddressList?.deliveryLocationName,
                  value: billingAddressList?.id,
                },
              ]}
              popOverOptions={
                <ShowAddressPopOver
                  id={billingAddressId}
                  matchKey="billingAddress"
                  options={[billingAddressList]}
                  text="View Billing Address"
                />
              }
              required={true}
              placeholder="Select Billing Address"
            />
          </Col>

          <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Approval Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              placeholder="Select"
              required={true}
            />
            <div style={{ display: "none" }}>
              <FormInput name="approvalsData" label="" type="text" />
            </div>
            {approvalRequired == "true" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
      {approvals.open && (
        <ApprovalDrawer
          open={approvals.open}
          siteId={null}
          onClose={() => {
            setApprovals((pre) => {
              return {
                ...pre,
                open: false,
              };
            });
          }}
          onSubmit={(data: any) => {
            setApprovals({
              open: false,
              data: data,
            });
          }}
          setApprovalSubmissionStatus={(status: boolean) => {}}
          approvalData={approvals.data} // Pass the current approval data
        />
      )}
    </>
  );
};

export default GeneralDetails;
