import React, { useEffect, useState } from "react";
import Pages from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import InternetStatus from "./pages/components/internetStatus/internetStatus";
import { initializeFCM, onMessageListener } from "./firebaseMessaging";
import miscService from "./api/misc/misc";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fcmInitialized, setFcmInitialized] = useState(false);
  useEffect(() => {
    if (isLoggedIn && !fcmInitialized) {
      const setupFCM = async () => {
        try {
          // Initialize Firebase Cloud Messaging
          if (Notification.permission !== "granted") {
            await initializeFCM();
          } else {
            await initializeFCM();
            console.log(
              "Notification permission already granted. Skipping request."
            );
          }

          // Log the FCM token stored in session storage
          const fcmToken = sessionStorage.getItem("fcmToken");
          // console.log("345345345", fcmToken);
          if (fcmToken) {
            console.log("FCM Token stored in session:", fcmToken);
            notificationCall();
          } else {
            console.warn("Failed to get FCM token.");
          }
        } catch (error) {
          console.error("Error initializing FCM:", error);
        }
      };

      setupFCM();

      // Listen for foreground messages
      onMessageListener()
        .then((payload) => {
          console.log("Foreground notification received:", payload);
          // Handle the notification payload as needed
        })
        .catch((error) => {
          console.error("Error receiving foreground messages:", error);
        });
    }
  }, [isLoggedIn, fcmInitialized]);

  const notificationCall = async () => {
    try {
      let postData = {
        token: sessionStorage.getItem("fcmToken"),
        deviceType: "web",
      };
      const { data, status } = await miscService.captureUserFirebaseToken(
        postData
      );
      // if (status) {
      //   setTimeout(() => {
      //     navigate("/dashboard");
      //   }, 1000);
      // }
    } catch (err: any) {}
  };

  return (
    <ThemeProvider>
      <InternetStatus />
      <Pages setIsLoggedIn={setIsLoggedIn} />
    </ThemeProvider>
  );
};

export default App;
