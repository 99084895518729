import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { RSelect } from "../../../components/Component";
interface SelectOptions {
  label: string;
  value: string;
}
const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useFormContext();
  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    props.next();
  };

  const [categories, setCategories] = useState<SelectOptions[]>([
    { label: "Category1", value: "Category1" },
    { label: "Category2", value: "Category2" },
  ]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([
    { label: "Supplier", value: "Supplier" },
    { label: "Customer", value: "Customer" },
  ]);

  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await supplierApis.getContructionsMaterialTypes();
      if (status) {
        console.log(data);
        setIndustryTypeOptions(
          data.map((obj: any) => ({ label: obj.name, value: obj.material_id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const handleMultiSelectChange = (selectedOptions: any, name: any) => {
    if (name == "categories") {
      const value = selectedOptions.map((option: any) => ({
        categoryID: option.value,
        categoryName: option.label,
      }));
      setValue(name, value);
    } else if (name == "materials") {
      const value = selectedOptions.map((option: any) => ({
        materialID: option.value,
        materialName: option.label,
      }));
      setValue(name, value);
    }
  };

  useEffect(() => {
    fetchIndustryType();
    fetchSupplierCategory();
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="Internal ID"
              type="text"
              pattern={/^[a-zA-Z0-9./_-]+$/}
              required={true}
              // maxLength={10}
              placeholder="Enter Internal ID"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="supplierName"
              label="Supplier Name"
              // disabled={action === "view"}
              required={true}
              type="text"
              maxLength={50}
              placeholder="Enter Supplier Name"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <label className="form-label">
              Material Type<span className="mandatory">*</span>
            </label>
            <RSelect
              isMulti
              name="industryType"
              // disabled={action === "view"}
              label="Material Type"
              options={industryTypeOptions}
              required={true}
              placeholder="-Select-"
              onChange={(value: any) =>
                handleMultiSelectChange(value, "materials")
              }
              closeMenuOnSelect={false}
            />
          </Col>
          <Col lg="4">
            <label className="form-label">
              Supply Categories<span className="mandatory">*</span>
            </label>
            <RSelect
              isMulti
              name="supplierCategory"
              // disabled={action === "view"}
              label="Supply Categories"
              options={categories}
              required={true}
              placeholder="-Select-"
              onChange={(value: any) =>
                handleMultiSelectChange(value, "categories")
              }
              closeMenuOnSelect={false}
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
