import makeApiCall from "../master/axiosIndex";
const miscService = {
  createLoginActivity: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/misc/createLoginActivity",
      method: "post",
      data,
    });
  },
  fetchLoginActivity: (): Promise<any> => {
    return makeApiCall({
      url: "/misc/fetchLoginActivity",
      method: "get",
    });
  },
  fetchNotificationSettings: (): Promise<any> => {
    return makeApiCall({
      url: "/misc/fetchNotificationSettings",
      method: "get",
    });
  },
  updateNotificationSettings: (data: any): Promise<any> => {
    return makeApiCall({
      url: `/misc/updateNotificationSettings`,
      method: "put",
      data,
    });
  },
  getByQuery: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/dms/getByQuery",
      method: "post",
      data,
    });
  },
  fetchEmailConfigurations: (): Promise<any> => {
    return makeApiCall({
      url: "/misc/fetchEmailConfigurations",
      method: "get",
    });
  },
  createEmailConfigurations: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/misc/createEmailConfigurations",
      method: "post",
      data,
    });
  },
  fetchExcelExportRequestedListWithPagination: (params: any): Promise<any> => {
    return makeApiCall({
      url: "/order-vs-billing/excelExportRequestedListWithPagination",
      method: "get",
      params,
    });
  },
  excelExportRequest: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/order-vs-billing/excelExportRequest",
      method: "post",
      data,
    });
  },
  bulkUpload: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/bulkUpload/upload",
      method: "post",
      data,
    });
  },
  fetchConvertedJsonListWithPagination: (params: any): Promise<any> => {
    return makeApiCall({
      url: "/bulkUpload/convertedJsonListWithPagination",
      method: "get",
      params,
    });
  },
  fetchBulkUploadRequestedListWithPagination: (params: any): Promise<any> => {
    return makeApiCall({
      url: "/bulkUpload/bulkUploadRequestedListWithPagination",
      method: "get",
      params,
    });
  },
  captureUserFirebaseToken: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/notification/captureUserFirebaseToken",
      method: "post",
      data,
    });
  },
  notificationsList: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/notification/notificationsList",
      method: "get",
      params: data,
    });
  },
  notificationCount: (): Promise<any> => {
    return makeApiCall({
      url: "/notification/unreadCounts",
      method: "get",
    });
  },
  notificationList: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/notification/notificationsList",
      method: "get",
      params: data,
    });
  },
  notificationRead: (notificationId: any): Promise<any> => {
    return makeApiCall({
      url: "/notification/viewed/" + notificationId,
      method: "put",
    });
  },
  viewNotificationById: (notificationId: any): Promise<any> => {
    return makeApiCall({
      url: "/notification/id/" + notificationId,
      method: "get",
    });
  },
  fetchProductFiltered: (): Promise<any> => {
    return makeApiCall({
      url: "/seller/fetchProductFiltered",
      method: "get",
    });
  },
  getOygTypes: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `admin/fetchCompanyTypes`,
    }),
  getStatesByCountry: (countryId: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/stateByCountry`,
      params: { countryId },
    }),
  getCountries: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/misc/countries" }),
};

export default miscService;
