import React, { useState, useEffect, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import miscService from "../../../api/misc/misc";
import { Badge, Card, Button as BButton, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Row, Col, Alert } from "reactstrap";
import CropperComponent from "../../components/CropperComponent";
import { FormProvider, set, useForm, useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import FormatDate from "../DateFormater";
import { Link, useParams } from "react-router-dom";
import customersApis from "../../../api/master/customers";
import { useNavigate } from "react-router";
import { use } from "echarts";
import usersApis from "../../../api/master/users";

const ViewCustomerDetails: FC = (props: any) => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [signatureModal, setSignatureModal] = useState(false);
  const toggleModal = () => setSignatureModal(!signatureModal);
  const [verifyingGstin, setVerifyingGstin] = useState(false);
  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const [docs, setDocs] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState(false);
  const navigate = useNavigate();
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;
  const orgLogoWatch = watch("orgLogo");
  const cancelledChequeWatch = watch("file");

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [modal, setModal] = useState(false);
  const files = watch("files");
  const businessType = watch("businessType");
  const gstinVerified = watch("gstinVerified");
  const gstin = watch("gstin");
  const addressList = watch("addressList") || [];
  const { id: customerId }: any = useParams<{ id: string }>();

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const fetchCustomerDetails = async () => {
    try {
      const {
        data: newData,
        status,
        message,
      } = await customersApis.viewCustomerDetails(customerId);

      if (status) {
        setData(newData);
        setDocs(newData.docs || []);
        const logo = newData?.docs?.find(
          (document: any) => document.moduleFileType == "org logo"
        );
        setCroppedImage(logo?.filePath);
        // return
        setValue("customerType", newData.customerType);
        setValue("orgName", newData.customerName);
        setValue("customerOrgType", newData.customerOrgType);
        setValue("businessType", newData.bussinessType);
        setValue("gstin", newData.gstin);
        setValue("pan", newData.pan);
        setValue("cin", newData.cin);
        setValue("accountName", newData.accountHolderName);
        setValue("bankaccountNumber", newData.accountNumber);
        setValue("bankName", newData.bankName);
        setValue("branchName", newData.branchName);
        setValue("ifcCode", newData.ifscCode);
        setValue("modifiedDate", newData.modifiedDate);
        setValue("modifiedBy", newData.modifiedBy);
        setValue("referenceID", newData.referenceID);
        setValue("docs", newData.docs || []);
        setValue("similarAddress", newData.similarAddress);
        setValue("salutation", newData.salutation);
        setValue("firstName", newData.firstName);
        setValue("lastName", newData.lastName);
        setValue("email", newData.email);
        setValue("workPhone", newData.phoneNumber);
        setValue("mobile", newData.mobileNumber);
        setValue("customerID", newData.customerID);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  const handleImageSave = async (image: string) => {
    try {
      const formData = new FormData();
      const deletedFileData: any = [];
      const imageFile = base64ToFile(image, "orgLogo.png");
      formData.append("files", imageFile);
      const previousDoc = docs.filter(
        (document: any) => document.moduleFileType == "org logo"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [
            {
              indexOfFileLocated: 0,
              module: "customer",
              purpose: "org logo",
              level: "Header Level",
              uniqueId: "",
              action: "Create",
            },
          ],
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      formData.append("customerData", JSON.stringify({}));

      toggleModal();
      const { status, message } = await customersApis.UpdateCustomerById(
        customerId,
        formData
      );
      if (status) {
        Swal.fire("Updated!", "Customer Details has been updated", "success");
        setModal(false);
        fetchCustomerDetails();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
    }
  };

  const [addressDataList, setaaddressDataList] = useState([]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };

  const [orgTypeOptions, setOrgTypeOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([
    { label: "Bussiness", value: "Bussiness" },
    { label: "Individual", value: "Individual" },
  ]);

  const getOrgTypes = async () => {
    try {
      const orgTypes = await miscService.getOygTypes();
      if (orgTypes.status && orgTypes.data.length > 0) {
        const transformedData = orgTypes.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.orgtype,
        }));
        setOrgTypeOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchCustomerDetails();
      getOrgTypes();
    }
  }, [customerId]);

  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const watchCountry = watch("country");
  const watchState = watch("state");

  useEffect(() => {
    getCountries();
  }, []); //////comp did mount

  useEffect(() => {
    if (watchCountry) {
      getStates(watchCountry);
    }
  }, [watchCountry]);

  useEffect(() => {
    if (watchState) {
      getDistricts(watchState);
    }
  }, [watchState]);

  const getCountries = async () => {
    try {
      const _countries = await miscService.getCountries();
      if (_countries.status && _countries.data.length > 0) {
        const transformedData = _countries.data.map((item: any) => ({
          value: item.countryId.toString(),
          label: item.countryName,
        }));
        setCountryOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching countries:", error.message);
    }
  };

  const getStates = async (countryId: any) => {
    try {
      const states = await miscService.getStatesByCountry(countryId);
      if (states.status && states.data.length > 0) {
        const transformedData = states.data.map((item: any) => ({
          value: item.stateId.toString(),
          label: item.stateName,
        }));
        setStateOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching states:", error.message);
    }
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      if (districts.status && districts.data.length > 0) {
        const transformedData = districts.data.map((item: any) => ({
          value: item.districtId.toString(),
          label: item.districtName,
        }));
        setDistrictOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching districts", error.message);
    }
  };

  const submitForm = (_data: any) => {
    console.log("_data", _data);
    // return;
    const deletedFileData: any = [];
    const formData: any = new FormData();
    const updatedFileData: any = [];
    const previousDoc = _data?.docs.filter(
      (document: any) => document.moduleFileType == "cheque"
    );
    if (previousDoc) {
      previousDoc.forEach((doc: any) => {
        deletedFileData.push({
          docId: doc.docId,
        });
      });
    }
    if (_data?.cancelledCheque && _data?.cancelledCheque[0] !== undefined) {
      formData.append("files", _data?.cancelledCheque[0]);
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [
            {
              indexOfFileLocated: 0,
              module: "customer",
              purpose: "cheque",
              level: "Header Level",
              uniqueId: "",
              action: "Create",
            },
          ],
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
    } else {
      formData.append("files", JSON.stringify([]));
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    let deliveryAddresses = _data?.addressList?.map((item: any) => {
      return {
        deliveryLocationName: item.attention,
        state: item.state,
        district: item.district,
        country: item.country,
        city: item.city,
        addressLine1: item.addressLine1,
        addressLine2: item.addressLine2,
        pincode: item.zipCode,
        faxNumber: item.faxNumber,
        phoneNumber: item.phone,
        contactPersonName: item.contactPersonName,
        addressType: item.addressType,
      };
    });
    let obj = {
      customerName: _data.orgName,
      customerType: _data.customerType,
      bussinessType: _data.businessType,
      customerOrgType: _data.customerOrgType,
      // orgID: _data.orgID,
      cin: _data.cin || null,
      incorporationDate: _data.incorporationDate || null,
      pan: _data.pan || null,
      gstin: _data.gstin || null,
      salutation: _data.salutation,
      firstName: _data.firstName,
      lastName: _data.lastName,
      mobileNumber: _data.mobile,
      phoneNumber: _data.workPhone,
      email: _data.eamilId,
      accountHolderName: _data.accountName,
      accountNumber: _data.bankaccountNumber,
      bankName: _data.bankName,
      branchName: _data.branchName,
      ifcCode: _data.ifscCode,
      // deliveryAddresses: deliveryAddresses,
    };
    formData.append("customerData", JSON.stringify(obj));

    console.log(obj);

    // return;
    submitUpdates(formData);
    setGIModal(false);
  };

  const submitUpdates = async (submitData: any) => {
    // console.log("customerId", submitData);

    // console.log("submitData", submitData);
    // return;
    const { status, message } = await customersApis.UpdateCustomerById(
      data.customerId,
      submitData
    );
    if (status) {
      Swal.fire("Updated!", "Customer has been updated", "success");
      const id: any = customerId;
      // console.log("id", id);
      if (id !== undefined || null || "") {
        fetchCustomerDetails();
      }
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  useEffect(() => {
    if (businessType === "unregistered") {
      setValue("gstin", "");
    }
  }, [businessType]);

  useEffect(() => {
    if (!gstin) return;
    if (
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
        gstin
      ) &&
      gstinVerified != "true"
    ) {
      setValue("gstinVerified", "false");
      setVerifyingGstin(true);
      validateSandbox({
        gstin: gstin,
        orgName: data.customerName,
      });
    } else {
      setVerifyingGstin(false);
    }
  }, [gstin]);

  const validateSandbox = async (data: any) => {
    try {
      const response = await customersApis.verifyLegalDetails({
        gstin: data?.gstin?.trim(),
        orgName: data.orgName?.trim(),
      });

      const sandboxResponse = response?.verificationResponse;

      const gstinVerification = sandboxResponse.find(
        (obj: any) => obj.gstin && !obj.verified
      );

      if (gstinVerification) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "GSTIN verification failed.",
        });
        setVerifyingGstin(false);
        return { errors: ["GSTIN verification failed."] };
      }

      const sandBoxObj = sandboxResponse.find(
        (obj: any) => obj.gstin && obj.verified
      );
    } catch (err) {
      console.log(err);
    } finally {
      setVerifyingGstin(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Customer Details"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Customer Details -{" "}
                    <strong className="text-primary small">
                      {data["customerName"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/Customers`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col lg="5">
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5"> General Information</BlockTitle>
                        <p> Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {/* {allowedActions?.edit && ( */}
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("GeneralInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                        {/* )} */}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Customer Type</div>
                          <div className="data-value">{data.customerType}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Organisation Name</div>
                          <div className="data-value">{data.customerName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Organisation Type</div>
                          <div className="data-value">
                            {data.customerOrgTypeName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Organisation Logo</div>
                          <span className="data-value">
                            {!croppedImage && (
                              <BButton
                                color="btn-dim btn"
                                onClick={toggleModal}
                              >
                                <span>Upload Logo </span>
                                <Icon name="upload-cloud" />
                              </BButton>
                            )}
                            {croppedImage && (
                              <div className="mt-3 w-25">
                                <div
                                  style={{
                                    marginLeft: "25px",
                                    height: "60px",
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  <img
                                    src={croppedImage}
                                    alt="Cropped"
                                    style={{ height: "60px" }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                  {allowedActions?.edit ? (
                                    <BButton
                                      color="btn-dim btn"
                                      onClick={toggleModal}
                                    >
                                      <span>Change</span>
                                      <Icon
                                        name="upload-cloud"
                                        className="pointer"
                                        onClick={toggleModal}
                                      />
                                    </BButton>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            )}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </Card>
                  <CropperComponent
                    modal={signatureModal}
                    toggle={toggleModal}
                    onSave={handleImageSave}
                  />

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          {" "}
                          Legal & Bank Information
                        </BlockTitle>
                        <p> BusinessType, CIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit && (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("LegalInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">GST Treatment</div>
                          <div className="data-value">
                            {data.bussinessType == "unregistered"
                              ? "Unregistered Bussiness"
                              : "Registered Bussiness"}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Holder Name</div>
                          <div className="data-value">
                            {data.accountHolderName || "N/A"}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Number</div>
                          <div className="data-value">
                            {data.accountNumber || "N/A"}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Bank Name</div>
                          <div className="data-value">{data.bankName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Branch</div>
                          <div className="data-value">{data.branchName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">IFSC Code</div>
                          <div className="data-value">{data.ifscCode}</div>
                        </div>
                      </li>

                      {docs?.map((document: any) => {
                        if (document.moduleFileType == "cheque")
                          return (
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">
                                  Canceled Cheque
                                </div>
                                <span className="data-value">
                                  <a
                                    href={document.filePath}
                                    target="_blank"
                                    style={{
                                      pointerEvents: "auto",
                                      cursor: "default",
                                    }}
                                  >
                                    {document.fileName}
                                  </a>
                                </span>
                              </div>
                            </li>
                          );
                      })}
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contact Information</BlockTitle>
                        <p>Basic Contact Information Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("ContactInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Salutation</div>
                          <div className="data-value">{data.salutation}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">First Name</div>
                          <div className="data-value">{data.firstName}</div>
                        </div>
                      </li>{" "}
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Last Name</div>
                          <div className="data-value">{data.lastName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email ID</div>
                          <div className="data-value">
                            <div className="data-value">{data.email}</div>
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Work Phone</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Mobile</div>
                          <div className="data-value">{data.mobileNumber}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Address Information</BlockTitle>
                        <p>Basic Address Information Details etc.</p>
                      </BlockHeadContent>
                      {allowedActions?.edit ? (
                        <Button
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("AddNewAddressInformation")
                          }
                        >
                          <Icon name="icon ni ni-plus" />
                          <span>Add</span>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <div className="table-responsive mt-2 mb-2">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Address Type</th>
                            <th>Delivery Location Name</th>
                            <th>Address Line-1</th>
                            {/* <th>Address Line-2</th> */}
                            <th>Country</th>
                            {/* <th>City</th> */}
                            <th>State</th>
                            <th>District</th>
                            <th>Pin Code</th>
                            {/* <th>Phone Number</th>
                            <th>Fax Number</th> */}
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.deliveryAddresses.length > 0 ? (
                            data.deliveryAddresses.map((itm: any) => {
                              return (
                                <tr key={itm.id}>
                                  <td>
                                    {itm.addressType == "Billing"
                                      ? "Billing"
                                      : "Delivery"}
                                  </td>
                                  <td>{itm.deliveryLocationName}</td>
                                  <td>{itm.addressLine1}</td>
                                  {/* <td>{itm.addressLine2}</td> */}
                                  <td>{itm.country.countryName}</td>
                                  {/* <td>{itm.city}</td> */}
                                  <td>{itm.state.stateName}</td>
                                  <td>{itm.district.districtName}</td>
                                  <td>{itm.pincode}</td>
                                  {/* <td>{itm.phoneNumber}</td>
                                  <td>{itm.faxNumber}</td> */}
                                  <td>
                                    {allowedActions?.edit ? (
                                      <Button
                                        outline
                                        className="d-none d-sm-inline-flex"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          openEditModal("AddressInformation");
                                          setValue("addressIndex", itm.id);
                                          setValue(
                                            "deliveryLocationName",
                                            itm.deliveryLocationName
                                          );
                                          setValue(
                                            "country",
                                            itm.country.countryId
                                          );
                                          setValue(
                                            "addressLine1",
                                            itm.addressLine1
                                          );
                                          setValue(
                                            "addressLine2",
                                            itm.addressLine2
                                              ? itm.addressLine2
                                              : "N/A"
                                          );
                                          setValue("city", itm.city);
                                          setValue("state", itm.state.stateId);
                                          setValue(
                                            "district",
                                            itm.district.districtId
                                          );
                                          setValue("pincode", itm.pincode);
                                          setValue("phone", itm.phoneNumber);
                                          setValue(
                                            "contactPersonName",
                                            itm.contactPersonName
                                          );
                                          setValue("faxNumber", itm.faxNumber);
                                          setValue(
                                            "addressType",
                                            itm.addressType
                                          );
                                          setaaddressDataList(
                                            data.deliveryAddresses
                                          );
                                        }}
                                      >
                                        <Icon name="edit-alt-fill" />
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <span className="text-silent mt-5">
                                    No data found
                                  </span>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "GeneralInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit General Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <div className="col-md-4">
                              <FormSelect
                                name="customerType"
                                label="Customer Type"
                                required={true}
                                options={customerOptions}
                              />
                            </div>
                            <div className="col-md-4">
                              <FormInput
                                name="orgName"
                                label="Organisation Name"
                                required={true}
                                type="text"
                                minLength={2}
                                placeholder="Enter Organisation name"
                              />
                            </div>
                            <div className="col-md-4">
                              <FormSelect
                                name="customerOrgType"
                                label="Organisation Type"
                                options={orgTypeOptions}
                                required={true}
                                placeholder="-Select-"
                              />
                            </div>
                          </Row>
                          <Row className="gy-4 mt-2">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "LegalInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Legal Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <div className="col-md-4">
                              <FormSelect
                                name="businessType"
                                label="GST Treatment"
                                required={true}
                                options={[
                                  {
                                    value: "registered",
                                    label: "Registered Business",
                                  },
                                  {
                                    value: "unregistered",
                                    label: "Unregistered Business",
                                  },
                                ]}
                              />
                            </div>
                            <div className="col-md-4">
                              <FormInput
                                name="cin"
                                label="CIN / LLPIN"
                                // disabled={gstinVerified == "true"}
                                // required={true}
                                type="text"
                                placeholder="Enter CIN / LLPIN"
                                pattern={/^.*$/}
                                // pattern={/^[a-zA-Z0-9]+$/}
                                // pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
                              />
                            </div>
                            {businessType === "registered" && (
                              <>
                                <div className="col-md-4">
                                  <FormInput
                                    name="gstin"
                                    label="GSTIN"
                                    required={true}
                                    type="text"
                                    placeholder="Enter GSTIN"
                                    pattern={
                                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                                    }
                                  />
                                </div>
                                {verifyingGstin && (
                                  <Col
                                    lg="4"
                                    className="d-flex align-items-end"
                                  >
                                    <Spinner
                                      size="sm"
                                      color="primary"
                                      className="m-2"
                                    />
                                  </Col>
                                )}
                                <span style={{ display: "none" }}>
                                  <FormInput name="gstinVerified" label="" />
                                </span>
                                {verifyingGstin}
                                {businessType === "registered" &&
                                  !verifyingGstin && (
                                    <>
                                      <div className="col-md-4">
                                        <FormInput
                                          name="pan"
                                          label="PAN"
                                          disabled
                                          required={true}
                                          type="text"
                                          placeholder="Enter PAN"
                                          pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                                        />
                                      </div>
                                    </>
                                  )}
                              </>
                            )}
                          </Row>
                          <Row className="gy-4 mt-1">
                            <h5>Bank Information</h5>
                            <div className="col-md-4">
                              <FormInput
                                name="accountName"
                                label="Account Holder Name"
                                required={true}
                                type="text"
                                placeholder="Enter Account Name"
                                // minLength={10}
                                // maxLength={20}
                              />
                            </div>
                            <div className="col-md-4">
                              <FormInput
                                name="bankaccountNumber"
                                label="Account Number"
                                required={true}
                                type="text"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                placeholder="Bank Account Number"
                              />
                            </div>
                            <div className="col-md-4">
                              <FormInput
                                name="bankName"
                                label="Bank Name"
                                required={true}
                                type="text"
                                placeholder="Enter Bank Name"
                                // minLength={5}
                                // maxLength={30}
                              />
                            </div>
                            <div className="col-md-4">
                              <FormInput
                                name="branchName"
                                label="Branch Name"
                                required={true}
                                type="text"
                                placeholder="Enter Branch Name"
                                // minLength={3}
                                // maxLength={30}
                              />
                            </div>
                            <div className="col-md-4">
                              <FormInput
                                name="ifcCode"
                                label="IFSC Code"
                                required={true}
                                type="text"
                                placeholder="Enter IFSC Code"
                                pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
                                maxLength={11}
                              />
                            </div>
                            <div className="col-md-4">
                              {/* <div className="form-group"> */}
                              <FormInput
                                type="file"
                                required={
                                  docs?.map(
                                    (item: any) =>
                                      item.moduleFileType == "cheque" &&
                                      item.fileName
                                  )
                                    ? false
                                    : true
                                }
                                name="cancelledCheque"
                                label="Upload Cancelled Cheque"
                              />
                              {cancelledChequeWatch && (
                                <div>{cancelledChequeWatch[0]?.name}</div>
                              )}
                              {!cancelledChequeWatch && (
                                <div>
                                  {docs?.map(
                                    (document: any) =>
                                      document.moduleFileType == "cheque" &&
                                      document.fileName
                                  )}
                                </div>
                              )}
                              {/* </div> */}
                            </div>
                          </Row>

                          <Row className="gy-4 mt-2">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ContactInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contact Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row>
                            {/* {fields.map((field, index) => ( */}

                            <div className="row mb-3">
                              <div className="col">
                                <FormSelect
                                  name="salutation"
                                  label="Select Salutation"
                                  required={true}
                                  options={[
                                    { label: "Mr.", value: "Mr." },
                                    { label: "Mrs.", value: "Mrs." },
                                    { label: "Ms.", value: "Ms." },
                                    { label: "Miss.", value: "Miss." },
                                    { label: "Dr.", value: "Dr." },
                                  ]}
                                  placeholder="Select Salutation"
                                />
                              </div>
                              <div className="col">
                                <FormInput
                                  name="firstName"
                                  label="First Name"
                                  required={true}
                                  type="text"
                                  placeholder="First Name"
                                />
                              </div>
                              <div className="col">
                                <FormInput
                                  name="lastName"
                                  label="Last Name"
                                  required={true}
                                  type="text"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </Row>
                          <Row>
                            <div className="col">
                              <FormInput
                                name="email"
                                label="Email ID"
                                required={true}
                                type="email"
                                placeholder="Email ID"
                              />
                            </div>
                            <div className="col">
                              <FormInput
                                name="workPhone"
                                label="Work Phone"
                                // required={true}
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                type="text"
                                placeholder="Work Phone"
                              />
                            </div>
                            <div className="col">
                              <FormInput
                                name="mobile"
                                label="Mobile"
                                required={true}
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                type="text"
                                placeholder="Mobile"
                              />
                            </div>
                          </Row>
                          <Row className="gy-4 mt-2">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {(modalEditBlockName === "AddressInformation" ||
                modalEditBlockName === "AddNewAddressInformation") && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        {modalEditBlockName == "AddressInformation"
                          ? "Edit Address Information"
                          : "Add New Address"}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit((addressData) => {
                            try {
                              const formData: any = new FormData();

                              formData.append("files", JSON.stringify([]));
                              formData.append(
                                "filesData",
                                JSON.stringify({
                                  newfiledata: [],
                                  deletedFileData: [],
                                  updatedFileData: [],
                                })
                              );
                              const {
                                addressIndex,
                                deliveryLocationName: newDeliveryLocation,
                                country: newCountry,
                                addressLine1: newAddressLine1,
                                addressLine2: newAddressLine2,
                                city: newCity,
                                state: newState,
                                district: newDistrict,
                                pincode: newPincode,
                                phone: newPhone,
                                contactPersonName: newContactPersonName,
                                faxNumber: newFaxNumber,
                                addressType: newAddressType,
                              } = addressData;
                              console.log("addressData", addressData);
                              if (
                                modalEditBlockName ===
                                "AddNewAddressInformation"
                              ) {
                                const billingAddress =
                                  data?.deliveryAddresses?.find(
                                    (address: any) =>
                                      address?.addressType == "Billing"
                                  );
                                if (
                                  billingAddress &&
                                  newAddressType == "Billing"
                                ) {
                                  Swal.fire(
                                    "Failed!",
                                    "Billing Address Already Exists",
                                    "error"
                                  );
                                } else {
                                  formData.append(
                                    "customerData",
                                    JSON.stringify({
                                      deliveryAddresses: [
                                        ...data?.deliveryAddresses,
                                        {
                                          deliveryLocationName:
                                            newDeliveryLocation,
                                          country: newCountry,
                                          addressLine1: newAddressLine1,
                                          addressLine2: newAddressLine2,
                                          city: newCity,
                                          state: newState,
                                          district: newDistrict,
                                          pincode: newPincode,
                                          phoneNumber: newPhone,
                                          contactPersonName:
                                            newContactPersonName,
                                          faxNumber: newFaxNumber,
                                          addressType: newAddressType,
                                        },
                                      ],
                                    })
                                  );
                                  console.log(
                                    "formData",
                                    data?.deliveryAddresses
                                  );
                                  return;
                                  submitUpdates(formData);
                                }
                              } else {
                                const newAddressList =
                                  data?.deliveryAddresses?.map(
                                    (address: any, index: any) => {
                                      console.log(address);
                                      console.log(addressIndex);

                                      if (address?.id == addressIndex) {
                                        return {
                                          deliveryLocationName:
                                            newDeliveryLocation,
                                          country: newCountry,
                                          addressLine1: newAddressLine1,
                                          addressLine2: newAddressLine2,
                                          city: newCity,
                                          state: newState,
                                          district: newDistrict,
                                          pincode: newPincode,
                                          contactPersonName:
                                            newContactPersonName,
                                          phoneNumber: newPhone,
                                          faxNumber: newFaxNumber,
                                          addressType: newAddressType,
                                        };
                                      }
                                      // decode from address
                                      const {
                                        deliveryLocationName,
                                        addressLine1,
                                        addressLine2,
                                        city,
                                        state,
                                        district,
                                        pincode,
                                        faxNumber,
                                        contactPersonName,
                                        phoneNumber,
                                        addressType,
                                      } = address;
                                      return {
                                        deliveryLocationName,
                                        country: address?.country?.countryId,
                                        addressLine1,
                                        addressLine2,
                                        city,
                                        state: address?.state?.stateId,
                                        district: address?.district?.districtId,
                                        pincode,
                                        contactPersonName,
                                        phoneNumber,
                                        faxNumber,
                                        addressType,
                                      };
                                    }
                                  );
                                formData.append(
                                  "customerData",
                                  JSON.stringify({
                                    deliveryAddresses: newAddressList,
                                  })
                                );
                                submitUpdates(formData);
                              }
                              setValue("addressIndex", null);
                              setValue("deliveryLocationName", null);
                              setValue("country", null);
                              setValue("addressLine1", null);
                              setValue("addressLine2", null);
                              setValue("city", null);
                              setValue("state", null);
                              setValue("district", null);
                              setValue("pincode", null);
                              setValue("contactPersonName", null);
                              setValue("phone", null);
                              setValue("faxNumber", null);
                              setValue("addressType", null);
                              setGIModal(false);
                            } catch (error) {
                              console.log(error);
                            }
                          })}
                        >
                          {/* First Row with 4 fields */}
                          <Row>
                            <div className="col mb-4">
                              <FormSelect
                                name="addressType"
                                label="Address Type"
                                required={true}
                                options={[
                                  { label: "Billing", value: "Billing" },
                                  { label: "Delivery", value: "Shipping" },
                                ]}
                                onChange={(value) =>
                                  setValue("addressType", value)
                                }
                                placeholder="Select Address Type"
                              />
                            </div>

                            <div className="col mb-4">
                              <FormInput
                                name="contactPersonName"
                                label="Customer Name"
                                required={true}
                                type="text"
                                placeholder="Enter Customer Name"
                              />
                            </div>
                            <div className="col mb-4">
                              <FormInput
                                name="phone"
                                label="Customer Phone Number"
                                required={true}
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                type="text"
                                placeholder="Enter Customer Phone Number"
                              />
                            </div>

                            <div className="col mb-4">
                              <FormInput
                                name="faxNumber"
                                label="Fax Number"
                                type="number"
                                placeholder="Enter Fax Number"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col mb-3">
                              <FormInput
                                name="deliveryLocationName"
                                label="Delivery Location Name"
                                required={true}
                                placeholder="Select Salutation"
                              />
                            </div>
                            <div className="col mb-3">
                              <FormInput
                                name="addressLine1"
                                label="AddressLine-1"
                                required={true}
                                type="text"
                                placeholder="Enter Address"
                              />
                            </div>
                            <div className="col mb-3">
                              <FormInput
                                name="addressLine2"
                                label="AddressLine-2"
                                type="text"
                                placeholder="Enter Address"
                              />
                            </div>
                            <div className="col mb-3">
                              <FormSelect
                                name="country"
                                label="Country"
                                required={true}
                                options={countryOptions}
                                placeholder="Select Country"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col mb-3">
                              <FormSelect
                                name="state"
                                label="State"
                                required={true}
                                options={stateOptions}
                                placeholder="Select State"
                              />
                            </div>
                            <div className="col mb-3">
                              <FormSelect
                                name="district"
                                label="District"
                                required={true}
                                options={districtOptions}
                                placeholder="Select District"
                              />
                            </div>
                            <div className="col mb-3">
                              <FormInput
                                name="city"
                                label="City"
                                required={true}
                                placeholder="Enter City"
                              />
                            </div>
                            <div className="col mb-3">
                              <FormInput
                                name="pincode"
                                label="Pincode"
                                required={true}
                                type="number"
                                placeholder="Enter Pincode"
                              />
                            </div>
                          </Row>

                          {/* Address Type field in the second row (if needed) */}

                          {/* Submit and Cancel Buttons */}
                          <Row className="gy-4 mt-2">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewCustomerDetails;
