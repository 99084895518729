import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Badge, ModalBody, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm, useFormContext } from "react-hook-form";
import {
  Table,
  Collapse as AntCollapse,
  TableColumnsType,
  Descriptions,
  Modal,
  Timeline,
  Popover,
  Empty,
} from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/Utils";
import invoiceApis from "../../../api/Invoice/invoice";
import Swal from "sweetalert2";
import quotationApis from "../../../api/master/quotations";
import { useNavigate } from "react-router";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = AntCollapse;

const Preview: React.FC = (props: any) => {
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { data: currentUser } = useSelector((state: any) => state.currentUser);
  const dispatch: any = useDispatch();
  const [tab, setTab] = useState("1");
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const { watch, register } = useFormContext();
  const [taxConfigurationObj, setTaxConfigurationObj] = useState<any>({});
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { handleSubmit } = useForm();

  const [currency, setCurrency] = useState<any>({
    currencyFormat: "₹#,##0.00",
    currencySymbol: "₹",
    currency_code: "INR",
  });
  const [signature, setSignature] = useState<any>([]);
  const navigate = useNavigate();
  const previousFormData = watch();

  const base64ToFile = (base64String: string, fileName: string) => {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  };
  const nanoid = (length: any) => {
    return Math.random()
      .toString(36)
      .substring(2, length || 8);
  };

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      width: 150,
      // hidden: previousFormData?.needByDateType === "Line Level" ? false : true,
      render: (text) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Min Purchase Qty",
      dataIndex: "minQty",
      key: "minQty",
      width: 200,
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsn",
      key: "hsn",
      width: 100,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
      render: (text) => (
        <p className="justify-end">{formatCurrency(Number(text), "INR")}</p>
      ),
    },
    {
      title: "Basic Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgstAmount",
          key: "cgstAmount",
          width: 150,
          render: (text) => <p className="text-right">{text}</p>,
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgstAmount",
          key: "sgstAmount",
          width: 150,
        },
      ],
    },
    {
      title: "Line Total",
      key: "lineTotal",
      width: 150,
      render: (text, record: any) => {
        return (
          <p className="justify-end">
            {formatCurrency(Number(record?.lineTotal) || 0, "INR")}
          </p>
        );
      },
    },
    {
      title: "Delivery Lead Days",
      dataIndex: "leadDays",
      key: "leadDays",
      width: 150,
    },
  ]);

  const createSelfQuotation = async (formData: any) => {
    setDisableSubmit(true);
    try {
      const res = await quotationApis.submitSupplierSelfQuotion(formData);
      const { response: _data, status, message } = res;
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Quotation Generated",
          text: _data.rfqId,
        });
        navigate("/quoted_view");
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
    } finally {
      setDisableSubmit(false);
    }
  };
  const onSubmit = async (formdata: any) => {
    try {
      const formData: any = new FormData();
      let _filesData: any = [];
      let orderData: any = {
        ...previousFormData,
      };
      console.log("orderData", orderData);
      const lines = await Promise.all(
        previousFormData?.items.map(async (lineItem: any, index: number) => {
          let obj = { ...lineItem };
          let finalObj: any = {
            categoryId: Number(obj?.materialCategory),
            materialId: Number(obj?.materialName),
            specification: obj?.specification,
            quantity: Number(obj?.quantity),
            uomId: Number(obj?.uomId),
            hsn: obj?.hsn,
            minQty: Number(obj?.minQty),
            price: Number(obj?.price),
            sgst: obj?.sgstPercentage != 0 ? Number(obj?.sgstPercentage) : null,
            cgst: obj?.cgstPercentage != 0 ? Number(obj?.cgstPercentage) : null,
            igst: obj?.igstPercentage != 0 ? Number(obj?.igstPercentage) : null,
            // additionalCharges: 0,
            deliveryDate: dayjs(obj?.deliveryDate).format("YYYY-MM-DD"),
            leadDays: Number(obj?.leadDays),
          };
          return finalObj;
        })
      );

      const {
        orgId,
        rfqId,
        quotationDescription,
        preferredBrands,
        negotiable,
        validityDate,
        billingAddressId,
        deliveryAddressId,
        deliveryAddressType,
        signatureObj,
        instructions,
        termsAndConditions,
        dcAttachments,
        paymentTerms,
        customerOrgId,
        paymentDays,
      } = orderData;
      const signature = JSON.parse(signatureObj);
      console.log("attachments", dcAttachments, orderData);
      if (signature?.image) {
        const image = base64ToFile(signature?.image, "signature.png");
        // console.log("image", image);
        formData.append("files", image);
        _filesData.push({
          fileIndex: _filesData.length,
          module: "po",
          moduleFileType: "signature",
          level: "HEADER_LEVEL",
          // uniqueId: nanoid(4),
          // action: "Create",
        });
      }

      if (dcAttachments) {
        dcAttachments.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          const file = attachment?.originFileObj;
          formData.append(`files`, file);
          _filesData.push({
            fileIndex: _filesData.length,
            module: "quotation",
            moduleFileType: "attachments",
            level: "HEADER_LEVEL",
            // uniqueId: nanoid(4),
            // action: "Create",
          });
        });
      }
      if (instructions) {
        const instructionsFile = new Blob([instructions], {
          type: "text/html",
        });
        formData.append(
          `files`,
          new File([instructionsFile], "instructions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          fileIndex: _filesData.length,
          module: "quotation",
          moduleFileType: "instructions",
          level: "HEADER_LEVEL",
          // uniqueId: nanoid(4),
          // action: "Create",
        });
      }

      if (termsAndConditions) {
        const termsAndConditionsFile = new Blob([termsAndConditions], {
          type: "text/html",
        });
        formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsandconditions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          fileIndex: _filesData.length,
          module: "quotation",
          moduleFileType: "termsAndConditions",
          level: "HEADER_LEVEL",
          // uniqueId: nanoid(4),
          // action: "Create",
        });
      }

      console.log("additionalCharges", additionalCharges);

      let _OrderData: any = {
        orgId: customerOrgId,
        rfqId,
        quotationDescription,
        preferredBrands,
        negotiable,
        validityDate: validityDate && dayjs(validityDate).format("YYYY-MM-DD"),
        billingAddressId: billingAddressId,
        deliveryAddressId: deliveryAddressId,
        // signatureObj,
        // instructions,
        // termsAndConditions,
        // dcAttachments,
        paymentTerms,
        paymentDays: paymentDays ? paymentDays : null,
        signatureLabel: signature?.label,
        additionalChargesArray: additionalCharges?.map((charge: any) => {
          return {
            name: charge?.name,
            price: charge?.price,
          };
        }),
      };

      formData.append("header", JSON.stringify(_OrderData));
      formData.append("lines", JSON.stringify(lines));
      formData.append("docKeys", JSON.stringify(_filesData));
      createSelfQuotation(formData);
    } catch (error) {}
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const updateColumnsWithCurrencySymbol = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.dataIndex == "unitPrice" || col.dataIndex == "amount") {
          return {
            ...col,
            render: (text: any) => (
              <div className="justify-end">
                {formatCurrency(text, currency?.currency_code)}
              </div>
            ),
          };
        }
        if (col.children) {
          let children = col.children.map((child: any) => {
            if (child.title == "Amount") {
              return {
                ...child,
                render: (text: any) => (
                  <div className="justify-end">
                    {formatCurrency(text, currency?.currency_code)}
                  </div>
                ),
              };
            }
            return child;
          });
          return {
            ...col,
            children,
          };
        }
        return col;
      });
    });
  }, [currency]);

  useEffect(() => {
    updateColumnsWithCurrencySymbol();
  }, [currency, updateColumnsWithCurrencySymbol]);

  useEffect(() => {
    const {
      currencyObj,
      signatureObj,
      taxConfigurationObj,
      additionalCharges,
    } = previousFormData;
    console.log("previousFormData", previousFormData);
    if (additionalCharges) {
      const _additionalCharges = JSON.parse(additionalCharges);
      setAdditionalCharges(_additionalCharges);
    }
    if (currencyObj) {
      const _currency = JSON.parse(currencyObj);
      setCurrency(_currency);
    }
    if (signatureObj) {
      const _signature = JSON.parse(signatureObj);
      setSignature(_signature);
    }
    if (taxConfigurationObj) {
      onConfigureTaxSave(JSON.parse(taxConfigurationObj), false);
    }
  }, []);

  const onConfigureTaxSave = (data: any, updateField = true) => {
    setTaxConfigurationObj(data);
    setColumns((pre) => {
      // if (!data?.cess?.length) return pre;
      let newColArray = [...pre].slice(0, 6);
      let additionalCol: any = [];
      if (data?.gstType == "IGST") {
        additionalCol.push({
          title: "IGST",
          children: [
            {
              title: "%",
              dataIndex: "igstPercentage",
              key: "igstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "igstAmount",
              key: "igstAmount",
              width: 150,
            },
          ],
        });
      } else {
        additionalCol.push({
          title: "CGST",
          children: [
            {
              title: "%",
              dataIndex: "cgstPercentage",
              key: "cgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "cgstAmount",
              key: "cgstAmount",
              width: 150,
            },
          ],
        });
        additionalCol.push({
          title: "SGST",
          children: [
            {
              title: "%",
              dataIndex: "sgstPercentage",
              key: "sgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "sgstAmount",
              key: "sgstAmount",
              width: 150,
            },
          ],
        });
      }
      if (data?.cess.length) {
        data.cess.forEach((item: any) => {
          if (!item.cessName) return;
          additionalCol.push({
            title: item.cessName,
            children: [
              {
                title: "%",
                dataIndex: item.cessName,
                key: item.cessName,
                width: 100,
                render: (text: any) => `${Number(text)}%`,
              },
              {
                title: "Amount",
                dataIndex: `${item.cessName}Amount`,
                key: `${item.cessName}Amount`,
                width: 150,
              },
            ],
          });
        });
      }
      additionalCol.push({
        title: "Line Total",
        key: "lineTotal",
        width: 150,
        render: (text: any, record: any) => {
          return (
            <p className="justify-end">
              {formatCurrency(
                Number(record?.lineTotal),
                currency?.currency_code
              )}
            </p>
          );
        },
      });

      return newColArray.concat(additionalCol);
    });
  };

  const ShowAddressPopOver = ({ address, text }: any) => {
    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city},{address?.district?.districtName},{" "}
              {address?.state?.stateName},{address?.country?.countryName} -{" "}
              {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>
          <span
            style={{
              color: "#2263b3",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {address?.deliveryLocationName}
          </span>
        </a>
      </Popover>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = JSON.parse(previousFormData?.approvalsData);
    if (
      !approvalsData ||
      approvalsData?.data?.length === 0 ||
      previousFormData?.approvalRequired == "false"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.data?.map((approval: any, index: number) => {
              return (
                <Timeline.Item key={index}>
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  return (
    <>
      <Head title="New - Quotation " />
      <div className="content clearfix">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AntCollapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
            <Panel
              header={
                <div className="d-flex align-items-center justify-content-between">
                  <>General Information</>
                </div>
              }
              key="1"
            >
              <div className="mt-0">
                <Col className="justify-start w-100  flex-wrap ">
                  <div className="w-30 border-end ">
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Customer</div>
                      <div className="data-value w-70">
                        {previousFormData?.orgName || "--"}
                      </div>
                    </div>

                    <div className="data-col align-center pt-1 ">
                      <div className="data-label w-30">RFQ Number</div>
                      <div className="data-value w-70 ">
                        {previousFormData.rfqId || "N/A"}
                      </div>
                    </div>
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Validity Date</div>
                      <div className="data-value w-70">
                        {dayjs(previousFormData?.validityDate).format(
                          "DD-MMM-YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-30 paddingLeft10 border-end  ">
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Make</div>
                      <div className="data-value w-70">
                        {previousFormData?.preferredBrands || "N/A"}
                      </div>
                    </div>

                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Negotiable</div>
                      <div className="data-value w-70">
                        {previousFormData?.negotiable || "--"}
                      </div>
                    </div>

                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Billing Address</div>
                      <div className="data-value w-70">
                        <ShowAddressPopOver
                          address={previousFormData?.billingAddress}
                          text={"View"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-40 paddingLeft10">
                    <div className="data-col align-center pt-1 ">
                      <div className="data-label w-30">Payment Terms</div>
                      <div className="data-value w-70 ">
                        {previousFormData.paymentTerms || "N/A"}
                      </div>
                    </div>
                    {previousFormData.paymentTerms == "Credit" && (
                      <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Credit Days </div>
                        <div className="data-value w-70 ">
                          {previousFormData.paymentDays || "N/A"}
                        </div>
                      </div>
                    )}
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Delivery Address</div>
                      <div className="data-value w-70">
                        <ShowAddressPopOver
                          address={previousFormData?.deliveryAddress}
                          text={"View"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="data-col align-left pt-1 mt-3">
                    <div className="data-label" style={{ width: "10%" }}>
                      Description
                    </div>
                    <div className="data-value w-90 align-left">
                      {previousFormData?.quotationDescription || "--"}
                    </div>
                  </div>
                </Col>
              </div>
            </Panel>
            <Panel header="Material Information" key="2">
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 170 + "px",
                    margin: "auto",
                  }}
                >
                  {columns && (
                    <Table
                      className="customTable"
                      columns={columns}
                      dataSource={previousFormData?.items}
                      scroll={{ x: 1000 }}
                      // expandable={{
                      //   expandedRowRender: (record: any) => (
                      //     <div className="expandableBody">
                      //       <div style={{ flex: 1, textAlign: "left" }}>
                      //         <p style={{ margin: 0 }}>{record.description}</p>
                      //       </div>
                      //     </div>
                      //   ),
                      // }}
                      bordered
                      size="small"
                      pagination={false}
                    />
                  )}
                </div>
                {previousFormData?.items?.length > 0 && (
                  <Row
                    style={{
                      margin: "20px",
                    }}
                  >
                    <Col className="justify-end">
                      <div className="w-40">
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>Taxable Amount</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.amount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                        {previousFormData?.items.reduce(
                          (a: any, b: any) => a + Number(b.igstAmount),
                          0
                        ) > 0 ? (
                          <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                              <strong>IGST</strong>
                            </div>
                            <span className="r_align">
                              {formatCurrency(
                                previousFormData?.items.reduce(
                                  (a: any, b: any) => a + Number(b.igstAmount),
                                  0
                                ),
                                currency?.currency_code
                              )}
                            </span>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-between">
                              <div className="w-50 justify-end">
                                <strong>CGST</strong>
                              </div>
                              <span className="r_align">
                                {formatCurrency(
                                  previousFormData?.items.reduce(
                                    (a: any, b: any) =>
                                      a + Number(b.cgstAmount),
                                    0
                                  ),
                                  currency?.currency_code
                                )}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="w-50 justify-end">
                                <strong>SGST</strong>
                              </div>
                              <span className="r_align">
                                {formatCurrency(
                                  previousFormData?.items.reduce(
                                    (a: any, b: any) =>
                                      a + Number(b.sgstAmount),
                                    0
                                  ),
                                  currency?.currency_code
                                )}
                              </span>
                            </div>
                          </>
                        )}
                        {additionalCharges?.map((field: any) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <div className="w-50 justify-end">
                                <strong>{field.name}</strong>
                              </div>
                              <span className="r_align">
                                {formatCurrency(
                                  Number(field.price),
                                  currency?.currency_code
                                )}
                              </span>
                            </div>
                          );
                        })}
                        <div
                          className="d-flex justify-content-between"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                        >
                          <div className="w-50 justify-end">
                            <strong>Total</strong>
                          </div>
                          <strong className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.lineTotal),
                                0
                              ) +
                                additionalCharges.reduce(
                                  (a: any, b: any) =>
                                    Number(a) + Number(b.price),
                                  0
                                ),
                              currency?.currency_code
                            )}
                          </strong>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="m-4 justify-content-end ">
                  {signature?.isActive && (
                    <div className="signatureBtnWrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>Signature</strong>
                      </div>
                      <div
                        style={{
                          minHeight: signature?.image ? "0px" : "120px",
                          display: signature?.image ? "block" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {signature?.image && (
                          <>
                            <div className="signatueImgWrapper border">
                              <img
                                src={signature?.image}
                                alt="Signed"
                                style={{ width: "160px" }}
                              />
                            </div>

                            <div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="signatureLabel"
                                >
                                  Signature Label
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="signatureLabel"
                                  onChange={(e) => {}}
                                  disabled
                                  defaultValue={
                                    signature?.label || "Authorised Signatory"
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </Panel>
            <Panel header="Other Information" key="3">
              <Descriptions title="" className="custom-description-title">
                <Descriptions.Item label="Instructions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setInstructionsVisible(true)}
                  >
                    View
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Terms and Conditions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setTermsVisible(true)}
                  >
                    View
                  </span>
                </Descriptions.Item>
                {previousFormData?.dcAttachments?.length > 0 && (
                  <Descriptions.Item label="">
                    <div>
                      <div className="">
                        Attachments &nbsp;
                        <PaperClipOutlined />
                      </div>
                      <div>
                        {[previousFormData?.dcAttachments?.[0]]?.map(
                          (attachment: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div>
                                  <strong>{index + 1}.</strong>
                                  &nbsp;
                                  {attachment?.name}
                                  &nbsp; &nbsp; &nbsp; &nbsp;
                                </div>
                                <div></div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
          </AntCollapse>
          <Modal
            title="Instructions"
            visible={instructionsVisible}
            footer={null}
            onCancel={() => setInstructionsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.instructions ? (
              <pre>{previousFormData?.instructions}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No instructions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <Modal
            title="Terms and Conditions"
            visible={termsVisible}
            footer={null}
            onCancel={() => setTermsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.termsAndConditions ? (
              <pre>{previousFormData?.termsAndConditions}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No terms and conditions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <div className="actions clearfix">
            <ul>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                )}
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default Preview;
