import React, { useEffect, FC } from "react";
import { Modal, ModalBody, Form, Row, Alert } from "reactstrap";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Col } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormTextArea from "../../form-components/TextAreaComponent";

const AddModal: FC<any> = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  alertInfo,
}) => {
  useEffect(() => {
    reset(formData);
  }, [formData]);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  return (
    <Modal
      isOpen={modal}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add Department</h5>
          {alertInfo.message && (
            <div className="mb-3 mt-1">
              <Alert
                color={alertInfo.type === "error" ? "danger" : "success"}
                className="alert-icon"
              >
                <strong>{alertInfo.message}</strong>
                <Icon
                  name={
                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                  }
                />
                {alertInfo.errors.length > 0 ? (
                  <ul>
                    {alertInfo.errors.map((err: any, index: any) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <FormProvider {...methods}>
              <Form
                className="row gy-4"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      label="Department ID"
                      name="referenceID"
                      required={true}
                      type="text"
                      pattern={/^[a-zA-Z0-9./_-]+$/}
                      placeholder="Enter Department ID"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      label="Department Name"
                      name="deptName"
                      type="text"
                      required={true}
                      placeholder="Enter Department Name"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      label="Team Count"
                      name="teamCount"
                      type="number"
                      placeholder="Team Count"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <FormTextArea
                      name="description"
                      label="Description"
                      placeholder="Enter Description"
                      required={true}
                      maxLength={50}
                    />
                  </div>
                </Col>
                <Row className="mt-4">
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          className="btn btn-primary btn-md"
                          type="submit"
                          color="primary"
                        >
                          {/* <Icon name="check" /> */}
                          <span>Submit</span>
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            closeModal();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AddModal;
