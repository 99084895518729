import exportFromJSON from "export-from-json";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { Icon } from "../Component";
import { useNavigate } from "react-router";
import miscService from "../../api/misc/misc";
import Swal from "sweetalert2";
import { Form, Modal as AntModal, Input } from "antd";

const reportConfiguration: any = {
  "view-purchase-order": {
    reportType: "Orders",
    reportModule: "orderBilling",
  },
  "view-invoice-list": {
    reportType: "Invoices",
    reportModule: "orderBilling",
  },
  "view-payments": {
    reportType: "Payments",
    reportModule: "orderBilling",
  },
  "material-list": {
    reportType: "Supplier Materials",
    reportModule: "masters",
  },
  "view-purchase-requests-list": {
    reportType: "Purchase Request",
    reportModule: "transactions",
  },
  "view-budget-list": {
    reportType: "Budgets",
    reportModule: "transactions",
  },
  "view-rfqs": {
    reportType: "Quotations",
    reportModule: "transactions",
  },
  awardFromCustomerReport: {
    reportType: "Award from Customer",
    reportModule: "reports",
  },
  invoicesForSalesByCustomerReport: {
    reportType: "Award from Customer Details",
    reportModule: "reports",
  },
  salesByCustomerReport: {
    reportType: "Sales By Customer",
    reportModule: "reports",
  },
  salesByCustomerDetailsReport: {
    reportType: "Sales By Customer Details",
    reportModule: "reports",
  },
  salesByMaterialReport: {
    reportType: "Sales By Material",
    reportModule: "reports",
  },
  invoicesForSalesByMaterialReport: {
    reportType: "Sales By Material Details",
    reportModule: "reports",
  },
  salesInvoiceDetailsReport: {
    reportType: "Sales Report",
    reportModule: "reports",
  },
  customerBalancesReport: {
    reportType: "Customer Balances",
    reportModule: "reports",
  },
  customerBalancesDetailReport: {
    reportType: "Customer Balances Details",
    reportModule: "reports",
  },
  receivablesAgingSummaryReport: {
    reportType: "Receivables Aging Summary",
    reportModule: "reports",
  },
  receivablesAgingSummaryDetailReport: {
    reportType: "Receivables Aging Summary Details",
    reportModule: "reports",
  },
  paymentsReceivedByCustomerReport: {
    reportType: "Payments Received By Customer",
    reportModule: "reports",
  },
  paymentsReceivedByCustomerDetailReport: {
    reportType: "Payments Received By Customer Details",
    reportModule: "reports",
  },
  deliveryNoteByCustomerReport: {
    reportType: "Delivery Note Details",
    reportModule: "reports",
  },
};

const Export = ({
  data,
  moduleKey,
  reportType = "transactionFlow",
}: {
  data: any;
  moduleKey?: any;
  reportType?: any;
}) => {
  const params = data;
  const [modal, setModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const navigate = useNavigate();
  // const exportCSV = () => {
  //   const exportType = exportFromJSON.types.csv;
  //   exportFromJSON({ data, fileName, exportType });
  // };

  // const exportExcel = () => {
  //   const exportType = exportFromJSON.types.xls;
  //   exportFromJSON({ data, fileName, exportType });
  // };

  // const copyToClipboard = () => {
  //   navigator.clipboard.writeText(JSON.stringify(data));
  //   setModal(true);
  // };

  const handelExcelClick = () => {
    setShowCommentsModal(true);
  };

  const getExtraPaginationParams = (moduleKey: any) => {
    const { selectFilter1, selectFilter2, selectFilter3, customerId } = params;
    const extraparmsobj: any = {
      "view-purchase-order": {
        orderStatus: selectFilter1,
      },
      "view-invoice-list": {
        invoiceStatus: selectFilter1,
      },
      "material-list": {
        categoryFilters: selectFilter1,
      },
      "suppliers-list": {
        categoryFilters: selectFilter1,
      },
      "view-purchase-requests-list": {
        status: selectFilter1,
      },
      "view-budget-list": {
        status: selectFilter1,
      },
      "view-rfqs": {
        overallStatus: selectFilter1,
      },
      invoicesForSalesByCustomerReport: {
        customerIds: customerId,
      },
      salesByCustomerDetailsReport: {
        customerIds: customerId,
      },
      invoicesForSalesByMaterialReport: {
        materialIds: customerId,
      },
      customerBalancesDetailReport: {
        customerId: customerId,
      },
      receivablesAgingSummaryDetailReport: {
        customerId: customerId,
      },
      paymentsReceivedByCustomerReport: {
        transactionNumber: customerId,
      },
      deliveryNoteByCustomerReport: {
        customerId: customerId,
      },
    };
    return extraparmsobj[moduleKey];
  };

  const comments = async (comments: any) => {
    try {
      const {
        sortOrder,
        pageSize,
        direction,
        pageDiff,
        searchQuery,
        dateRange,
        customDateRange,
        siteId,
        contractorId,
        supplierId,
        page, //reports
        customer
      } = params;
      console.log("params", params);
      const apiResponse = await miscService.excelExportRequest({
        ...reportConfiguration[moduleKey],
        comments,
        paginationData: {
          ...getExtraPaginationParams(moduleKey),
          pageSize: pageSize,
          startDate: dateRange?.from,
          endDate: dateRange?.to,
          ...(reportType == "transactionFlow"
            ? {
                sortOrder: sortOrder,
                direction: direction,
                pageDiff: pageDiff,
                searchQuery: searchQuery,
              }
            : {
                page: page,
                customerIds:customer
                // contractorIds: contractorId ? contractorId : undefined,
                // siteIds: siteId ? siteId : undefined,
                // supplierIdS: supplierId ? supplierId : undefined,
              }),
        },
      });
      const { data, status } = apiResponse;
      if (status) {
        const { list, count } = data;
        Swal.fire({
          title: "Success",
          text: "Request Submited Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  return (
    <>
      <UncontrolledDropdown>
        <div
          // size={size || "md"}
          onClick={() => handelExcelClick()}
          className="btn btn-white btn-outline-light"
        >
          <Icon name="download-cloud"></Icon>
          <span>Export </span>
        </div>
      </UncontrolledDropdown>
      <CommentsModal
        isModalVisible={showCommentsModal}
        setIsModalVisible={setShowCommentsModal}
        comments={comments}
      />
    </>
  );
};

const CommentsModal = ({
  isModalVisible,
  setIsModalVisible,
  comments,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        comments(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <AntModal
      title="Comments"
      visible={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Comments"
          rules={[{ required: true, message: "Please input your Comments!" }]}
        >
          <Input.TextArea placeholder="Type your Comments here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Request
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </AntModal>
  );
};

export default Export;
