import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Button,
  //   Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Alert,
} from "reactstrap";
import AddAddressModal from "./AddAddressModal"; 
import { Icon, Button as AntButton } from "../../../components/Component";
import { Table } from "antd";
import customersApis from "../../../api/master/customers";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { render } from "@testing-library/react";

const AddressInformation: React.FC = (props: any) => {
  const { control, reset, setValue, watch, handleSubmit } = useFormContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "items",
  });
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [activeAddress, setActiveAddress] = useState<any>({
    action: null,
    index: null,
    item: null,
  });

  useEffect(() => {
    if (alertInfo.type !== "") {
      setTimeout(() => {
        setAlertInfo({
          type: "",
          message: "",
          errors: [],
        });
      }, 3000);
    }
  }, [alertInfo]);
  const navigate = useNavigate();

  const toggleModal = () => {
    setActiveAddress({
      action: "add",
      index: null,
      item: null,
    });
  };

  // const addAddress = (newAddress: any) => {
  //   console.log("addAddress", newAddress);
  //   const billingAddress = addressList?.find(
  //     (item: any) => item.addressType === "Billing"
  //   );
  //   console.log(billingAddress);

  //   if (
  //     newAddress?.formType !== "edit" &&
  //     billingAddress &&
  //     newAddress?.addressType === "Billing"
  //   ) {
  //     setAlertInfo({
  //       type: "error",
  //       message: "Billing Address Already Exists",
  //       errors: [],
  //     });
  //   } else {
  //     // const updatedAddresses = [...addressList, newAddress];
  //     // setValue("addressList", updatedAddresses);
  //     if (newAddress?.formType === "edit") {
  //       setAddressList((pre: any) => {
  //         return pre.map((address: any, ind: any) => {
  //           if (address.id === newAddress.id) {
  //             return newAddress;
  //           }
  //           return address;
  //         });
  //       });
  //     } else {
  //       setAddressList((pre: any) => [...pre, newAddress]);
  //     }
  //   }
  //   toggleModal();
  // };

  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  const submitForm = async (data: any) => {
    let deliveryAddresses = fields?.map((item: any) => {
      return {
        deliveryLocationName: item.attention,
        state: item.state,
        country: item.country,
        city: item.city,
        addressLine1: item.addressLine1,
        addressLine2: item.addressLine2,
        pincode: item.zipCode,
        faxNumber: item.faxNumber,
        phoneNumber: item.phone,
        addressType: item.addressType,
      };
    });

    if (!deliveryAddresses?.length) {
      setAlertInfo({
        type: "error",
        message: "Please add at least one Billing & one Shipping Address",
        errors: [],
      });
      return;
    }
    const atleastOneBillingAddress = deliveryAddresses?.find(
      (address: any) => address?.addressType == "Billing"
    );
    if (!atleastOneBillingAddress) {
      setAlertInfo({
        type: "error",
        message: "Please add at least one billing address",
        errors: [],
      });
      return;
    }
    const atleastOneShippingAddress = deliveryAddresses?.find(
      (address: any) => address?.addressType == "Shipping"
    );
    if (!atleastOneShippingAddress) {
      setAlertInfo({
        type: "error",
        message: "Please add at least one shipping address",
        errors: [],
      });
      return;
    }
    let obj = {
      customerName: data.orgName,
      customerType: data.customerType,
      bussinessType: data.businessType,
      customerOrgType: data.orgType,
      cin: data.cin || null,
      incorporationDate: data.incorporationDate || null,
      pan: data.pan || null,
      gstin: data.gstin || null,
      salutation: data.salutation,
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNumber: data.mobile,
      phoneNumber: data.workPhone,
      email: data.eamilId,
      accountHolderName: data.accountName,
      accountNumber: data.bankaccountNumber,
      bankName: data.bankName,
      branchName: data.branchName,
      ifscCode: data.ifcCode,
      deliveryAddresses: deliveryAddresses,
    };
    const _FormData: any = new FormData();
    const filesData: any = [];
    const files = [];
    if (data?.files) {
      const files = data.files;
      _FormData.append("files", files[0]);
      filesData.push({
        indexOfFileLocated: 0,
        module: "customer",
        purpose: "cheque",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
    }
    if (data?.orgLogo != "") {
      const imageFile = base64ToFile(data?.orgLogo, "orgLogo.png");
      if (imageFile) {
        _FormData.append("files", imageFile);
        filesData.push({
          indexOfFileLocated: 1,
          module: "customer",
          purpose: "org logo",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
      }
    }
    _FormData.append("customerData", JSON.stringify(obj));
    _FormData.append("filesData", JSON.stringify(filesData));
    try {
      const response = await customersApis.createCustomer(_FormData);
      const { status, message } = response;
      if (status) {
        Swal.fire("Created!", "Customer has been created", "success");
        navigate("/customers");
      } else {
        let errorMessage = "An error occurred";
        const fullErrorMessage =
          `${errorMessage}\n${message.join(", ")}` || message;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    {
      title: "Address Type",
      dataIndex: "addressType",
      key: "addressType",
      render: (text: any) => (
        <div>{text == "Billing" ? "Billing" : "Delivery"}</div>
      ),
    },
    {
      title: "Contact Person Name",
      dataIndex: "contactPersonName",
      key: "contactPersonName",
    },
    { title: "Contact Person Phone", dataIndex: "phone", key: "phone" },
    { title: "Attention", dataIndex: "attention", key: "attention" },
    { title: "Address Line 1", dataIndex: "addressLine1", key: "addressLine1" },
    { title: "Address Line 2", dataIndex: "addressLine2", key: "addressLine2" },
    { title: "Country", dataIndex: "countryName", key: "countryName" },
    { title: "State", dataIndex: "stateName", key: "stateName" },
    { title: "District", dataIndex: "districtName", key: "districtName" },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "Zip Code", dataIndex: "zipCode", key: "zipCode" },
    { title: "Fax Number", dataIndex: "faxNumber", key: "faxNumber" },
    {
      title: "",
      dataIndex: "operation",
      render: (text: any, record: any, recordIndex: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setActiveAddress({
                action: "edit",
                index: recordIndex,
                item: record,
              });
            }}
          >
            <Icon name="edit" />
          </Button>
          <Button
            size="sm"
            color=""
            //  onClick={() => remove(index)}
            onClick={() => remove(recordIndex)}
          >
            <Icon name="trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row className="gy-4  d-flex justify-content-between align-items-center">
        <Col>
          <h5>Address Information</h5>
        </Col>
        <Col className="text-end">
          <Button color="primary" onClick={toggleModal}>
            <Icon name="plus"></Icon> <span>Add</span>
          </Button>
        </Col>
      </Row>

      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}

      <Table
        dataSource={fields}
        columns={columns}
        bordered
        className="mt-4"
        pagination={{ pageSize: 5 }}
      />

      {/* Submit & Navigation Buttons */}
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" onClick={handleSubmit(submitForm)}>
              Submit
            </Button>
          </li>
          <li>
            <Button
              color="secondary"
              onClick={props.prev}
              className="btn-dim btn btn-secondary"
            >
              Previous
            </Button>
          </li>
        </ul>
      </div>

      {/* Address Modal */}
      {/* <AddAddressModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSubmit={addAddress}
        existingAddresses={addressList}
      /> */}
      {activeAddress.action && (
        <AddAddressModal
          existingAddresses={fields}
          modal={!!activeAddress.action}
          closeModal={() => {
            setActiveAddress({
              action: null,
              index: null,
              item: null,
            });
          }}
          append={(data: any) => {
            console.log("data", data);
            // for (let i = 0; i < fields.length; i++) {
            //   let item: any = fields[i];
            // if (
            //   item.materialName == data.add &&
            //   checkDeliveryAddress(
            //     previousFormDate?.deliveryAddressType,
            //     item.deliveryAddressId,
            //     data.deliveryAddressId
            //   ) &&
            //   checkNeedByDate(
            //     previousFormDate?.needByDateType,
            //     item.needByDate,
            //     data.needByDate
            //   )
            // ) {
            //   Swal.fire({
            //     icon: "error",
            //     title: "Error",
            //     text: "Material Already Added",
            //   });
            //   return;
            // }
            // }

            const billingAddress = fields?.find(
              (item: any) => item.addressType === "Billing"
            );
            console.log(billingAddress);

            if (billingAddress && data?.addressType === "Billing") {
              setAlertInfo({
                type: "error",
                message: "Billing Address Already Exists",
                errors: [],
              });
              return;
            }

            append(data);
          }}
          update={(index: any, data: any) => {
            console.log("Update::data", data);
            const ItemsList = [...fields];
            // const _data = ItemsList?.filter(
            //   (item: any, idx) => idx != index
            // )?.find((item: any) => {
            //   if (
            //     item.materialName == data.materialName &&
            //     checkDeliveryAddress(
            //       previousFormDate?.deliveryAddressType,
            //       item.deliveryAddressId,
            //       data.deliveryAddressId
            //     ) &&
            //     checkNeedByDate(
            //       previousFormDate?.needByDateType,
            //       item.needByDate,
            //       data.needByDate
            //     )
            //   )
            //     return true;
            // });

            // if (_data) {
            //   Swal.fire({
            //     icon: "error",
            //     title: "Error",
            //     text: "Material Already Added",
            //   });
            //   return;
            // }
            update(index, data);
          }}
          formData={activeAddress}
        />
      )}
    </>
  );
};

export default AddressInformation;
