import React from "react";
import { Row, Col, Button } from "reactstrap";
import { useFormContext, Controller } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";

const BankAccountDetails: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };

  const fileValidation = {
    required: "Please upload a cheque",
    validate: {
      fileFormat: (files: any) =>
        (files.length &&
          Array.from(files).every((file: any) =>
            /\.(png|jpg|jpeg)$/i.test(file.name)
          )) ||
        "Only PNG,JPG and jpeg files are allowed!",
    },
  };

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          <Col lg="4">
            <FormInput
              name="accountHolderName"
              type="text"
              label="Account Holder Name"
              required={true}
              placeholder="Enter Account Holder Name"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="accountNumber"
              // disabled={action === "edit"}
              type="number"
              label="Account Number"
              required={true}
              placeholder="Enter Account Number"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankName"
              // disabled={action === "edit"}
              label="Bank Name"
              required={true}
              placeholder="Enter Bank Name"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormInput
              name="branch"
              // disabled={action === "edit"}
              label="Branch"
              required={true}
              placeholder="Enter Branch"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="ifscCode"
              // disabled={action === "edit"}
              label="IFSC Code"
              pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
              maxLength={11}
              required={true}
              placeholder="Enter IFSC Code"
            />
          </Col>
          <Col lg="4">
            <div className="form-group">
              <label className="form-label">Upload Cancelled Cheque <span className="mandatory">*</span></label>
              <div className="form-control-wrap">
                <div className="form-file">
                  <Controller
                    control={control}
                    name="files"
                    rules={fileValidation}
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          type="file"
                          required={true}
                          accept="image/jpeg, image/png, image/jpg"
                          className="form-control" // Adding form-control class for proper styling
                          onChange={(e) => field.onChange(e.target.files)}
                          ref={field.ref}
                        />
                        {fieldState.error && (
                          <div
                            className="mandatory"
                            style={{ display: "block" }}
                          >
                            {fieldState.error.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4"></Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default BankAccountDetails;
