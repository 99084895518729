import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import GeneralLedgerApis from "../../../api/master/generalLedger";
import { Divider, Space, Popover } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import sequenceApis from "../../../api/master/sequence";
import ApprovalDrawer from "./ApprovalDrawer";
import misc from "../../../api/misc/dropDowns";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";

interface SelectOptions {
  label: string;
  value: string;
}
interface SequenceType {
  module: string;
  level: string;
  siteIds: string[];
}
const GeneralDetails: FC = (props: any) => {
  const dispatch: any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [billingAddressList, setBillingAddressList] = useState<any>({});

  const AllFormDetails = watch();
  const deliveryAddressId = watch("deliveryAddressId");
  const billingAddressId = watch("billingAddressId");
  const approvalRequired = watch("approvalRequired");
  const siteIdWatch = watch("siteId");
  const invoiceDate = watch("invoiceDate");
  const paymentTermsWatch = watch("paymentTerms");
  const submitForm = (data: any) => {
    props?.next();
  };

  const orgId = watch("orgId");

  useEffect(() => {
    if (orgId) getAddressOptions(orgId);
  }, [orgId]);

  const setFormValues = () => {
    const { customerId } = AllFormDetails;
    if (customerId) {
      getAddressOptions(customerId);
    }
  };

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  useEffect(() => {
    setFormValues();
    getCustomerOptions();
  }, []);

  const getCustomerOptions = async () => {
    try {
      const res = await misc.fetchCustomersByOrgId();
      const { status, data } = res || {};
      if (status) {
        setCustomerOptions(
          data?.map((customer: any) => ({
            label: customer?.customerName,
            value: customer?.customerId,
            orgId: customer?.customerOrgId,
          }))
        );
      }
    } catch (error) {}
  };

  const getAddressOptions = async (id: any) => {
    try {
      const res = await misc.fetchCustomerAddressesById(id);
      const { status, data } = res || {};
      if (status) {
        console.log("data", data);
        setDeliveryAddressList(
          data?.filter((item: any) => item?.addressType == "Shipping")
        );
        let _billingAddress = data?.find(
          (item: any) => item?.addressType == "Billing"
        );
        setBillingAddressList(_billingAddress);
        setValue("billingAddressId", _billingAddress?.id);
        setValue("billingAddress", _billingAddress);
      }
    } catch (error) {}
  };

  const ShowAddressPopOver = ({ id, text, options }: any) => {
    if (!id) {
      return <></>;
    }

    let address = options?.find((item: any) => item.id == id);

    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city},{address?.district?.districtName},{" "}
              {address?.state?.stateName},{address?.country?.countryName} -{" "}
              {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          {!sequenceAccess?.includes("Quotation") ? (
            <Col lg="4">
              <FormInput
                name="rfqId"
                label={`Quotation Number`}
                required={true}
                placeholder="Enter Quotation Number"
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg="4">
            <FormSelect
              name="orgId"
              label={`Customer`}
              options={customerOptions}
              required={true}
              placeholder="Select Customer"
              onChange={(e: any) => {
                let orgName = customerOptions?.find(
                  (item: any) => item.value == e
                );
                setValue("orgName", orgName?.label);
                setValue("customerOrgId", orgName?.orgId);
              }}
            />
          </Col>
          <Col lg="8">
            <FormInput
              name="quotationDescription"
              label="RFQ Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={200}
              placeholder="Enter Description"
              tooltipText="Enter a brief description of the quotation."
            />
          </Col>

          <Col lg="4">
            <FormInput
              name="preferredBrands"
              label={`Make`}
              required={true}
              placeholder="Enter Preferred Brands"
            />
          </Col>
          {/* <Col lg="4">
            <FormInput
              name="irn"
              label={`IRN`}
              required={true}
              placeholder="Enter IRN"
            />
          </Col> */}

          <Col lg="4">
            <FormDatePicker
              name="validityDate"
              label="Quotation Validity Date"
              required={true}
              minDate={new Date()}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="negotiable"
              label={`Negotiable`}
              options={[
                {
                  label: "Yes",
                  value: "Y",
                },
                {
                  label: "No",
                  value: "N",
                },
              ]}
              required={true}
              placeholder="-Select-"
            />
          </Col>
          {/* <Col lg="4">
            <FormDatePicker
              name="dueDate"
              disabled={!invoiceDate}
              minDate={invoiceDate}
              label="Due Date"
              required={true}
            />
          </Col> */}

          <Col lg="4">
            <FormSelect
              disabled={!orgId}
              name="deliveryAddressId"
              label={`Delivery Address`}
              options={deliveryAddressList?.map((item: any) => ({
                label: item?.deliveryLocationName,
                value: item?.id,
              }))}
              onChange={(e: any) => {
                let address = deliveryAddressList?.find(
                  (item: any) => item.id == e
                );
                setValue("deliveryAddress", address);
              }}
              popOverOptions={
                <ShowAddressPopOver
                  id={deliveryAddressId}
                  matchKey="deliveryAddress"
                  options={deliveryAddressList}
                  text="View Selected Delivery Address"
                />
              }
              required={true}
              placeholder="Select Delivery Address"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              disabled
              name="billingAddressId"
              label={`Billing Address`}
              options={[
                {
                  label: billingAddressList?.deliveryLocationName,
                  value: billingAddressList?.id,
                },
              ]}
              popOverOptions={
                <ShowAddressPopOver
                  id={billingAddressId}
                  matchKey="billingAddress"
                  options={[billingAddressList]}
                  text="View Billing Address"
                />
              }
              required={true}
              placeholder="Select Billing Address"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="paymentTerms"
              label="Payment Terms"
              options={[
                { label: "Cash & Carry", value: "Cash and Carry" },
                // { label: "Advance", value: "Advance" },
                { label: "Credit", value: "Credit" },
              ]}
              required={true}
              placeholder="Select Payment Terms"
              tooltipText="Select the payment terms for the purchase order."
            />
          </Col>
          {paymentTermsWatch == "Credit" && (
            <Col lg="4">
              <FormInput
                name="paymentDays"
                type="number"
                label="Credit Days"
                required={true}
                placeholder="Enter Credit Days"
              />
            </Col>
          )}
          {/* <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Approval Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              placeholder="Select"
              required={true}
            />
            <div style={{ display: "none" }}>
              <FormInput name="approvalsData" label="" type="text" />
            </div>
            {approvalRequired == "true" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Col> */}
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default GeneralDetails;
