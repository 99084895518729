import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Alert } from "reactstrap";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Controller, FormProvider, useForm } from "react-hook-form";
import FormSelect from "../../form-components/SelectComponent";
import Swal from "sweetalert2";
import rolesApi from "../../../api/master/roles";
import FormInput from "../../form-components/InputComponent";
import FormTextArea from "../../form-components/TextAreaComponent";
import { Col } from "reactstrap";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
interface SelectOptions {
  label: string;
  value: string;
}
const EditModal: FC<any> = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  alertInfo,
}) => {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = methods;
  const [roleOptions, setRoleOptions] = useState<SelectOptions[]>([]);
  const fetchRoles = async () => {
    try {
      const { data, status, message } = await rolesApi.fetchRolesData();
      if (status) {
        setRoleOptions(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);
  useEffect(() => {
    console.log("formData", formData);
    setValue("ledgerName", formData.ledgerName);
    setValue("description", formData.description);
    setValue("referenceID", formData.referenceID);
    setValue(
      "roles",
      formData?.roles?.map((item: any) => item.roleID.toString())
    );
  }, [formData]);
  return (
    <Modal
      isOpen={modal}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Update General Ledger</h5>
          {alertInfo.message && (
            <div className="mb-3 mt-1">
              <Alert
                color={alertInfo.type === "error" ? "danger" : "success"}
                className="alert-icon"
              >
                <strong>{alertInfo.message}</strong>
                <Icon
                  name={
                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                  }
                />
                {alertInfo.errors.length > 0 ? (
                  <ul>
                    {alertInfo.errors.map((err: any, index: any) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <FormProvider {...methods}>
              <Form
                className="row gy-4"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      label="Ledger ID"
                      name="referenceID"
                      type="text"
                      pattern={/^[a-zA-Z0-9./_-]+$/}
                      disabled
                      placeholder="Enter Ledger ID"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      label="Ledger Name"
                      name="ledgerName"
                      type="text"
                      placeholder="Enter Ledger Name"
                    />
                  </div>
                </Col>
                <Col md="4" className={undefined}>
                  <div className="form-group">
                    <FormMultiSelect
                      name="roles"
                      label="Select Role"
                      required={true}
                      options={roleOptions}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <FormTextArea
                      name="description"
                      label="Description"
                      placeholder="Write your description"
                      required={true}
                      maxLength={50}
                    />
                  </div>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" type="submit">
                        Update
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          closeModal();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </FormProvider>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default EditModal;
