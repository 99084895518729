import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Label, Input, Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import customersApis from "../../../api/master/customers";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const LegalInformation: React.FC = (props: any) => {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const files = watch("files");
  const businessType = watch("businessType");
  const [verifyingGstin, setVerifyingGstin] = useState(false);
  const FullFormDetails = watch();
  const gstinVerified = watch("gstinVerified");
  const gstin = watch("gstin");
  const cin = watch("cin");
  const submitForm = (data: any) => {
    props.next();
  };

  useEffect(() => {
    if (!gstin) return;
    if (
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
        gstin
      ) ||
      (/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/.test(cin) &&
        gstinVerified != "true")
    ) {
      setValue("gstinVerified", "false");
      setVerifyingGstin(true);
      validateSandbox({
        cin: cin,
        gstin: gstin,
        orgName: FullFormDetails.orgName,
      });
    } else {
      setVerifyingGstin(false);
    }
  }, [gstin, cin]);

  const validateSandbox = async (data: any) => {
    try {
      const response = await customersApis.verifyLegalDetails({
        cin: data.cin,
        gstin: data.gstin,
        orgName: data.orgName,
      });

      const sandboxResponse = response?.verificationResponse;
      const errorsArray: string[] = [];

      // Check if any GSTIN verification failed
      const gstinVerification = sandboxResponse.find(
        (obj: any) => obj.gstin && !obj.verified
      );
      if (gstinVerification) {
        // Show alert if GSTIN verification is false
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "GSTIN verification failed.",
        });
        setVerifyingGstin(false);
        return { errors: ["GSTIN verification failed."] };
      }

      // If verification passed, return the companyAddress
      const sandBoxObj = sandboxResponse.find(
        (obj: any) => obj.gstin && obj.verified
      );

      const { companyAddress, dateOfRegistration } = sandBoxObj;

      if (!gstinVerification) {
        if (companyAddress) {
          setValue("comAddressline1", companyAddress?.bno);
          setValue("comAddressLine2", companyAddress?.landMark);
          setValue("comCountry", 1);
          //   await fetchStatesByCountry(
          //     "1",
          //     companyAddress?.stcd,
          //     companyAddress?.dst.toLowerCase().replace(/\s+/g, "")
          //   );

          setValue(
            "area",
            companyAddress?.locality + ", " + companyAddress?.landMark
          );

          setValue("comCity", companyAddress?.loc);
          setValue("comPincode", companyAddress?.pncd);
        }
        setValue("pan", data.gstin.substring(2, 12)); // Extract substring instead of using splice
        setValue("gstinVerified", "true");
      }
      setValue("incorporationDate", dayjs(dateOfRegistration, "DD/MM/YYYY"));
      setVerifyingGstin(false);
      return { errors: errorsArray, companyAddress };
    } catch (error: any) {
      setVerifyingGstin(false);
      console.log(error.message);
      throw error;
    }
  };
  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <div className="col-md-4">
            <FormSelect
              name="businessType"
              label="GST Treatment"
              required={true}
              options={[
                { value: "registered", label: "Registered Bussiness" },
                {
                  value: "unregistered",
                  label: "Unregistered Bussiness",
                },
              ]}
            />
          </div>
          {businessType === "registered" && (
            <>
              <div className="col-md-4">
                <FormInput
                  name="gstin"
                  label="GSTIN"
                  required={true}
                  type="text"
                  placeholder="Enter GSTIN"
                  pattern={
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                  }
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="pan"
                  label="PAN"
                  disabled
                  required={true}
                  type="text"
                  placeholder="Enter PAN"
                  pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="cin"
                  label="CIN / LLPIN"
                  // disabled={gstinVerified == "true"}
                  // required={true}
                  type="text"
                  placeholder="Enter CIN / LLPIN"
                  pattern={/^.*$/}
                  // pattern={/^[a-zA-Z0-9]+$/}
                  // pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
                />
              </div>
            </>
          )}
        </Row>
        <Row className="gy-4 mt-1">
          <h5>Bank Information</h5>
          <div className="col-md-4">
            <FormInput
              name="accountName"
              label="Account Holder Name"
              required={true}
              type="text"
              placeholder="Enter Account Name"
              // minLength={10}
              // maxLength={20}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="bankaccountNumber"
              label="Account Number"
              required={true}
              type="text"
              pattern={/^\d+$/} // Allows only digits
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
              }}
              placeholder="Bank Account Number"
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="bankName"
              label="Bank Name"
              required={true}
              type="text"
              placeholder="Enter Bank Name"
              // minLength={5}
              // maxLength={30}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="branchName"
              label="Branch Name"
              required={true}
              type="text"
              placeholder="Enter Branch Name"
              // minLength={3}
              // maxLength={30}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="ifcCode"
              label="IFSC Code"
              required={true}
              type="text"
              placeholder="Enter IFSC Code"
              pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
              maxLength={11}
            />
          </div>
          <div className="col-md-4">
            {files && files[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"file"}>
                    Upload Cancelled Cheque <span className="mandatory">*</span>
                  </label>
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{files[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("files", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${files && files[0]?.name ? "none" : "block"}`,
              }}
            >
              <FormInput
                name="files"
                label="Upload Cancelled Cheque"
                type="file"
                required={files && files[0]?.name ? false : true}
                placeholder="Upload Cancelled Cheque"
              />
            </div>
          </div>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default LegalInformation;
