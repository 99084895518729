import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Invest from "../pages/Invest";

import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
import InvoicePrint from "../pages/pre-built/invoice/InvoicePrint";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

//Procurex Pages

import AddNewPurchaseRequest from "../pages/purchase-requests/AddNewPurchaseRequest";
import ViewPurchaseRequests from "../pages/purchase-requests/ViewPurchaseRequests";
import PurchaseRequestAprrovals from "../pages/purchase-requests/PurchaseRequestApprovals";
import PurchaseRequestAnalytics from "../pages/purchase-requests/PurchaseRequestAnalytics";
import TrackPurchaseRequests from "../pages/purchase-requests/TrackPurchaseRequests";

import AddNewBudget from "../pages/budget/AddNewBudget";
import ViewBudget from "../pages/budget/ViewBudget";
import TrackBudget from "../pages/budget/TraclBudget";
import BudgetApprovals from "../pages/budget/BudgetApprovals";
import BudgetAnalytics from "../pages/budget/BudgetAnalytics";

import RFQAnalytics from "../pages/quotations/RFQAnalytics";
import RFQApprovals from "../pages/quotations/RFQApprovals";
import RFQComparison from "../pages/quotations/RFQComparison";
import RFQConversion from "../pages/quotations/RFQConversion";
import RFQSupplierResponses from "../pages/quotations/RFQSupplierResponses";
import RFQView from "../pages/quotations/RFQView";
import RFQTracking from "../pages/quotations/RFQTracking";
import SitesSetup from "../pages/masters/sites/SitesSetup";
import AddSites from "../pages/masters/sites/CreateSites";
import SitesViewDetails from "../pages/masters/sites/SitesViewDetails";
import Departments from "../pages/masters/departments/Departments";
import Roles from "../pages/masters/roles/Roles";
import ReportingHeirarchy from "../pages/masters/roles/ReportingHeirarchy";
import SupplierCategory from "../pages/masters/supplier/SupplierCategory";
import Suppliers from "../pages/masters/supplier/Suppliers";
import Contractors from "../pages/masters/contractors/Contractors";
import ViewSuppliersDetails from "../pages/masters/supplier/ViewSuppliersDetails";
import AddContractors from "../pages/masters/contractors/AddContractors";
import ViewContractorDetails from "../pages/masters/contractors/ViewContractorDetails";
import ApprovalHeirarchy from "../pages/masters/contractors/ApprovalHeirarchy";
import MaterialCategory from "../pages/masters/materials/MaterialCategory";
import Materials from "../pages/masters/materials/Material";

import CostCenter from "../pages/masters/cost-center/CostCenter";
import GeneralLedger from "../pages/masters/general-ledger/GeneralLedger";
import Consultants from "../pages/masters/consultants/Consultants";
import Users from "../pages/masters/Users/Users";
import CreateUsers from "../pages/masters/Users/CreateUsers";
import AddSupplier from "../pages/masters/supplier/AddSupplier";
import AddConsultants from "../pages/masters/consultants/AddConsultants";
import AddMaterial from "../pages/masters/materials/AddMaterial";
import ViewMaterialDetails from "../pages/masters/materials/ViewMaterialDetails";
import ViewConsultantDetails from "../pages/masters/consultants/ViewConsultantDetails";
import CreateRole from "../pages/masters/roles/CreateRole";
import UserViewDetails from "../pages/masters/Users/ViewUser";
import RoleViewDetails from "../pages/masters/roles/ViewRole";
import ViewOrgPage from "../pages/masters/orgmaster/ViewOrg";
import SetPassword from "../pages/auth/SetPassword";
import SuccessMain from "../pages/auth/SuccessMain";
import EmailError from "../pages/auth/EmailError";
import ResetPassword from "../pages/auth/ResetPassword";
import OrgLegalInformation from "../pages/masters/orgmaster/OrgLegalInformation";
import OrgContactInformation from "../pages/masters/orgmaster/orgContactInformation";
import OrgBankInformation from "../pages/masters/orgmaster/OrgBankInformation";

// import PurchaseOrderMain from "../pages/purchase-orders/PurchaseOrderMain";
import DeliveryAddress from "../pages/masters/delivery-address/DeliveryAddress";
import PurchaseOrderAprrovals from "../pages/purchase-orders/PurchaseOrderApprovals";
import ViewPurchaseOrdersList from "../pages/purchase-orders/ViewPurchaseOrder";
import TrackPurchaseOrder from "../pages/purchase-orders/TrackPurchaseOrder";
import PurchaseOrderAnalytics from "../pages/purchase-orders/PurchaseOrderAnalytics";
import AddInvoice from "../pages/invoice/AddInvoice";
import InvoiceApproval from "../pages/invoice/InvoiceApproval";
import InvoiceAnalytics from "../pages/invoice/InvoiceAnalytics";
import PoInvoiceDetails from "../pages/invoice/ViewOrderDetails";
import ViewMaterialInvoices from "../pages/invoice/ViewMaterialInvoices";
import ViewInvoiceDetails from "../pages/invoice/ViewInvoiceDetails";
import ViewInvoiceList from "../pages/invoice/ViewAllInvoices";
import RFQViewDetails from "../pages/quotations/ViewRFQDetails";
import QuotedList from "../pages/quotations/QuotedList";
import EditQuotedView from "../pages/quotations/EditQuotedView";
import InvoiceToDispatchCreation from "../pages/dispatch/invoiceToDispatchCreation";
import ViewDispatchInvoiceList from "../pages/dispatch/dispatchableInvoices";
import ViewAllDispatch from "../pages/dispatch/ViewAllDispatch";
import ShowDispatchDetailsWrapper from "../pages/dispatch/showDispatchDetailsWrapper";
import ViewMaterialDispatch from "../pages/dispatch/ViewMaterialDispatch";
import ViewPayments from "../pages/payments/viewPayments";
import RunPayments from "../pages/payments/runPayments";
import Main from "../pages/credit-debit-note/Main";
import ViewAllCN from "../pages/credit-debit-note/ViewAllCN";
import ViewPendingApprovalCN from "../pages/credit-debit-note/ViewPendingApprovalCN";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from "../store/slices/misc/currentUser";
import { updateAccessibility } from "../store/slices/misc/accessibility";
import { updateMenuData } from "../store/slices/misc/menuData";
import { updateAllowedRoutes } from "../store/slices/misc/allowedRoutes";
import { Flex, Spin } from "antd";
import SequenceList from "../pages/masters/sequence/SequenceList";
import Cookies from "js-cookie";
import VieweInvoiceList from "../pages/eInvoice/ViewAlleInvoices";
import GenerateEInvoice from "../pages/eInvoice/ViewInvoiceDetails";
import SalesByCustomerReport from "../pages/reports/sales-report/salesByCustomerReport";
import ViewAllReportsList from "../pages/reports/view-all-reports";
import InvoicesForSalesByCustomerReport from "../pages/reports/sales-report/invoicesForSalesByCustomerReport";
import SalesByMaterialReport from "../pages/reports/sales-report/salesByMaterialReport";
import InvoicesForSalesByMaterialReport from "../pages/reports/sales-report/invoicesForSalesByMaterialReport";
import SalesInvoiceDetailsReport from "../pages/reports/sales-report/salesInvoiceDetailsReport";
import InvoiceForCustomerReport from "../pages/reports/sales-report/invoiceForCustomerReport";
import PurchaseOrderPreview from "../pages/reports/sales-report/purchaseOrderPreview";
import InvoicePreviewReport from "../pages/reports/sales-report/invoicePreviewReport";
import GRNPreview from "../pages/reports/sales-report/grnPreview";
import PaymentsAgingSummaryReport from "../pages/reports/receivables-reports/paymentsAgingSummaryReport";
import PaymentsAgingSummaryInvoiceReport from "../pages/reports/receivables-reports/paymentsAgingSummaryInvoiceReport";
import CustomerBalancesReport from "../pages/reports/receivables-reports/customerBalancesReport";
import CustomerBalancesDetailReport from "../pages/reports/receivables-reports/customerBalancesDetailReport";
import DeliveryNoteDetailsReport from "../pages/reports/receivables-reports/deliveryNoteDetailsReport";
import PaymentsReceivedByCustomerReport from "../pages/reports/receivables-reports/paymentsReceivedByCustomerReport";
import PaymentsReceivedByCustomerDetailReport from "../pages/reports/receivables-reports/paymentsReceivedByCustomerDetailReport";
import AwardFromCustomerReport from "../pages/reports/sales-report/awardFromCustomerReport";
import SalesByCustomerDetailsReport from "../pages/reports/sales-report/salesByCustomerDetailsReport";
import ExcelExportRequestedList from "../pages/pre-built/user-manage/ExcelExportRequestedList";
import NotificationMain from "../pages/notifications/NotificationMain";
import InvoicePayables from "../pages/payments/invoicePayables";
import BulkUploadList from "../pages/pre-built/user-manage/ExcelUploadRequestedList";
import SelfInvoiceCreation from "../pages/invoice/selfInvoice/selfInvoiceMain";
import SelfQuotationCreation from "../pages/quotations/selfQuotation/selfQuotationMain";
import Customers from "../pages/masters/customers/Customers";
import AddCustomers from "../pages/masters/customers/AddCustomers";
import ViewCustomerDetails from "../pages/masters/customers/ViewCustomerDetails";
const globalRoutes = [
  "/",
  "/auth-reset",
  "/auth-success",
  "/auth-register",
  "/setPassword",
  "/success",
  "/errorEmail",
  "/privacy",
];
const routesMap = {
  dashboard: <Invest />,
  organisation: <ViewOrgPage />,
  orgLegalInformation: <OrgLegalInformation />,
  orgContactInformation: <OrgContactInformation />,
  orgBankInformation: <OrgBankInformation />,
  sequence: <SequenceList />,
  sites: <SitesSetup />,
  createSites: <AddSites />,
  "delivery-address": <DeliveryAddress />,
  "sitesViewDetails/:siteId": <SitesViewDetails />,
  departments: <Departments />,
  roles: <Roles />,
  "ViewRoleDetails/:roleID": <RoleViewDetails />,
  "reporting-heirarchy": <ReportingHeirarchy />,
  "supplier-category": <SupplierCategory />,
  suppliers: <Suppliers />,
  addSuppliers: <AddSupplier />,
  "vewSuppliersDetails/:siteId": <ViewSuppliersDetails />,
  "general-contractors": <Contractors />,
  "viewContractorDetails/:siteId": <ViewContractorDetails />,
  addContractors: <AddContractors />,
  addConsultants: <AddConsultants />,
  "viewConsultantDetails/:consultantId": <ViewConsultantDetails />,
  addMaterials: <AddMaterial />,
  "vewMaterialDetails/:materialId": <ViewMaterialDetails />,
  consultants: <Consultants />,
  users: <Users />,
  createUsers: <CreateUsers />,
  "userProfile/:userId": <UserViewDetails />,
  createRole: <CreateRole />,
  "approval-heirarchy": <ApprovalHeirarchy />,
  "materail-category": <MaterialCategory />,
  materails: <Materials />,
  customers: <Customers />,
  "view-customer-details/:id": <ViewCustomerDetails />,
  addCustomer: <AddCustomers />,
  "cost-center": <CostCenter />,
  "general-ledger": <GeneralLedger />,
  "add-new-purchase-request": <AddNewPurchaseRequest />,
  "approvals-purchase-request": <PurchaseRequestAprrovals />,
  "view-purchase-requests": <ViewPurchaseRequests />,
  "track-purchase-requests": <TrackPurchaseRequests />,
  "analytics-purchase-requests": <PurchaseRequestAnalytics />,
  "add-budget": <AddNewBudget />,
  "budget-approvals": <BudgetApprovals />,
  "view-budget": <ViewBudget />,
  "track-budget": <TrackBudget />,
  "analytics-budget": <BudgetAnalytics />,
  "rfq-approvals": <RFQApprovals />,
  "view-rfqs": <RFQView />,
  "view-rfqs-details": <RFQViewDetails />,
  "track-rfqs": <RFQTracking />,
  "analytics-rfq": <RFQAnalytics />,
  "rfq-comparison": <RFQComparison />,
  "rfq-conversion": <RFQConversion />,
  quoted_view: <QuotedList />,
  "view-quoted-details/:qid": <EditQuotedView />,
  "rfq-supplier-responses": <RFQSupplierResponses />,
  "approvals-purchase-order": <PurchaseOrderAprrovals />,
  "view-purchase-order": <ViewPurchaseOrdersList />,
  "view-purchase-order/:orderNumber": <PurchaseOrderPreview />,
  // "view-purchase-order/:orderNumber": <PurchaseOrderViewWrapper/>,
  "track-purchase-order": <TrackPurchaseOrder />,
  "analytics-purchase-order": <PurchaseOrderAnalytics />,
  "add-purchase-invoice": <AddInvoice />,
  "add-purchase-invoice/:orderNumber": <PoInvoiceDetails />,
  "view-invoice-list": <ViewInvoiceList />,
  "view-invoice/:id": <ViewInvoiceDetails />,
  "view-material-invoices/:orderNumber/:lineNo": <ViewMaterialInvoices />,
  "approvals-purchase-invoice": <InvoiceApproval />,

  "view-e-invoice-list": <VieweInvoiceList />,
  "view-e-invoiced-list": <VieweInvoiceList />,
  "view-e-invoice-cancelled-list": <VieweInvoiceList />,
  "view-e-invoice-expired-list": <VieweInvoiceList />,
  "view-e-invoice-excluded-list": <VieweInvoiceList />,

  "generate-e-invoice/:id": <GenerateEInvoice />,

  "analytics-purchase-invoice": <InvoiceAnalytics />,
  "view-dispatch-invoice-list": <ViewDispatchInvoiceList />,
  "post-dispatch-invoice/:invoiceNumber": <InvoiceToDispatchCreation />,
  "view-material-dispatch/:invoiceNumber/:invoiceLineNo": (
    <ViewMaterialDispatch />
  ),
  "view-dispatch-list": <ViewAllDispatch />,
  "view-dispatch-details/:id": <ShowDispatchDetailsWrapper />,
  "credit-debit-note-new": <Main />,
  "all-credit-debit-notes": <ViewAllCN />,
  "pending-approval-credit-debit-notes": <ViewPendingApprovalCN />,
  "invoice-payables": <InvoicePayables />,
  "view-payments": <ViewPayments />,
  "pending-payment-approvals": <RunPayments />,
  "kyc-list-regular": <KycListRegular />,
  "kyc-details-regular/:kycId": <KycDetailsRegular />,
  "transaction-basic": <TransListBasic />,
  "transaction-crypto": <TransListCrypto />,
  "terms-policy": <Terms />,

  faq: <Faq />,
  privacy: <Faq />,

  // "pages/regular-v1": <Regularv1 />,
  // "pages/regular-v2": <Regularv2 />,

  "view-all-sales-reports": <ViewAllReportsList />,
  "view-all-receivables-reports": <ViewAllReportsList />,
  "sales-by-customer-report": <SalesByCustomerReport />,
  "award-from-customer-report": <AwardFromCustomerReport />,
  "invoices-for-sales-by-customer-report/:id": (
    <InvoicesForSalesByCustomerReport />
  ),
  "sales-by-customer-details-report/:id": <SalesByCustomerDetailsReport />,
  "sales-by-material-report": <SalesByMaterialReport />,
  "invoices-for-sales-by-material-report/:id": (
    <InvoicesForSalesByMaterialReport />
  ),
  "sales-invoice-details-report": <SalesInvoiceDetailsReport />,
  "invoice-for-customer-report": <InvoiceForCustomerReport />,
  "purchase-order-report-preview/:orderNumber": <PurchaseOrderPreview />,
  "invoice-preview-report/:id": <InvoicePreviewReport />,
  "grn-preview-report/:id": <GRNPreview />,

  "customer-balances-report": <CustomerBalancesReport />,
  "customer-balances-detail-report/:id": <CustomerBalancesDetailReport />,
  "payment-aging-summary-reports-list": <PaymentsAgingSummaryReport />,
  "payments-aging-summary-invoice-report/:id": (
    <PaymentsAgingSummaryInvoiceReport />
  ),

  "delivery-note-details-report": <DeliveryNoteDetailsReport />,
  "payments-received-by-customer-report": <PaymentsReceivedByCustomerReport />,
  "payments-received-by-customer-detail-report/:id": (
    <PaymentsReceivedByCustomerDetailReport />
  ),
  "self-invoice": <SelfInvoiceCreation />,
  "self-quotation": <SelfQuotationCreation />,
};

const menu = [
  {
    text: "Dashboard",
    link: "dashboard",
  },
  {
    text: "Masters",
    subMenu: [
      {
        text: "Organisation",
        link: "organisation",
      },
      {
        text: "Sequence Settings",
        link: "sequence",
      },
      {
        text: "Manufacturing Locations",
        link: "sites",
      },
      {
        text: "Departments",
        link: "departments",
      },
      {
        text: "Roles",
        active: false,
        subMenu: [
          {
            text: "Roles",
            link: "roles",
          },
          {
            text: "Organisation Structure",
            link: "reporting-heirarchy",
          },
        ],
      },
      {
        text: "Material Category",
        link: "supplier-category",
      },
      {
        text: "Materials",
        link: "materails",
      },
      {
        text: "Customers",
        link: "customers",
      },
      {
        text: "Users",
        link: "users",
      },

      {
        text: "Approval Heirarchy",
        link: "approval-heirarchy",
      },

      {
        text: "Cost Center",
        link: "cost-center",
      },
      {
        text: "General Ledger",
        link: "general-ledger",
      },
    ],
  },
  {
    text: "Sales",
    subMenu: [
      // {
      //   text: "Budget",
      //   link: "add-budget",
      //   alt: ["view-budget"],
      // },
      // {
      //   text: "Purchase Request",
      //   link: "add-new-purchase-request",
      //   alt: ["view-purchase-request"],
      // },
      {
        text: "Quotations",
        link: "view-rfqs",
      },
      // {
      //   text: "Forward Auction",
      //   link: "forward-auction-new",
      // },
      // {
      //   text: "Reverse Auction",
      //   link: "reverse-auction-new",
      // },
      {
        text: "Sales Order",
        link: "approvals-purchase-order",
      },
      {
        text: "Invoices",
        link: "view-invoice-list",
      },
      {
        text: "Dispatch",
        link: "view-dispatch-list",
      },
      {
        text: "Credit/Debit Note",
        link: "all-credit-debit-notes",
      },
      {
        text: "Payment Receipts",
        link: "view-payments",
      },
    ],
  },
  {
    text: "E-Way Bill",
    link: "view-e-invoice-list",
  },
  {
    text: "Reports",
    link: "view-all-sales-reports",
  },
];

const Pages = ({ setIsLoggedIn }) => {
  const { data: currentUser, isLoading } = useSelector(
    (state) => state.currentUser
  );
  const [accessibility, setAccessibility] = useState([]);
  const [restrictedRoutes, setRestrictedRoutes] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("access-token");
    if (
      !currentUser &&
      isAuthenticated &&
      !globalRoutes?.includes(window.location.pathname)
    ) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.userRoutePermissions) {
      try {
        const { userRoutePermissions } = currentUser;
        const decodedString = atob(userRoutePermissions);
        const decodedData = JSON.parse(decodedString);
        console.log("decodedData", decodedData);
        dispatch(updateAccessibility(decodedData));
        const _allowedRoutes = [];
        const _restrictedRoutes = [];
        Object.keys(decodedData).forEach((mod) => {
          const module = decodedData[mod];
          if (module?.access) {
            module?.routes?.forEach((route) => {
              if (route?.permission) {
                _allowedRoutes.push(route?.path);
              } else {
                _restrictedRoutes.push(route?.path);
              }
            });
          } else {
            module?.routes?.forEach((route) => {
              _restrictedRoutes.push(route?.path);
            });
          }
        });
        setAccessibility(_allowedRoutes);
        setRestrictedRoutes(_restrictedRoutes);
        const _menu = [];
        menu?.forEach((item) => {
          if (item?.subMenu?.length > 0) {
            const subMenu = [];
            item?.subMenu?.forEach((subItem) => {
              if (subItem?.subMenu?.length > 0) {
                const _subMenuChildren = [];
                subItem?.subMenu?.forEach((subSubItem) => {
                  if (_allowedRoutes.includes(subSubItem?.link)) {
                    _subMenuChildren.push({
                      text: subSubItem?.text,
                      link: subSubItem?.link,
                    });
                  }
                });
                if (_subMenuChildren.length > 0) {
                  subMenu.push({
                    text: subItem?.text,
                    active: false,
                    subMenu: _subMenuChildren,
                  });
                }
                return;
              }
              if (_allowedRoutes.includes(subItem?.link)) {
                subMenu.push({
                  text: subItem?.text,
                  link: subItem?.link,
                });
              } else {
                if (subItem?.alt?.length > 0) {
                  const altLink = subItem?.alt?.find((link) =>
                    _allowedRoutes.includes(link)
                  );
                  if (altLink) {
                    subMenu.push({
                      text: subItem?.text,
                      link: altLink,
                    });
                  }
                }
              }
            });
            if (subMenu.length > 0) {
              _menu.push({
                text: item?.text,
                subMenu: subMenu,
              });
            }
          } else {
            if (_allowedRoutes.includes(item?.link)) {
              _menu.push({
                text: item?.text,
                link: item?.link,
              });
            }
          }
        });
        dispatch(updateMenuData(_menu));
        dispatch(updateAllowedRoutes(_allowedRoutes));
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("access-token");
    if (!isAuthenticated && !globalRoutes?.includes(window.location.pathname)) {
      window.location.href = "/";
    }
  }, []);

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
        {accessibility?.map((path) => (
          <Route path={path} key={path} element={routesMap[path]} />
        ))}
        {restrictedRoutes?.map((path) => (
          <Route
            path={path}
            key={path}
            element={<div> Unauthorized Access</div>}
          />
        ))}
        <Route path="privacy" element={<Faq />}></Route>
        <Route path="notifications" element={<NotificationMain />} />

        {/* <Route path="view-all-sales-reports" element={<ViewAllReportsList/>}/>
        <Route path="view-all-receivables-reports" element={<ViewAllReportsList/>}></Route>
        <Route path="sales-by-customer-report" element={<SalesByCustomerReport/>}/>
        <Route path="invoices-for-sales-by-customer-report/:id" element={<InvoicesForSalesByCustomerReport/>}/>
        <Route path="sales-by-material-report" element={<SalesByMaterialReport/>}/>
        <Route path="invoices-for-sales-by-material-report/:id" element={<InvoicesForSalesByMaterialReport/>}/>
        <Route path="sales-invoice-details-report" element={<SalesInvoiceDetailsReport/>}/>
        <Route path="invoice-for-customer-report" element={<InvoiceForCustomerReport/>}/>
        <Route path="purchase-order-report-preview/:orderNumber" element={<PurchaseOrderPreview />}></Route>
        <Route path="invoice-preview-report/:id" element={<InvoicePreviewReport />}></Route>
        <Route path="grn-preview-report/:id" element={<GRNPreview />}></Route>

        <Route path="customer-balances-report" element={<CustomerBalancesReport/>}></Route>
        <Route path="customer-balances-detail-report/:id" element={<CustomerBalancesDetailReport/>}></Route>
        <Route path="payment-aging-summary-reports-list" element={<PaymentsAgingSummaryReport />} ></Route>
        <Route path="payments-aging-summary-invoice-report/:id" element={<PaymentsAgingSummaryInvoiceReport />}/> 
        <Route path="delivery-note-details-report" element={<DeliveryNoteDetailsReport/>}></Route>
        <Route path="payments-received-by-customer-report" element={<PaymentsReceivedByCustomerReport/>}></Route>
        <Route path="payments-received-by-customer-detail-report/:id" element={<PaymentsReceivedByCustomerDetailReport/>}></Route> */}

        <Route
          path="user-profile-regular"
          element={<UserProfileRegular />}
        ></Route>
        <Route
          path="user-profile-notification"
          element={<UserProfileNotification />}
        ></Route>
        <Route
          path="user-profile-activity"
          element={<UserProfileActivity />}
        ></Route>
        <Route
          path="excelexport-requested-list"
          element={<ExcelExportRequestedList />}
        ></Route>
        <Route path="bulk-upload-list" element={<BulkUploadList />}></Route>
        <Route
          path="user-profile-setting"
          element={<UserProfileSetting />}
        ></Route>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ResetPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route index element={<Login setIsLoggedIn={setIsLoggedIn} />}></Route>
        <Route path="setPassword" element={<SetPassword />}></Route>
        <Route path="success" element={<SuccessMain />}></Route>
        <Route path="errorEmail" element={<EmailError />}></Route>

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route
          path="*"
          element={
            accessibility.length === 0 ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <Error404Modern />
            )
          }
        ></Route>

        <Route
          path="invoice-print/:invoiceId"
          element={<InvoicePrint />}
        ></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
