import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Badge } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Tooltip,
  Drawer,
  Modal,
  Form,
  Popover,
  Timeline,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import {
  SearchOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import DispatchAside from "./paymentsNav";
import paymentApis from "../../api/payments/payments";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import usersApis from "../../api/master/users";
import invoiceApis from "../../api/Invoice/invoice";
import { render } from "@testing-library/react";
import { title } from "process";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const ViewPayments: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      // fixed: "left",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },

    {
      title: "Customer",
      dataIndex: "orgName",
      key: "orgName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Gross Amount",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 200,
      render: (text: any, record: any) => {
        return (
          <p className="justify-end">{formatCurrency(Number(text), "INR")}</p>
        );
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: 200,
      render: (text: any, record: any) => {
        return (
          <p className="justify-end">
            {formatCurrency(Number(text), "INR")}
            {record?.paymentStatus == "PartiallyPaid" && (
              <Tooltip
                title={
                  " Outstanding Amount: " +
                  formatCurrency(
                    Number(record?.grossTotal) - Number(text),
                    "INR"
                  )
                }
              >
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            )}
          </p>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      width: 100,
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 130,
      fixed: "right",
      render: (text: any, record: any) => {
        const paymentStatus: any = {
          FullyPaid: "FULLY PAID",
          PartiallyPaid: "PARTIALLY PAID",
        };
        if (text == "Pending") {
          const { days } = record?.due;
          if (days <= 0) {
            return <p className="text-danger">Outstanding</p>;
          }
          return <p className="text-warning">Over Due</p>;
        }
        return (
          <p style={{ color: "green", fontSize: "14px" }} className="tb-status">
            {paymentStatus[text] || text}
          </p>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 70,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewDrawer(true);
              fetchPaymentDetails(record?.invoiceNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  const fetchPaymentDetails = async (invoiceNumber: any) => {
    try {
      const res = await paymentApis.fetchAllInvoicePaymentLines(invoiceNumber);
      console.log(res);

      const { data, status, message } = res;
      if (status) {
        setDrawerData(data);

        setApproverIds(
          data?.paymentApprovals
            ?.filter((item: any) => item?.status == "Pending")
            ?.map((item: any) => item?.userId)
        );
      } else {
        setViewDrawer(false);
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const drawerColumns: any = [
    {
      title: "Receipt #",
      key: "paymentId",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => (
        <div className="align-left">
          <span>{record?.payment?.paymentId}</span>
        </div>
      ),
    },
    {
      title: "Receipt Date",
      key: "paymentDate",
      width: 100,
      render: (date: any, record: any) => (
        <span>{FormatDate(record?.payment?.paymentDate)}</span>
      ),
    },
    {
      title: "Payment Mode",
      key: "paymentType",
      width: 120,
      render: (text: any, record: any) => (
        <span>{record?.payment?.paymentMode}</span>
      ),
    },
    {
      title: "Transaction #",
      key: "transactionNumber",
      width: 120,
      render: (text: any, record: any) => {
        const number = record?.payment?.transactionNumber;
        return number ? (
          <span>
            <Tooltip title={number}>{number?.substring(0, 8)}...</Tooltip>
          </span>
        ) : (
          <span>N/A</span>
        );
      },
    },
    {
      title: "Amount Received",
      key: "payableAmount",
      width: 140,
      render: (_text: any, record: any) => {
        const text = record?.payment?.payableAmount;
        return (
          <span>
            {formatCurrency(Number(text), "INR")}
            {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
          </span>
        );
      },
    },
    // {
    //   title: "Approvals",
    //   key:"Approvals",
    //   width: 100,
    //   render: (text: any, record: any) => {
    //     const paymentApprovals = record?.payment?.paymentApprovals;
    //     return (
    //       <span>
    //         {ApprovalTimelineContent(paymentApprovals)}
    //       </span>
    //     )
    //   }
    // },
  ];

  const disapprovePayment = async (reason: any) => {
    try {
      // const res = await paymentApis.disapprovePayment(
      //   drawerData?.paymentNumber,
      //   reason
      // );
      // const { status, message } = res;
      // if (status) {
      //   Swal.fire("Success", message, "success");
      //   fetchData();
      // }
    } catch (error) {}
  };

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="view-payments"
        title="View Receipts"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <DispatchAside updateSm={setSm} sm={sm} />
          </div>
        }
        extra={{
          tableScrollX: 1100,
        }}
      />
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Payment's Summary </strong>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {drawerData ? (
          <>
            <div className="table-responsive poTable">
              {/* <div
                style={{
                  width: screenSize - 300 + "px",
                  margin: "0px auto 15px auto",
                }}
              > */}
              <Table
                className="customTable"
                columns={drawerColumns}
                dataSource={drawerData}
                scroll={{ x: 1000 }}
                bordered
                size="middle"
                pagination={false}
              />
            </div>
            {/* </div> */}
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
      </Drawer>
      <DisapprovePayment
        isModalVisible={showRejectModal}
        setIsModalVisible={setShowRejectModal}
        reject={disapprovePayment}
      />
    </>
  );
};

const DisapprovePayment = ({
  isModalVisible,
  setIsModalVisible,
  reject,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        reject(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Reason for Rejection"
      open={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="rejectForm"
        requiredMark={false}
      >
        <Form.Item
          name="reason"
          label={undefined}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </Modal>
  );
};

export default ViewPayments;
