import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  // LoginLogTable,
  Button,
} from "../../../components/Component";

import dayjs from "dayjs";
import { Empty } from "antd";
import miscService from "../../../api/misc/misc";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import { render } from "@testing-library/react";

const ExcelExportRequestedList = () => {
  const [loginActivity, setLoginActivity] = useState([]);
  const [sm, updateSm] = useState(false);

  const columns = [
    {
      title: "File ID",
      dataIndex: "reportId",
      key: "reportId",
      width: 200,
    },
    {
      title: "File Type",
      dataIndex: "fileType",
      key: "fileType",
      width: 200,
      render: (text) => <span>Excel</span>,
    },
    {
      title: "File Name",
      dataIndex: "reportType",
      key: "reportType",
      width: 200,
    },
    {
      title: "Date & Time",
      dataIndex: "requestedDate",
      key: "requestedDate",
      width: 200,
      render: (text) => (
        <span>{dayjs(text).format("DD-MM-YYYY hh:mm:ss")}</span>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: 200,
    },
    {
      title: "File Status",
      dataIndex: "status",
      key: "status",
      width: 200,
    },
    {
      title: "File Size (KB)",
      dataIndex: "fileSize",
      key: "fileSize",
      width: 200,
    },
    {
      title: "Download Link",
      dataIndex: "filePath",
      key: "filePath",
      width: 200,
      render: (text, record) =>
        record?.status === "Processed" ? (
          <a href={text}>
            <Icon name="download" />
          </a>
        ) : null,
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="excelexport-requested-list"
      title="Excel Export Requested"
      columns={columns}
      extra={{
        tableScrollX: 1100,
      }}
    />
  );
};

export default ExcelExportRequestedList;
