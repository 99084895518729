import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../../utils/Utils";

function SalesByCustomerReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      // width:300,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/sales-by-customer-details-report/${record.customerId}`,
                {
                  state: {
                    name: record.customerName,
                  },
                }
              );
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoices count",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
      width: 180
    },
    {
      title: "Invoice Value With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      width: 180,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Invoice Value Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      width: 190,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Sales By Customer"
      moduleKey="salesByCustomerReport"
      columns={columns}
      filtersOption={{
        customer: true,
        dateRange: true,
        aging: false,
      }}
      previousPath="/view-all-sales-reports"
      extra={{
        tableScrollX: 1400,
      }}
    />
  );
}

export default SalesByCustomerReport;
