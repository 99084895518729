import React, { useState, useEffect } from "react";
import { useFormContext, FormProvider, Controller } from "react-hook-form";
import { Button, Spinner } from "reactstrap";
import { Collapse, Upload, message } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import FormTextArea from "../../form-components/TextAreaComponent";
import { AllowedFileTypes } from "../../../fileAccess";

const { Panel } = Collapse;
const { Dragger } = Upload;

const Others: React.FC<{ prev: () => void; next: () => void }> = (
  props: any
) => {
  const methods = useFormContext();
  const { setValue, watch } = methods;
  let formData: any = {
    dcAttachments: [],
    invoiceAttachments: [],
  };
  const [dcList, setDcList] = useState<UploadFile[]>(
    formData.dcAttachments || []
  );
  const [invoiceList, setInvoiceList] = useState<UploadFile[]>(
    formData.invoiceAttachments || []
  );
  const dcAttachmentsWatch = watch("dcAttachments");
  useEffect(() => {
    if (dcAttachmentsWatch) {
      setDcList(dcAttachmentsWatch);
    }
  }, [dcAttachmentsWatch]);

  const invoiceAttachmentsWatch = watch("invoiceAttachments");
  useEffect(() => {
    if (invoiceAttachmentsWatch) {
      setInvoiceList(invoiceAttachmentsWatch);
    }
  }, [invoiceAttachmentsWatch]);

  const submitForm = (data: any) => {
    props.next();
  };

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType = Object.values(AllowedFileTypes)
      .filter((value) => typeof value === "string")
      .includes(file.type as AllowedFileTypes);
    if (!isAllowedType) {
      message.error("Invalid file type!");
      return false;
    }
    if (file.size && file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 5MB!");
      return false;
    }
    return true;
  };

  return (
    <FormProvider {...methods}>
      <form
        className="content clearfix"
        onSubmit={methods.handleSubmit(submitForm)}
      >
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="Instructions (if any)" key="1">
            <FormTextArea
              name="instructions"
              label={undefined}
              placeholder="Enter Instructions"
            />
          </Panel>
          <Panel header="Terms and Conditions (if any)" key="2">
            <FormTextArea
              name="termsAndConditions"
              label={undefined}
              placeholder="Enter Terms and Conditions"
            />
          </Panel>
          <Panel header="Attachments (if any)" key="3">
            <div className="d-flex justify-content-around">
              <Controller
                name="dcAttachments"
                control={methods.control}
                render={({ field }) => (
                  <Dragger
                    beforeUpload={beforeUpload}
                    fileList={dcList}
                    maxCount={1}
                    onChange={(info: { fileList: UploadFile[] }) => {
                      setDcList(info.fileList);
                      setValue("dcAttachments", info.fileList);
                    }}
                    className="ant-upload-drag-custom w-40"
                    action="/purchase-order-new"
                    showUploadList={{ showPreviewIcon: false }}
                    itemRender={(originNode, file, fileList, actions) => {
                      return React.cloneElement(originNode, {
                        title: null, // Removes the tooltip entirely
                      });
                    }}
                  >
                    <p className="ant-upload-text">
                      Click or drag DC file to this area to upload
                    </p>
                  </Dragger>
                )}
              />
              <Controller
                name="invoiceAttachments"
                control={methods.control}
                render={({ field }) => (
                  <Dragger
                    beforeUpload={beforeUpload}
                    fileList={invoiceList}
                    maxCount={1}
                    onChange={(info: { fileList: UploadFile[] }) => {
                      setInvoiceList(info.fileList);
                      setValue("invoiceAttachments", info.fileList);
                    }}
                    className="ant-upload-drag-custom w-40"
                    action="/purchase-order-new"
                    showUploadList={{ showPreviewIcon: false }}
                    itemRender={(originNode, file, fileList, actions) => {
                      return React.cloneElement(originNode, {
                        title: null, // Removes the tooltip entirely
                      });
                    }}
                  >
                    <p className="ant-upload-text">
                      Click or drag Invoice file to this area to upload
                    </p>
                  </Dragger>
                )}
              />
            </div>
          </Panel>
        </Collapse>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </FormProvider>
  );
};

export default React.memo(Others);
