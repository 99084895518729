import React, { useState, useEffect, FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Row, Col, Button, Alert } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import Swal from "sweetalert2";
import { Icon } from "../../../components/Component";
import usersApis from "../../../api/master/users";
import FormDatePicker from "../../form-components/DatePicker";
import sequenceApis from "../../../api/master/sequence";
import dayjs from "dayjs";
interface SelectOptions {
  label: string;
  value: string;
}
const SequenceMaster: FC<{
  id?: string | null;
  formAction?: string | null;
  activeModules?: any;
  setAction?: () => void;
  toggleModelPopUp: () => void;
}> = ({ id, formAction, toggleModelPopUp, activeModules }) => {
  const [modules, setModules] = useState<SelectOptions[]>([]);
  const [module, setModule] = useState<any>({});
  const [action, setAction] = useState(formAction);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = methods;
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [states, setStates] = useState<SelectOptions[]>([]);
  const includeStateWatch = watch("includeState");
  const onSubmit = async (data: any) => {
    data.level="Org"
    delete data?.includeState;
    if (!action && !id) {
      const response = await sequenceApis.createSequence(data);
      const { status, message } = response;
      if (status) {
        Swal.fire("Created!", "Sequence has been created", "success");
        toggleModelPopUp();
      } else {
        const alertMessage: string = Array.isArray(message)
          ? message.join(", ")
          : typeof message === "object"
          ? JSON.stringify(message)
          : message;
        setAlertInfo({
          type: "error",
          message: alertMessage,
          errors: [],
        });
      }
    } else if (action === "edit") {
      const response = await sequenceApis.updateSequenceById(id, {...data,moduleId:module?.id});
      const { status, message } = response;
      if (status) {
        Swal.fire("Updated!", "Sequence has been updated", "success");
        toggleModelPopUp();
      } else {
        const alertMessage: string = Array.isArray(message)
          ? message.join(", ")
          : typeof message === "object"
          ? JSON.stringify(message)
          : message;
        setAlertInfo({
          type: "error",
          message: alertMessage,
          errors: [],
        });
      }
    }
  };

  const fetchModuleConfig = async () => {
    try {
      const res = await usersApis.fetchSequenceModules();
      const moduleConfigData = res.data;
      const filteredModuleConfigData = [...moduleConfigData].filter(
        (module: any) =>{
          return !activeModules?.includes(Number(module?.moduleID))
        }
      );
      setModules(
        filteredModuleConfigData.map((obj: any) => ({
          label: obj.moduleName,
          value: obj.moduleID.toString(),
        }))
      );
    } catch (err) {
      throw err;
    }
  };
  const fetchStatesByCountry = async () => {
    try {
      const data = await usersApis.getStatesByCountry("1");
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
    } catch (error) {}
  };
  const fetchModuleSequenceById = async (id: any) => {
    try {
      const res = await sequenceApis.fetchSequenceById(id);
      const { data, status, message } = res;
      if (status) {
        setModule({name:data.moduleName,id:data.moduleId});
        setValue("sequenceNo", data.sequenceNo);
        setValue("endDate", dayjs(data.endDate));
        setValue("prefix", data.prefix);
        // setValue("includeState", data.stateId?"true":"false");
        // setValue("stateId", data.stateId);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchModuleConfig();
  }, []);
  useEffect(() => {
    if (includeStateWatch == "true") {
      fetchStatesByCountry();
    }
  }, [includeStateWatch]);
  useEffect(() => {
    if (id) {
      fetchModuleSequenceById(id);
    }
  }, [id]);

  return (
    <>
      <h5 className="title">
        {action === "edit"
          ? `Edit ${module?.name} Sequence`
          : action === "view"
          ? "View Sequence"
          : "Create Sequence"}
      </h5>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <div style={{ marginTop: "15px" }}></div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            {action !== "edit" && (
              <div className="col-md-4">
                <FormSelect
                  name="moduleId"
                  label="Module"
                  options={modules}
                  required={true}
                />
              </div>
            )}
            <div className="col-md-4">
              <FormInput
                name="prefix"
                label="PREFIX"
                type="text"
                maxLength={20}
                placeholder="Enter Prefix"
                required={true}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                name="sequenceNo"
                label="Sequence No"
                disabled={action === "view"}
                type="Number"
                placeholder="Enter Sequence Number"
                required={true}
              />
            </div>
            {action !== "edit" && <div style={{ marginTop: "15px" }}></div>}
            <div className="col-md-4">
              <FormDatePicker
                disabled={action === "view"}
                name="endDate"
                label="End Date"
                minDate={new Date()}
                required={true}
              />
            </div>
            {action == "edit" && <div style={{ marginTop: "15px" }}></div>}

            {/* <div className="col-md-4">
              <FormSelect
                name="includeState"
                label="Include State ?"
                options={[
                  { label: "Yes", value: "true" },
                  { label: "No", value: "false" },
                ]}
                required={true}
              />
            </div>
            {includeStateWatch == "true" && (
              <div className="col-md-4">
                <FormSelect
                  name="stateId"
                  label="State"
                  placeholder="Enter State"
                  required={true}
                  options={states}
                />
              </div>
            )} */}
          </div>

          {action !== "view" ? (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      <span>{action === "edit" ? "Update" : "Submit"}</span>
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleModelPopUp();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          ) : (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <div
                      className="btn btn-primary btn-md"
                      color="primary"
                      onClick={() => setAction("edit")}
                    >
                      <Icon name="pen" />
                      <span>Edit</span>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default SequenceMaster;
