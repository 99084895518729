import axios from "../../axios";
import makeApiCall from "../master/axiosIndex";
export const getCountrys = async () => {
  try {
    const res = await axios.get("/countries");
    const { data } = res.data;
    return data;
  } catch (error) {
    return [];
  }
};

export const getStatesByCountry = async (countryId: string) => {
  try {
    const res = await axios.get(`/stateByCountry?countryId=${countryId}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    return [];
  }
};

const misc = {
  getCurrencyTypes: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/misc/currencyTypes",
    }),
  getOygTypes: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `admin/fetchCompanyTypes`,
    }),
  getTaxSlabs: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/taxSlabs`,
    }),
  getContructionsMaterialTypes: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/constructionMaterials`,
    }),
  fetchOrganizationResponseByKeys: (): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/misc/fetchOrganizationResponseByKeys`,
      data: {
        type: "Buyer",
      },
    }),
  fetchCategoryProducts: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchCategoryProducts",
      params: {
        categoryId: data,
      },
    }),
  fetchSupplierCategory: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchSupplierCategory" }),
  fetchCustomersByOrgId: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchCustomersByOrgId`,
    }),
  fetchCustomerAddressesById: (id: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchCustomerAddressesById`,
      params: {
        id,
      },
    }),
};

export default misc;
