import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import FormatDate from "../../masters/DateFormater";
import { Icon } from "../../../components/Component";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { useLocation, useNavigate, useParams } from "react-router";

function CustomerBalancesDetailReport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name;
  const columns: any = [
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/customer-balances-detail-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "GRN Date",
      dataIndex: "grnDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    // {
    //   title: "Due Type",
    //   dataIndex: "dueType",
    //   width: 100,
    //   render: (dueType: any) => (
    //     <span className="text-capitalize">{dueType}</span>
    //   ),
    // },
    // {
    //   title: "Aging",
    //   dataIndex: "aging",
    //   width: 100,
    //   render: (aging: any) => <span>{aging} days</span>,
    // },
    {
      title: "Payment Days",
      dataIndex: "aging",
      key: "aging",
      width: 160,
      render: (text: any, record: any) => {
        //  "dueType": "overDue",
        let dueType = record?.aging > 0 ? "overDue" : "due";
        const isHighlighted = dueType == "overDue";
        let placeholder = "";
        if (dueType == "overDue") {
          placeholder =
            "OVERDUE BY " + Math.abs(Number(record?.aging)) + " DAYS";
        } else {
          if (record?.dueType == "Paid") {
            placeholder = "PAID";
          } else {
            if (record?.aging == 0) {
              placeholder = "DUE TODAY";
            } else {
              placeholder =
                "DUE IN " + Math.abs(Number(record?.aging)) + " DAYS";
            }
          }
        }
        return (
          <div
            className="align-left"
            style={{
              color: isHighlighted ? "lightcoral" : "green",
              backgroundColor: isHighlighted ? "white" : "inherit",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {placeholder}
          </div>
        );
      },
    },
    {
      title: "Invoice Amount Without Tax",
      dataIndex: "invAmountWithoutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Invoice Amount With Tax",
      dataIndex: "invAmountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Overdue Amount",
      dataIndex: "overDueAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      width: 150,
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Balances, Detail Report`}
      moduleKey="customerBalancesDetailReport"
      columns={columns}
      filtersOption={{
        customer: false,
        dateRange: false,
        aging: false,
      }}
      previousPath="/customer-balances-report"
      extra={{
        tableScrollX: 1850,
        id,
      }}
    />
  );
}

export default CustomerBalancesDetailReport;
