import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PreviewAltCard,
} from "../components/Component";
import {
  FileTextOutlined, // This is the new icon for invoices
  DollarOutlined,
  ExclamationCircleOutlined,
  ShoppingCartOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  FileProtectOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { formatCurrency2, formatCurrency } from "../utils/Utils";
import { Row, Col, Card, Tooltip, Select, DatePicker } from "antd";
import QuotationsVsAwardsChart from "./dashboard/QuotationsVsAwardsChart";
import InvoiceToPaymentSummaryWidget from "./dashboard/InvoiceToPaymentSummaryWidget";
import OverdueSummaryWidget from "./dashboard/OverdueSummaryWidget";
import OutstandingPaymentsWidget from "./dashboard/OutstandingPaymentsWidget";
import dayjs from "dayjs";
import dashboardApis from "../api/master/dashboard";
import { set } from "react-hook-form";
const { Option } = Select;
const { RangePicker } = DatePicker;
const InvestHomePage = () => {
  const [sm, updateSm] = useState(false);
  const [dateRange, setDateRange] = useState("Quarter to Date");
  const [customDateRange, setCustomDateRange] = useState();

  const [qutationCount, setQutationCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [orderValue, setOrderValue] = useState(0);
  const [invoiceAndPaymentSummary, setInvoiceAndPaymentSummary] = useState({
    invoiceValue: 0,
    paymentValue: 0,
    paymentPercentage: 0,
    outstandingValue: 0,
    outstandingPercentage: 0,
    overdueValue: 0,
    overduePercentage: 0,
  });
  const [quotationsVsAwardsData, setQuotationsVsAwardsData] = useState([
    {
      month: "January",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "February",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "March",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "April",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "May",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "June",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "July",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "August",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "September",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
    {
      month: "October",
      year: "2024",
      quotations: 0,
      awards: 0,
      invoiced: 0,
    },
  ]);
  const [invoiceToPaymentData, setInvoiceToPaymentData] = useState([
    { name: "Overdue", value: 0 },
    { name: "Paid (Partially/Fully)", value: 0 },
    { name: "Outstanding", value: 0 },
    { name: "Invoiced", value: 0 },
    { name: "Dispatched", value: 0 },
  ]);

  const [overdueSummaryData, setOverdueSummaryData] = useState([
    { name: "0-15 Days", value: 0 },
    { name: "16-30 Days", value: 0 },
    { name: "31-45 Days", value: 0 },
    { name: "46-60 Days", value: 0 },
    { name: ">60 Days", value: 0 },
  ]);

  const [outstandingPaymentsData, setOutstandingPaymentsData] = useState([
    { name: "0-15 Days", value: 0 },
    { name: "16-30 Days", value: 0 },
    { name: "31-45 Days", value: 0 },
    { name: "46-60 Days", value: 0 },
    { name: ">60 Days", value: 0 },
  ]);

  const fetchSuppliersQutationCount = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await dashboardApis.fetchSuppliersQuotationCount(from, to);
      const { data, status } = res;
      if (status) {
        console.log(data);
        setQutationCount(Number(data?.quotCount));
        setOrderCount(Number(data?.ordercount));
        setOrderValue(Number(data?.totalOrder));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSupplierMonthOnMonthTrend = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await dashboardApis.fetchSupplierMonthOnMonthTrend(to);
      const { data, status } = res;
      if (status) {
        // {
        //     "year": "2023",
        //     "month": "11",
        //     "month_name": "November",
        //     "totalQuotationCount": "0",
        //     "totalQuotationValue": "0.00",
        //     "totalOrderCount": "0",
        //     "totalOrderValue": "0.000000"
        // },
        setQuotationsVsAwardsData(
          data?.map((obj) => ({
            month: obj?.month_name,
            year: obj?.year,
            quotations: Number(obj?.totalQuotationCount),
            awards:Number(obj?.totalOrderCount),
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSupplierInvoiceAndPaymentSummary = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await dashboardApis.fetchSupplierInvoiceAndPaymentSummary(
        from,
        to
      );
      const { data: _data, status } = res;
      if (status) {
        const data = _data[0];
        // {
        //     "totalInvoice": null,
        //     "totalPayments": null,
        //     "paymentPercentage": "0.0000000000",
        //     "outstandingValue": null,
        //     "outstandingPercentage": "0.0000000000",
        //     "totalOverduevalue": null,
        //     "overduePercentage": "0.0000000000"
        // }
        setInvoiceAndPaymentSummary({
          invoiceValue: Number(data?.totalInvoice || 0),
          paymentValue: Number(data?.totalPayments || 0),
          paymentPercentage: Number(data?.paymentPercentage || 0)?.toFixed(1),
          outstandingValue: Number(data?.outstandingValue || 0),
          outstandingPercentage: Number(
            data?.outstandingPercentage || 0
          )?.toFixed(1),
          overdueValue: Number(data?.totalOverduevalue || 0),
          overduePercentage: Number(data?.overduePercentage || 0)?.toFixed(1),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSupplierInvoiceToPaymentSummary = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await dashboardApis.fetchSupplierInvoiceToPaymentSummary(
        from,
        to
      );
      const { data: _data, status } = res;
      if (status) {
        const data = _data[0];
        // {
        //     "dispatched": null,
        //     "invoiced": null,
        //     "totalOutstanding": "0.000000",
        //     "totalPaid": null,
        //     "totalOverdue": null
        // }
        setInvoiceToPaymentData([
          { name: "Overdue", value: Number(data?.totalOverdue || 0) },
          {
            name: "Paid (Partially/Fully)",
            value: Number(data?.totalPaid || 0),
          },
          { name: "Outstanding", value: Number(data?.totalOutstanding || 0) },
          { name: "Invoiced", value: Number(data?.invoiced || 0) },
          { name: "Dispatched", value: Number(data?.dispatched || 0) },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSupplierPaymentOverdueSummary = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await dashboardApis.fetchSupplierPaymentOverdueSummary(
        from,
        to
      );
      const { data: _data, status } = res;
      if (status) {
        const data = _data[0];
        // {
        //     "overdueLessThan15Days": null,
        //     "overdue16To30Days": null,
        //     "overdue31To45Days": null,
        //     "overdue46To60Days": null,
        //     "overdueMoreThan60Days": null
        // }
        setOverdueSummaryData([
          {
            name: "0-15 Days",
            value: Number(data?.overdueLessThan15Days || 0),
          },
          { name: "16-30 Days", value: Number(data?.overdue16To30Days || 0) },
          { name: "31-45 Days", value: Number(data?.overdue31To45Days || 0) },
          { name: "46-60 Days", value: Number(data?.overdue46To60Days || 0) },
          { name: ">60 Days", value: Number(data?.overdueMoreThan60Days || 0) },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSupplierPaymentOutstandingSummary = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const res = await dashboardApis.fetchSupplierPaymentOutstandingSummary(
        from,
        to
      );
      const { data: _data, status } = res;
      if (status) {
        const data = _data[0];
        // {
        //     "outstandingLessThan30Days": null,
        //     "outstanding30To60Days": null,
        //     "overdue61To90Days": null,
        //     "overdue91To120Days": null,
        //     "overdueMoreThan120Days": null
        // }
        setOutstandingPaymentsData([
          {
            name: "0-30 Days",
            value: Number(data?.outstandingLessThan30Days || 0),
          },
          {
            name: "31-60 Days",
            value: Number(data?.outstanding30To60Days || 0),
          },
          { name: "61-90 Days", value: Number(data?.overdue61To90Days || 0) },
          { name: "91-120 Days", value: Number(data?.overdue91To120Days || 0) },
          {
            name: ">120 Days",
            value: Number(data?.overdueMoreThan120Days || 0),
          },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null);
    }
  };

  const handleCustomDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([dates[0], dates[1]]);
    } else {
      setCustomDateRange(null);
    }
  };

  const convertToDates = (dateType) => {
    switch (dateType) {
      case "Month to Date":
        return {
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Quarter to Date":
        return {
          from: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Year to Date":
        return {
          from: dayjs().startOf("year").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {
          from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
          to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
        };
    }
  };

  useEffect(() => {
    if (dateRange || customDateRange) {
      fetchSuppliersQutationCount();
      fetchSupplierMonthOnMonthTrend();
      fetchSupplierInvoiceAndPaymentSummary();
      fetchSupplierInvoiceToPaymentSummary();
      fetchSupplierPaymentOverdueSummary();
      fetchSupplierPaymentOutstandingSummary();
    }
  }, [dateRange, customDateRange]);

  return (
    <React.Fragment>
      <Head title="Procurex Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Procurex.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div>
                        <label style={{ fontWeight: "bold", marginRight: 8 }}>
                          Filter by Date Range:
                        </label>
                        <Select
                          defaultValue="Quarter to Date"
                          style={{ width: 200, marginRight: 16 }}
                          onChange={handleDateRangeChange}
                        >
                          <Option value="Month to Date">Month to Date</Option>
                          <Option value="Quarter to Date">
                            Quarter to Date
                          </Option>
                          <Option value="Year to Date">Year to Date</Option>
                          <Option value="Custom Date">Custom Date</Option>
                        </Select>
                        {dateRange === "Custom Date" && (
                          <RangePicker
                            value={
                              customDateRange
                                ? [
                                    dayjs(customDateRange[0].toDate()),
                                    dayjs(customDateRange[1].toDate()),
                                  ]
                                : null
                            }
                            onChange={(dates, dateStrings) =>
                              handleCustomDateChange(dates)
                            }
                            style={{ width: 300 }}
                          />
                        )}
                      </div>
                    </li>
                    {/* <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle color="transparent" className="dropdown-toggle btn btn-icon btn-primary">
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                href="#adduser"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="user-add-fill"></Icon>
                                <span>Add User</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addorder"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="coin-alt-fill"></Icon>
                                <span>Add Order</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addpage"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="note-add-fill-c"></Icon>
                                <span>Add Page</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li> */}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="d-flex justify-content-between">
            <Row className="w-30 d-flex dashboard-side-widget">
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Orders vs Quotations</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {orderCount ? orderCount : "--"}
                    </span>
                    <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                      /{qutationCount ? qutationCount : "--"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        marginLeft: "5px",
                        color: "green",
                      }}
                    >
                      
                      (
                      {orderCount && qutationCount
                        ? ((orderCount/qutationCount) * 100).toFixed(1)
                        : ""}
                      %)
                    </span>
                  </span>
                  <FileTextOutlined
                    style={{ color: "#5470C6", fontSize: 32 }}
                  />
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Order Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2(orderValue?.toString(), "INR")}
                    <Tooltip
                      title={formatCurrency(orderValue?.toString(), "INR")}
                    >
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, fontSize: 12 }}
                      />
                    </Tooltip>
                  </span>
                  <ShoppingCartOutlined
                    style={{ color: "#5470C6", fontSize: 32 }}
                  />
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Invoiced Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2(
                      invoiceAndPaymentSummary?.invoiceValue?.toString(),
                      "INR"
                    )}
                    <Tooltip
                      title={formatCurrency(
                        invoiceAndPaymentSummary?.invoiceValue?.toString(),
                        "INR"
                      )}
                    >
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, fontSize: 12 }}
                      />
                    </Tooltip>
                  </span>
                  <FileProtectOutlined
                    style={{ color: "#5470C6", fontSize: 32 }}
                  />
                  {/* <DollarOutlined style={{ color: "#5470C6", fontSize: 32 }} /> */}
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Payments Received</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2(
                      invoiceAndPaymentSummary?.paymentValue?.toString(),
                      "INR"
                    )}
                    <Tooltip
                      title={formatCurrency(
                        invoiceAndPaymentSummary?.paymentValue?.toString(),
                        "INR"
                      )}
                    >
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, fontSize: 12 }}
                      />
                    </Tooltip>
                    <span
                      style={{
                        fontSize: "14px",
                        marginLeft: "5px",
                        color: "green",
                      }}
                    >
                      (
                      {invoiceAndPaymentSummary?.paymentPercentage
                        ? invoiceAndPaymentSummary?.paymentPercentage
                        : ""}
                      %)
                    </span>
                  </span>
                  <CheckCircleOutlined
                    style={{ color: "#5470C6", fontSize: 32 }}
                  />
                  {/* <ExclamationCircleOutlined style={{ color: "#5470C6", fontSize: 32 }} /> */}
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Outstanding Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2(
                      invoiceAndPaymentSummary?.outstandingValue?.toString(),
                      "INR"
                    )}
                    <Tooltip
                      title={formatCurrency(
                        invoiceAndPaymentSummary?.outstandingValue?.toString(),
                        "INR"
                      )}
                    >
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, fontSize: 12 }}
                      />
                    </Tooltip>
                    <span
                      style={{
                        fontSize: "14px",
                        marginLeft: "5px",
                        color: "orange",
                      }}
                    >
                      (
                      {invoiceAndPaymentSummary?.outstandingPercentage
                        ? invoiceAndPaymentSummary?.outstandingPercentage
                        : ""}
                      %)
                    </span>
                  </span>
                  <PauseCircleOutlined
                    style={{ color: "#5470C6", fontSize: 32 }}
                  />
                  {/* <ShoppingCartOutlined style={{ color: "#5470C6", fontSize: 32 }} /> */}
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Overdue Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2(
                      invoiceAndPaymentSummary?.overdueValue?.toString(),
                      "INR"
                    )}
                    <Tooltip
                      title={formatCurrency(
                        invoiceAndPaymentSummary?.overdueValue?.toString(),
                        "INR"
                      )}
                    >
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, fontSize: 12 }}
                      />
                    </Tooltip>
                    <span
                      style={{
                        fontSize: "14px",
                        marginLeft: "5px",
                        color: "red",
                      }}
                    >
                      (
                      {invoiceAndPaymentSummary?.overduePercentage
                        ? invoiceAndPaymentSummary?.overduePercentage
                        : ""}
                      %)
                    </span>
                  </span>
                  <ExclamationCircleOutlined
                    style={{ color: "#5470C6", fontSize: 32 }}
                  />
                </div>
              </Card>
            </Row>
            <Row className="w-70">
              <Card className="w-100">
                <QuotationsVsAwardsChart data={quotationsVsAwardsData} />
              </Card>
            </Row>
          </div>
          <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
            <Col span={8}>
              <Card className="card-full">
                <InvoiceToPaymentSummaryWidget data={invoiceToPaymentData} />
              </Card>
            </Col>
            
            <Col span={8}>
              <Card className="card-full">
                <OutstandingPaymentsWidget data={outstandingPaymentsData} />
              </Card>
            </Col>
            <Col span={8}>
              <Card className="card-full">
                <OverdueSummaryWidget data={overdueSummaryData} />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default InvestHomePage;
