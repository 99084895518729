import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasNewNotification: false, // Initial state
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.hasNewNotification = action.payload;
    },
    clearNotification: (state) => {
      state.hasNewNotification = false; // Reset the state
    },
  },
});

export const { setNotification, clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
