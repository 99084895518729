import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  TooltipComponent,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Progress, Table, Select, Input, Flex, Spin } from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import quotationApis from "../../api/master/quotations";
import moment from "moment";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const { Option } = Select;

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
  headerStatus: string;
  orgName: string;
}

const QuotedList: FC = () => {
  const [sm, setSm] = useState(false);
  const [filterStatus, setfilterStatus] = useState<any>([]);
  const navigate = useNavigate();
  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate(`/view-quoted-details/${rfq.quotationId}`);
  };
  useEffect(() => {
    fetchStatusFilterOptions();
  }, []);

  const fetchStatusFilterOptions = async () => {
    try {
      const res = await quotationApis.fetchStatusFilterOptions();
      console.log("status");
      const { response, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = response.data;
        console.log("dateresp", dateresp);
        setfilterStatus(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 200,
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleRFQClick(record)}
          style={{ color: "#1677ff", fontWeight: "600" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "orgName",
      key: "orgName",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      // fixed: "left",
      width: 250,
      align: "left",
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`Description-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`Description-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
      align: "left",
    },
    {
      title: "Preferred Brands / Make",
      dataIndex: "preferredBrands",
      key: "preferredBrands",
      width: 200,
      // sorter: (a, b) => a.prefferedBrands.localeCompare(b.prefferedBrands),
      // align: "left",
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastSubmissionDate",
      key: "lastSubmissionDate",
      width: 200,
      align: "left",
      render: (text: string) => {
        const formattedDate = dayjs(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    // {
    //   title: "Cost Center",
    //   dataIndex: "costCenterName",
    //   key: "costCenterName",
    //   width: 250,
    //   sorter: (a, b) => a.costCenterName.localeCompare(b.costCenterName),
    //   align: "left",
    //   render: (text: string, record: any, index: any) => {
    //     return (
    //       <>
    //         <span id={`costCenterName-tooltip-${index}`}>
    //           {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
    //         </span>
    //         {text?.length > 20 && (
    //           <TooltipComponent
    //             iconClass="info-circle text-info"
    //             icon="info"
    //             id={`costCenterName-tooltip-${index}`}
    //             direction="top"
    //             text={text}
    //             containerClassName={""}
    //           />
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: "Payment Terms",
      dataIndex: "paymentTerms",
      key: "paymentTerms",
      width: 150,
      fixed: "right",
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, record) => (
        <span style={{ color: record.statusColor }}>{text}</span>
      ),
      width: 150,
      align: "left",
    },
  ];

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="quoted_view"
      title="Quotations"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <QuotationsAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Status",
          placeholder: "Select Status",
          options: filterStatus,
        }
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: false,
        excelExport: false,
      }}
    />
  );
};

export default QuotedList;
