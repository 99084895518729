import React, { useState, useEffect, FC, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import PurchaseOrderAside from "./PurchaseOrderAside";
import { FormProvider, useForm } from "react-hook-form";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Form,
  TableColumnsType,
  Input,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Flex,
  Spin,
  Empty,
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import misc from "../../api/misc/dropDowns";
import adminOrgApis from "../../api/master/adminOrg";
import EditPoModal from "./EditPoModal";
import purchaseOrderApis from "../../api/purchase-order/PurchaseOrder";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "./SignatureModal";
import dayjs from "dayjs";
import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
import ChangeFromBuyerModal from "./ChangeFromBuyerModal";
import statusMapper from "./StatusMapper";
import axios from "axios";
import { useSelector } from "react-redux";
import { formatCurrency, formatDecimal } from "../../utils/Utils";
import PdfGenerator from "./TestPDF";
import EmailCreationModal from "../EmailCreationModal";

const { Panel } = AntCollapse;

interface LineItem {
  lineNo: number;
  uuid: string;
  orderNumber: number;
  needByDate: string | null;
  materialId: number;
  hsnCode: number;
  categoryId: number;
  deliveryAddressId: number | null;
  rejectedReason: string;
  description: string;
  quantity: string;
  uomId: number;
  changeRequestQuantity: string;
  quantityReturned: string;
  quantityDelivered: string;
  unitPrice: string;
  basicAmount: string;
  discountPercentage: string;
  discount: string;
  netAmount: string;
  sgstPercentage: string;
  cgstPercentage: string;
  igstPercentage: string;
  sgst: string;
  cgst: string;
  igst: string;
  additionalCharges: string;
  grossTotal: string;
  status: string;
  deliveryStatus: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string | null;
  modifiedBy: number | null;
  archieved: number;
}

interface EventChain {
  id: number;
  orderNumber: number;
  orgId: number;
  eventType: string;
  eventName: string;
  error: string | null;
  eventDate: string;
  createdBy: number;
}

interface Item {
  orderNumber: number;
  referenceId: string;
  mode: string;
  version: number;
  orderDate: string;
  needByDateType: string;
  needByDate: string | null;
  orgId: number;
  siteId: number;
  supplierId: number;
  costCenterId: number;
  description: string;
  ledgerId: number;
  departmentId: number;
  budgetId: number;
  paymentTerms: string;
  advancePercentage: number;
  creditDays: number;
  paymentTermsDescription: string | null;
  inwardRequired: number;
  autoRelease: number;
  autoPoDoc: number;
  billingAddressId: number;
  deliveryAddressType: string;
  deliveryAddressId: string | null;
  currencyId: number;
  basicAmount: string;
  discount: string;
  netAmount: string;
  sgst: string;
  cgst: string;
  igst: string;
  additionalCharges: string;
  grossTotal: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string | null;
  modifiedBy: number | null;
  archieved: number;
  rejectedReason: string;
  approvalStatus: string;
  status: string;
  deliveryStatus: string;
  lineItems: LineItem[];
  eventChains: EventChain[];
  supplierName: string;
  siteName: string;
  costCenterName: string;
  createdUserName: string;
  createdUserId: number;
  currencyCode: string;
  currency: string;
  currencySymbol: string;
}

interface SelectOptions {
  label: string;
  value: string;
}

type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}
const ViewOrderDetails: FC<any> = ({ orderNumber, previousPath, extra }) => {
  const pdfBlobData: any = useRef(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareOptionsToggle, setShareOptionsToggle] = useState(false);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });
  const { data: currentUser } = useSelector((state: any) => state.currentUser);
  const [disableApprove, setDisableApprove] = useState(false);
  const [disableReject, setDisableReject] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [disapprovePO, setDisapprovePO] = useState<boolean>(false);
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [poStatus, setPoStatus] = useState<any>("");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const [signature, setSignature] = useState<any>([]);
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [allCurrencyData, setAllCurrencyData] = useState<CurrencyData[]>([]);
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);
  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [changeFromBuyerData, setChangeFromBuyerData] = useState<any>();
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Purchase Order"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const updatePdfBlobDataRef = (newData: any) => {
    try {
      pdfBlobData.current = newData;
    } catch (error) {}
  };

  const TriggerEmail = async (emailData: any, _methods: any) => {
    try {
      const formData = new FormData();
      formData.append("msgType", "purchaseOrderConfirmation");
      formData.append("logo", currentUser?.orgLogo);
      formData.append("email", emailData?.to);
      formData.append("cc", emailData?.cc);
      formData.append("bcc", emailData?.bcc);
      if (emailData?.attachments?.length > 0) {
        emailData?.attachments?.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          let file: any = null;
          if (index == 0) {
            file = new File(
              [pdfBlobData.current],
              OriginalData?.orderId + ".pdf",
              {
                type: "application/pdf",
              }
            );
          } else {
            file = attachment.originFileObj;
          }
          formData.append(`files`, file);
        });
      }
      const response: any = await purchaseOrderApis.sendOrderEmailNotification(
        orderNumber,
        formData
      );
      const { data, status } = response;
      if (status) {
        Swal.fire("Success", "Email has been sent successfully", "success");
        _methods.reset();
        setShowEmailModal(false);
      }
    } catch (error) {
      console.log(error);
      setShowEmailModal(false);
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const res = await purchaseOrderApis.fetchOrder(orderNumber);
      const { data, status, message } = res;
      if (status) {
        const {
          currencyCode,
          currencySymbol,
          currencyFormat,
          dms,
          signatureLabel,
          igst,
        } = data;
        setOriginalData(data);
        setPoStatus(data?.orderStatus);
        if (Number(igst) == 0) {
          setTaxConfiguration({
            taxType: "GST",
            gstType: "CGST+SGST",
            cess: [
              // {
              //   cessName: "Cess",
              //   cessType: "CGST",
              //   cessPercentage: "0",
              //   cessAmount: "0",
              // },
              // {
              //   cessName: "Cess",
              //   cessType: "SGST",
              //   cessPercentage: "0",
              //   cessAmount: "0",
              // },
            ],
          });
        } else {
          setTaxConfiguration({
            taxType: "GST",
            gstType: "IGST",
            cess: [
              // {
              //   cessName: "Cess",
              //   cessType: "IGST",
              //   cessPercentage: "0",
              //   cessAmount: "0",
              // },
            ],
          });
        }
        setCurrency({
          description: currencyCode,
          currency_code: currencyCode,
          currency_symbol: currencySymbol,
          currency_format: currencyFormat,
          example: currencyCode,
        });
        if (dms?.length > 0) {
          setDMS(dms);
          const _signature = dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: signatureLabel,
              isActive: true,
            });
          }
          const _attachments = dms?.filter(
            (item: any) => item.moduleFileType == "attachments"
          );
          if (_attachments) {
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }
        const _termsAndConditions = dms?.find(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions) {
          const data: any = await fetchHtmlContentFromUrl(
            _termsAndConditions?.filePath
          );
          setTermsAndConditions(data);
        }
        const _instructions = dms?.find(
          (item: any) => item.moduleFileType == "instructions"
        );
        if (_instructions) {
          const data: any = await fetchHtmlContentFromUrl(
            _instructions?.filePath
          );
          setInstructions(data);
        }
        setMaterialData(data);
        const LineItems = data?.lineItems?.map((item: any) => {
          const file = dms?.find((doc: any) => doc.lineNo == item.lineNo);
          if (file) {
            item.file = file;
          }
          return item;
        });
        setData(LineItems);
        setShowSubmitBtm(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const ShowAddressPopOver = ({ address }: any) => {
    return (
      <ul>
        <li>{address.addressLine1}</li>
        <li>{address.addressLine2}</li>
        <li>
          {address.city}, {address.stateName}, {address.countryName} -{" "}
          {address.pincode}
        </li>
      </ul>
    );
  };

  useEffect(() => {
    fetchOrderDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    fetchCurrencyData();
    fetchUOMOPtions();
    fetchCategoryOptions();
  }, []);

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any = "₹"
  ) => {
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  const [columns, setColumns] = useState<TableColumnsType<Item>>([
    {
      ...Table.EXPAND_COLUMN,
      fixed: "left",
    },
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Materail Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text) => (text ? dayjs(text).format("DD-MMM-YYYY") : "N/A"),
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record: any) =>
        `${Number(text).toFixed(2)} ${record?.uom}`,
    },
    {
      title: "Rate",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (text) => formatCurrency(text, ""),
    },
    {
      title: "Basic Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      render: (text) => (
        <p className="text-right">{formatCurrency(text, "")}</p>
      ),
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any, record: any) =>
            `${
              Number(record?.sgst) && Number(record?.cgst)
                ? Number(text) + "%"
                : "N/A"
            }`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {Number(record?.sgst) && Number(record?.cgst)
                  ? formatCurrency(text, "INR")
                  : "N/A"}
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any, record: any) =>
            `${
              Number(record?.sgst) && Number(record?.cgst)
                ? Number(text) + "%"
                : "N/A"
            }`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {Number(record?.sgst) && Number(record?.cgst)
                  ? formatCurrency(text, "INR")
                  : "N/A"}
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any, record: any) =>
            `${
              !Number(record?.sgst) && !Number(record?.cgst)
                ? Number(text) + "%"
                : "N/A"
            }`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {!Number(record?.sgst) && !Number(record?.cgst)
                  ? formatCurrency(text, "INR")
                  : "N/A"}
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record: any, index) =>
        [
          "Approved",
          "To be Invoiced",
          "Changes to Buyer",
          "Change request accepted by Buyer",
          "Rejected from Buyer",
        ].includes(poStatus) ? (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setEditLineRow(record);
              }}
            >
              <Icon name="edit" />
            </Button>
          </>
        ) : (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setEditLineRow(record);
              }}
            >
              <Icon name="focus" />
            </Button>
          </>
        ),
    },
  ]);

  useEffect(() => {
    if (editLineRow) {
      setAddNewRowModel(true);
      fetchMaterialDetails(editLineRow?.materialCategory);
    }
  }, [editLineRow]);
  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status } = res;
      if (status) {
        let allCurrencyData = data.map((item: any) => {
          return {
            description: item.description,
            currency_code: item.currency_code,
            currency_symbol: item.currency_symbol,
            currency_format: item.currency_format,
            example: item.example,
          };
        });
        setAllCurrencyData(allCurrencyData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id,
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategoryOptions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchAdminMaterial();
      const { data: materialCategoryOptions } =
        await adminOrgApis.fetchAdminMaterialCategory();
      if (status) {
        setMaterialCategoryOptions(data);
        setCategoryOptions(
          materialCategoryOptions.map((item: any) => {
            return {
              label: item.category,
              value: item.id,
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterialDetails = async (category: any) => {
    try {
      category = categoryOptions.find(
        (item: any) => item.value == category
      )?.label;
      let materials: any = [];
      materialCategoryOptions.forEach((item: any) => {
        if (item.category == category) {
          materials.push({
            label: item.materialName + " ( " + item.materialCode + " )",
            value: item.id,
          });
        }
      });
      setMaterialOptions(materials);
    } catch (error) {
      console.log(error);
    }
  };

  const ShowBillingAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = address;
    const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return (
      <ul>
        <li>
          <strong style={{ color: "#2263b3" }}>{deliveryLocationName}</strong>
        </li>
        <li>{addressLine1}</li>
        <li>{addressLine2}</li>
        <li>
          {[
            city,
            district?.districtName,
            state?.stateName,
            countryInfo?.country,
          ].join(",")}{" "}
          - {pincode}
        </li>
        <li>
          <strong>GSTIN</strong> : {gstin}
        </li>
        <li>
          <strong>CIN</strong> : {cin}
        </li>
        <li>
          <strong>PAN</strong> : {pan}
        </li>
        <li>
          <strong>Phone</strong> : {phoneNumber}
        </li>
      </ul>
    );
  };
  const ShowDeliveryAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = address;
    // const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return (
      <ul>
        <li>
          <strong style={{ color: "#2263b3" }}>{deliveryLocationName}</strong>
        </li>
        <li>
          {addressLine1},{addressLine2}
        </li>
        <li>
          {[
            city,
            district?.districtName,
            state?.stateName,
            countryInfo?.country,
          ].join(",")}{" "}
          - {pincode}
        </li>
        <li>
          <strong>Contact Person</strong> : {address?.contactPersonName}
        </li>
        <li>
          <strong>Mobile Number</strong> : {address?.mobileNumber}
        </li>
        {/* <li>
          <strong>GSTIN</strong> : {gstin}
        </li>
        <li>
          <strong>CIN</strong> : {cin}
        </li>
        <li>
          <strong>PAN</strong> : {pan}
        </li>
        <li>
          <strong>Phone</strong> : {phoneNumber}
        </li> */}
      </ul>
    );
  };
  const updateColumnsWithCurrencySymbol = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (
          col.dataIndex == "unitPrice" ||
          col.dataIndex == "netAmount" ||
          col.dataIndex == "amount"
        ) {
          return {
            ...col,
            render: (text: any) => (
              <div className="justify-end">
                {formatCurrency(text, currency?.currency_code)}
              </div>
            ),
          };
        }
        if (col.children) {
          let children = col.children.map((child: any) => {
            if (child.title == "Amount") {
              return {
                ...child,
                // render: (text: any) => (
                //   <div className="justify-end">
                //     {formatCurrency(
                //       text,
                //       currency?.currency_code
                //     )}
                //   </div>
                // ),
              };
            }
            return child;
          });
          return {
            ...col,
            children,
          };
        }
        return col;
      });
    });
  }, [currency]);

  useEffect(() => {
    updateColumnsWithCurrencySymbol();
  }, [currency, updateColumnsWithCurrencySymbol]);

  const updateColmnWithTaxConfiguration = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (taxConfiguration?.gstType == "IGST") {
          if (col.title == "CGST" || col.title == "SGST") {
            return {
              ...col,
              hidden: true,
            };
          }
          if (col.title == "IGST") {
            return {
              ...col,
              hidden: false,
            };
          }
        } else {
          if (col.title == "IGST") {
            return {
              ...col,
              hidden: true,
            };
          }
          if (col.title == "CGST" || col.title == "SGST") {
            return {
              ...col,
              hidden: false,
            };
          }
        }
        return col;
      });
    });
  }, [taxConfiguration]);

  useEffect(() => {
    updateColmnWithTaxConfiguration();
  }, [taxConfiguration, updateColmnWithTaxConfiguration]);

  const updateColumnsWithPoStatus = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.key == "operation") {
          return {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 80,
            render: (text: any, record: any, index: any) =>
              [
                "Approved",
                "To be Invoiced",
                "Changes to Buyer",
                "Change request accepted by Buyer",
                "Rejected from Buyer",
              ].includes(poStatus) && allowedActions?.edit ? (
                <>
                  <Button
                    size="sm"
                    color=""
                    onClick={() => {
                      setEditLineRow(record);
                    }}
                  >
                    <Icon name="edit" />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="sm"
                    color=""
                    onClick={() => {
                      setEditLineRow(record);
                    }}
                  >
                    <Icon name="focus" />
                  </Button>
                </>
              ),
          };
        }
        return col;
      });
    });
  }, [poStatus]);

  useEffect(() => {
    updateColumnsWithPoStatus();
  }, [poStatus, updateColumnsWithPoStatus]);

  const rejectPo = async (rejectedReason: string) => {
    try {
      const res = await purchaseOrderApis.rejectOrder(
        orderNumber,
        rejectedReason
      );
      const { status, message } = res;
      if (status) {
        fetchOrderDetails();
        Swal.fire("Disapproved!", message, "success");
        // navigate("/approvals-purchase-order");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableReject(false);
  };

  const ApprovePo = async () => {
    try {
      const res = await purchaseOrderApis.acceptOrder(orderNumber);
      const { status, message } = res;
      if (status) {
        fetchOrderDetails();
        Swal.fire("Approved!", message, "success");
        // navigate("/approvals-purchase-order");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableApprove(false);
  };

  const SubmitChanges = async () => {
    try {
      const _formData = new FormData();
      const updatedLineItems: any = [];
      const newLineItems: any = [];
      const deletedLineItems: any = [];

      const newfiledata: any = [];
      const deletedFileData: any = [];
      const updatedFileData: any = [];

      materialData?.lineItems?.forEach((data: any) => {
        if (data?.lineNo) {
          const OData = OriginalData?.lineItems?.find(
            (item: any) => item.lineNo == data.lineNo
          );

          let updatedItem: any = {
            lineNo: data.lineNo,
            uuid: OData.uuid,
          };
          updatedItem.materialId = Number(data.materialId);
          if (OriginalData?.needByDateType == "Line Level") {
            updatedItem.needByDate = OData.needByDate;
          }
          if (OriginalData?.deliveryAddressType == "Line Level") {
            updatedItem.deliveryAddressId = Number(data.deliveryAddressId);
          }

          // if (Number(OData.quantity) !== Number(data.quantity)) {
          //   updatedItem.quantity = Number(data.quantity);
          // }

          // if (Number(OData.unitPrice) !== Number(data.unitPrice)) {
          //   updatedItem.unitPrice = Number(data.unitPrice);
          // }

          if (
            dayjs(OData.needByDate).format("YYYY-MM-DD") !==
            dayjs(data.needByDate).format("YYYY-MM-DD")
          ) {
            updatedItem.changeRequestNeedByDate = dayjs(data.needByDate).format(
              "YYYY-MM-DD"
            );
          } else {
            updatedItem.changeRequestNeedByDate = null;
          }

          if (Number(OData.quantity) !== Number(data.quantity)) {
            updatedItem.changeRequestQuantity = Number(data.quantity);
          } else {
            updatedItem.changeRequestQuantity = null;
          }

          if (Number(OData.unitPrice) !== Number(data.unitPrice)) {
            updatedItem.changeRequestUnitPrice = Number(data.unitPrice);
          } else {
            updatedItem.changeRequestUnitPrice = null;
          }

          updatedLineItems.push(updatedItem);
        }
      });

      const existingLineItems: any = [];
      OriginalData?.lineItems?.forEach((item: any) => {
        if (item?.lineNo) {
          const obj: any = {
            lineNo: item.lineNo,
            uuid: item.uuid,
            materialId: item.materialId,
          };
          if (OriginalData?.needByDateType == "Line Level") {
            obj.needByDate = item.needByDate;
          }
          if (OriginalData?.deliveryAddressType == "Line Level") {
            obj.deliveryAddressId = item.deliveryAddressId;
          }
          existingLineItems.push(obj);
        }
      });

      const submitData: any = {
        mode: "CR",
        needByDateType: OriginalData?.needByDateType,
        deliveryAddressType: OriginalData?.deliveryAddressType,
        combinedLineItems: {
          existingLineItems,
          updatedLineItems,
          newLineItems,
          deletedLineItems,
        },
      };
      if (OriginalData?.needByDateType != "Line Level") {
        submitData.needByDate = OriginalData?.needByDate;
      }
      if (OriginalData?.deliveryAddressType != "Line Level") {
        submitData.deliveryAddressId = OriginalData?.deliveryAddressId;
      }

      if (
        dayjs(OriginalData?.needByDate).format("YYYY-MM-DD") !==
        dayjs(materialData?.needByDate).format("YYYY-MM-DD")
      ) {
        submitData.changeRequestNeedByDate = dayjs(
          materialData?.needByDate
        ).format("YYYY-MM-DD");
      }

      if (OriginalData?.needByDateType == "order Level") {
        submitData.needByDate = OriginalData?.needByDate;
      }

      const filesData = {
        newfiledata,
        deletedFileData,
        updatedFileData,
      };

      _formData.append("filesData", JSON.stringify(filesData));

      _formData.append("files", JSON.stringify([]));
      _formData.append("orderData", JSON.stringify(submitData));
      console.log("submitData", submitData);

      const res = await purchaseOrderApis.purchaseOrderUpdate(
        orderNumber,
        _formData
      );
      const { status, message } = res;
      if (status) {
        fetchOrderDetails();
        Swal.fire("Updated!", message, "success");
        // navigate("/approvals-purchase-order");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableSubmit(false);
  };
  useEffect(() => {
    if (disapprovePO == false) {
      setDisableReject(false);
    }
  }, [disapprovePO]);

  const returnBillingDetails = () => {
    const address = materialData?.billingAddress;
    if (!address) {
      return {};
    }
    const { cin, gstin, pan } = materialData?.orgDetails;
    return {
      name: materialData?.orgName || "",
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      state: address?.state?.stateName,
      pincode: address.pincode,
      country: address?.countryInfo?.countryName,
      gstin,
      pan,
      cin,
    };
  };

  const returnSupplierDetails = () => {
    let seller = OriginalData?.supplierDetails;
    if (!seller) {
      return {};
    }
    const {
      city,
      stateInfo,
      countryInfo,
      pincode,
      addressline1,
      addressline2,
    } = seller?.address || {};
    return {
      name: OriginalData?.supplierName || "",
      addressLine1: addressline1 || "",
      addressLine2: addressline2 || "",
      city: city || "",
      state: stateInfo?.stateName || "",
      pincode: pincode || "",
      country: countryInfo?.countryName || "",
      gstin: seller?.gstin || "",
      pan: seller?.pan || "",
      cin: seller?.cin || "",
    };
  };

  const returnPoDetails = () => {
    const address = materialData?.deliveryAddress;
    return {
      poNumber: OriginalData?.orderId,
      poDate: dayjs(OriginalData?.orderDate).format("DD-MMM-YYYY"),
      terms:
        materialData?.paymentTerms +
        (materialData?.advancePercentage
          ? `(${materialData?.advancePercentage}%)`
          : ""),
      expectedShipmentDate:
        materialData?.needByDateType == "Order Level"
          ? dayjs(materialData?.needByDate).format("DD-MMM-YYYY")
          : "Line Level Date",
      placeofSupply: address?.city || "--",
      contactPersonName: address?.contactPersonName,
      mobileNumber: address?.mobileNumber,
      creditDays:
        materialData?.paymentTerms != "Cash and Carry"
          ? materialData?.creditDays
          : 0,
      deliveryAddressString:
        [
          address?.addressLine1,
          address?.addressLine2,
          address?.city,
          address?.state?.stateName,
          address?.pincode?.toString(),
          address?.countryInfo?.countryName,
        ]
          ?.filter(Boolean)
          ?.join(", ") || "--",
      totals: {
        taxableAmount: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.quantity) * Number(b.unitPrice),
            0
          )
        ),
        cgst: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.cgst),
            0
          )
        ),
        sgst: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.sgst),
            0
          )
        ),
        igst: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.igst),
            0
          )
        ),
        total: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.grossTotal),
            0
          )
        ),
      },
      lines: materialData?.lineItems?.map((item: any, index: any) => {
        let itemDescription = item?.materialName;
        if (item?.description) {
          itemDescription += "\t" + item?.description;
        }
        if (materialData?.deliveryAddressType === "Line Level") {
          let _address: any = {};
          // _address = deliveryAddressList?.find(
          //   (_item: any) => _item?.id == item?.deliveryAddressId
          // );
          if (_address) {
            const addressString = [
              _address.addressLine1,
              _address.addressLine2,
              _address.city,
              _address.stateName,
              _address.pincode?.toString(),
              _address.countryName,
            ]
              .filter(Boolean)
              .join(", ");
            itemDescription += "\n\nDelivery Address: " + addressString;
          }
        }
        if (materialData?.needByDateType != "Order Level") {
          itemDescription +=
            "\n\nNeed By Date: " +
            dayjs(item?.needByDate).format("DD-MMM-YYYY");
        }
        return {
          sno: index + 1,
          itemDescription,
          hsnSac: item?.hsnCode || "",
          rate: formatDecimal(item?.unitPrice),
          cgstPercentage: Number(item?.cgstPercentage),
          cgstAmount: formatDecimal(item?.cgst),
          sgstPercentage: Number(item?.sgstPercentage),
          sgstAmount: formatDecimal(item?.sgst),
          igstPercentage: Number(item?.igstPercentage),
          igstAmount: formatDecimal(item?.igst),
          taxableAmount: formatDecimal(item?.grossTotal),
          qty: Number(item?.quantity),
          uom: item?.uomLabel,
        };
      }),
    };
  };

  return (
    <>
      <Head title="Purchase Order - Track" />
      <RejectPo
        isModalVisible={disapprovePO}
        setIsModalVisible={setDisapprovePO}
        rejectPo={rejectPo}
      />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {!extra?.hideAside && (
              <div
                className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                  sm ? "content-active" : ""
                }`}
              >
                <PurchaseOrderAside updateSm={setSm} sm={sm} />
              </div>
            )}
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>
                        {OriginalData.orderId} |{" "}
                        {(statusMapper[poStatus] &&
                          statusMapper[poStatus]?.seller?.title) ||
                          poStatus}{" "}
                      </span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <div className="d-flex">
                    {[
                      "Approved",
                      "Change request accepted by Buyer",
                      "Changes from Buyer",
                      "Rejected from Buyer",
                    ].includes(poStatus) &&
                      allowedActions?.approve == 1 &&
                      !showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableApprove ? (
                                <Button color="primary" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setDisableApprove(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to accept the Order?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, Accept it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        ApprovePo();
                                      } else {
                                        setDisableApprove(false);
                                      }
                                    });
                                  }}
                                >
                                  Accept
                                </Button>
                              )}
                            </li>
                            <li className="pt-0 pb-0">
                              {disableReject ? (
                                <Button color="danger" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="secondary"
                                  onClick={() => {
                                    setDisableReject(true);
                                    setDisapprovePO(true);
                                  }}
                                  type="button"
                                  className="btn-dim btn btn-secondary"
                                >
                                  Reject
                                </Button>
                              )}
                            </li>
                          </ul>
                        </div>
                      )}

                    {showSubmitBtm && (
                      <div className="actions clearfix">
                        <ul
                          className="m-auto d-flex align-items-center justify-content-center p-0"
                          style={{
                            flexDirection: "row-reverse",
                          }}
                        >
                          <li className="pt-0 pb-0">
                            {disableSubmit ? (
                              <Button color="primary" type="button">
                                <Spinner size="sm" color="light" />
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="button"
                                onClick={() => {
                                  setDisableSubmit(true);
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "Do you want to submit the changes?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, submit it!",
                                    cancelButtonText: "No, cancel",
                                  }).then(async (result) => {
                                    if (result.isConfirmed) {
                                      await SubmitChanges();
                                    } else {
                                      setDisableSubmit(false);
                                    }
                                  });
                                }}
                              >
                                Submit
                              </Button>
                            )}
                          </li>
                          <li className="pt-0 pb-0">
                            <Button
                              color="secondary"
                              onClick={() => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "Do you want to revert the changes?",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, revert it!",
                                  cancelButtonText: "No, cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    fetchOrderDetails();
                                    Swal.fire(
                                      "Reverted!",
                                      "Your changes have been reverted.",
                                      "success"
                                    );
                                  }
                                });
                              }}
                              type="button"
                              className="btn-dim btn btn-secondary"
                            >
                              Revert
                            </Button>
                          </li>
                        </ul>
                      </div>
                    )}
                    {!showSubmitBtm && (
                      <>
                        <PdfGenerator
                          buyerDetails={returnBillingDetails()}
                          poDetails={returnPoDetails()}
                          supplierDetails={returnSupplierDetails()}
                          buyerOrgLogo={currentUser?.orgLogo}
                          hasIGST={taxConfiguration?.gstType == "IGST"}
                          TermsAndConditions={termsAndConditions}
                          getPdfUrl={updatePdfBlobDataRef}
                        />
                        <li className="pt-0 pb-0 p-2">
                          <Dropdown
                            isOpen={shareOptionsToggle}
                            toggle={() => {
                              setShareOptionsToggle(!shareOptionsToggle);
                            }}
                          >
                            <DropdownToggle className="btn btn-white btn-outline-light">
                              <Icon name="share"></Icon>
                              <span>Send</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {/* <DropdownItem onClick={() => {}}>
                                    <Icon name="archive"></Icon>
                                    <span>Send SMS</span>
                                  </DropdownItem> */}
                              <DropdownItem
                                onClick={() => {
                                  setShowEmailModal(true);
                                }}
                              >
                                <Icon name="mail"></Icon>
                                <span>Send Mail</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </li>
                      </>
                    )}
                    {!showSubmitBtm && (
                      <Button
                        className="toggle"
                        color="primary"
                        onClick={async () => {
                          navigate("/view-purchase-order");
                          navigate(previousPath, {
                            state: { ...extra?.previousState },
                          });
                        }}
                      >
                        <Icon name="arrow-left" />
                        <span>Back</span>
                      </Button>
                    )}
                  </div>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              {isLoading ? (
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              ) : (
                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                    <Panel header="General Information" key="1">
                      <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs w-100 p-0 m-0 position-relative">
                        <li className="nav-item" onClick={() => setTab("1")}>
                          <a
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className={`nav-link${
                              tab === "1" ? " active" : ""
                            }`}
                            href="#year"
                          >
                            General Info
                          </a>
                        </li>
                        <li className="nav-item" onClick={() => setTab("2")}>
                          <a
                            href="#overview"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className={`nav-link${
                              tab === "2" ? " active" : ""
                            }`}
                          >
                            Delivery Info
                          </a>
                        </li>
                        {tab == "2" &&
                          materialData?.needByDateType == "order Level" && (
                            <span
                              className="cursor-pointer position-absolute"
                              style={{
                                right: 20,
                                top: 10,
                                fontSize: "18px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (tab === "2") {
                                  setValue(
                                    "inwardRequired",
                                    materialData?.inwardRequired
                                  );
                                  setValue(
                                    "needByDateType",
                                    materialData?.needByDateType
                                  );
                                  setValue(
                                    "needByDate",
                                    dayjs(materialData?.needByDate)
                                  );
                                  setValue(
                                    "billingAddress",
                                    materialData?.deliveryAddress
                                      ?.deliveryLocationName
                                  );
                                  setValue(
                                    "deliveryAddressType",
                                    materialData?.deliveryAddressType
                                  );
                                  setValue(
                                    "deliveryAddress",
                                    materialData?.deliveryAddress
                                      ?.deliveryLocationName
                                  );
                                }
                                setGeneralInformationEdit(true);
                              }}
                            >
                              <Icon name="edit" />
                            </span>
                          )}
                      </ul>

                      <div className="tab-content mt-0">
                        {tab === "1" && (
                          <div
                            className={`tab-pane${tab === "1" && " active"}`}
                          >
                            <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                              <div className="w-50 border-end ">
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-20">Site</div>
                                  <div className="data-value w-80 ">
                                    {materialData?.siteName}
                                  </div>
                                </div>
                                {/* <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">
                                   Contractor 
                                  </div>
                                  <div className="data-value w-70 ">
                                    {materialData?.contractorName}
                                  </div>
                                </div> */}
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-20">
                                    Customer
                                  </div>
                                  <div className="data-value w-80 ">
                                    {materialData?.orgName}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-20">
                                    Description
                                  </div>
                                  <div className="data-value w-80">
                                    {materialData?.description || "--"}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-20">
                                    Order Date
                                  </div>
                                  <div className="data-value w-80">
                                    {OriginalData?.orderDate
                                      ? dayjs(OriginalData?.orderDate)?.format(
                                          "DD-MMM-YYYY"
                                        )
                                      : "N/A"}
                                  </div>
                                </div>

                                {/* <div className="data-col align-center pt-1">
                        <div className="data-label w-30">Approvals</div>
                        <div className="data-value w-70">
                          <ApprovalTimelineContent />
                        </div>
                      </div> */}
                              </div>
                              <div className="w-30 paddingLeft10 ">
                                {/* <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Budget</div>
                        <div className="data-value w-70 ">
                          {materialData?.budgetName}
                        </div>
                      </div> */}

                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">
                                    Payment Terms
                                  </div>
                                  <div className="data-value w-70 ">
                                    <Badge color="outline-success">
                                      {materialData?.paymentTerms || "--"}
                                    </Badge>
                                  </div>
                                </div>
                                {materialData?.paymentTerms == "Advance" && (
                                  <div className="data-col align-center pt-1 ">
                                    <div className="data-label w-30">
                                      Advance
                                    </div>
                                    <div className="data-value w-70 ">
                                      <span className="change up text-danger">
                                        {materialData?.advancePercentage ||
                                          "--"}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {materialData?.paymentTerms !=
                                  "Cash and Carry" && (
                                  <div className="data-col align-center pt-1 ">
                                    <div className="data-label w-30">
                                      Credit Days
                                    </div>
                                    <div className="data-value w-70 ">
                                      <Badge color="outline-primary">
                                        {materialData?.creditDays || "--"} days
                                      </Badge>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* <div className="w-30 paddingLeft10">
                      <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Cost Center</div>
                        <div className="data-value w-70 ">
                          {materialData?.costCenterName}
                        </div>
                      </div>

                      <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Ledger</div>
                        <div className="data-value w-70 ">
                          {materialData?.ledgerName}
                        </div>
                      </div>

                      <div className="data-col align-center pt-1 ">
                        <div className="data-label w-30">Department</div>
                        <div className="data-value w-70 ">
                          {materialData?.departmentName}
                        </div>
                      </div>
                    </div> */}
                            </Col>
                          </div>
                        )}
                        {tab === "2" && (
                          <div
                            className={`tab-pane${tab === "2" && " active"}`}
                          >
                            <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                              <div className="w-25 border-end">
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-50">
                                    GRN Required
                                  </div>
                                  <div className="data-value w-50">
                                    {materialData?.inwardRequired == "true" ? (
                                      <Badge color="outline-success">Yes</Badge>
                                    ) : (
                                      <Badge color="outline-danger">No</Badge>
                                    )}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-50">
                                    Line Level Delivery
                                  </div>
                                  <div className="data-value w-50">
                                    {materialData?.needByDateType !=
                                    "Order Level" ? (
                                      <Badge color="outline-success">Yes</Badge>
                                    ) : (
                                      <Badge color="outline-danger">No</Badge>
                                    )}
                                  </div>
                                </div>

                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-50">
                                    Delivery Type
                                  </div>
                                  <div className="data-value w-50">
                                    {materialData?.deliveryAddressType || "--"}
                                  </div>
                                </div>

                                {materialData?.needByDateType ==
                                  "Order Level" && (
                                  <div className="data-col pt-1">
                                    <div className="data-label w-50">
                                      Delivery Date
                                    </div>
                                    <div className="data-value w-50">
                                      <Badge color="outline-primary">
                                        {dayjs(materialData?.needByDate).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </Badge>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="w-30  paddingLeft10">
                                <div className=" pt-1">
                                  <div className="data-label w-100 mb-1">
                                    Billing Address
                                  </div>
                                  <div className="data-value w-100">
                                    <ShowBillingAddress
                                      address={materialData?.billingAddress}
                                    />
                                  </div>
                                </div>
                              </div>
                              {materialData?.deliveryAddressId && (
                                <div className="w-30 paddingLeft10 ">
                                  <div className=" pt-1">
                                    <div className="data-label w-9 mb-1">
                                      Delivery Address
                                    </div>
                                    <div className="data-value w-90">
                                      <ShowDeliveryAddress
                                        address={materialData?.deliveryAddress}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* <div className="w-25 paddingLeft10">
                      <div className="  pt-1">
                        <div className="data-label w-90 mb-1">
                          Supplier Info
                        </div>
                        <div className="data-value w-90">
                          <ShowSupplierInfo id={materialData?.supplierId} />
                        </div>
                      </div>
                    </div>{" "} */}
                            </Col>
                          </div>
                        )}
                      </div>
                    </Panel>
                    <Panel header="Material Information" key="2">
                      <div className="table-responsive poTable">
                        <div
                          style={{
                            width:
                              screenSize -
                              (extra?.reduceTableWidth || 380) +
                              "px",
                            margin: "15px auto",
                            minHeight: "200px",
                          }}
                        >
                          <Table
                            className="customTable"
                            columns={columns}
                            dataSource={data.map((item: any) => ({
                              ...item,
                              key: item?.lineNo,
                            }))}
                            scroll={{ x: 1000 }}
                            expandable={{
                              expandedRowRender: (record: any) => (
                                <div className="expandableBody">
                                  <div style={{ flex: 1, textAlign: "left" }}>
                                    <p style={{ margin: 0 }}>
                                      {record.description}
                                    </p>
                                    {record.file && (
                                      <div>
                                        <PaperClipOutlined /> &nbsp;
                                        <a
                                          href={record?.file?.filePath}
                                          download={record?.file?.filePath}
                                        >
                                          {record?.file?.fileName}
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                  {materialData?.deliveryAddressType ===
                                    "Line Level" &&
                                    record.deliveryAddressId && (
                                      <div
                                        className="lineAddress"
                                        style={{ flex: 1 }}
                                      >
                                        <h6>Delivery Address</h6>
                                        <ShowAddressPopOver
                                          address={record.deliveryAddress}
                                        />
                                      </div>
                                    )}
                                </div>
                              ),
                            }}
                            locale={{
                              emptyText: (
                                <span
                                  style={{
                                    height: "300px",
                                    color: "#333",
                                    fontSize: "16px",
                                  }}
                                >
                                  Please click on "Add New Item" to add purchase
                                  order lines.
                                </span>
                              ),
                            }}
                            bordered
                            size="middle"
                            pagination={false}
                          />
                        </div>
                        {data?.length > 0 && (
                          <Row
                            style={{
                              margin: "20px",
                            }}
                          >
                            <Col className={`justify-end`}>
                              {/* <a
                      onClick={() => {
                        setAddNewRowModel(true);
                        setEditLineRow(null);
                      }}
                      href="#add"
                      className="addNewItem"
                    >
                      <Icon name="plus-round" />
                      <span>Add New Item</span>
                    </a> */}
                              <div className="w-40">
                                <div className="d-flex justify-content-between">
                                  <div className="w-50 justify-end">
                                    <strong>Taxable Amount</strong>
                                  </div>
                                  <span className="r_align">
                                    {formatCurrency(
                                      materialData?.lineItems?.reduce(
                                        (a: any, b: any) =>
                                          a + Number(b.netAmount),
                                        0
                                      ),
                                      currency?.currency_code
                                    )}
                                  </span>
                                </div>
                                {materialData?.lineItems?.reduce(
                                  (a: any, b: any) => a + Number(b.cgst),
                                  0
                                ) +
                                  materialData?.lineItems?.reduce(
                                    (a: any, b: any) => a + Number(b.sgst),
                                    0
                                  ) >
                                0 ? (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      <div className="w-50 justify-end">
                                        <strong>CGST</strong>
                                      </div>
                                      <span className="r_align">
                                        {formatCurrency(
                                          materialData?.lineItems?.reduce(
                                            (a: any, b: any) =>
                                              a + Number(b.cgst),
                                            0
                                          ),
                                          currency?.currency_code
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="w-50 justify-end">
                                        <strong>SGST</strong>
                                      </div>
                                      <span className="r_align">
                                        {formatCurrency(
                                          materialData?.lineItems?.reduce(
                                            (a: any, b: any) =>
                                              a + Number(b.sgst),
                                            0
                                          ),
                                          currency?.currency_code
                                        )}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <div className="d-flex justify-content-between">
                                    <div className="w-50 justify-end">
                                      <strong>IGST</strong>
                                    </div>
                                    <span className="r_align">
                                      {formatCurrency(
                                        materialData?.lineItems?.reduce(
                                          (a: any, b: any) =>
                                            a + Number(b.igst),
                                          0
                                        ),
                                        currency?.currency_code
                                      )}
                                    </span>
                                  </div>
                                )}
                                <div
                                  className="d-flex justify-content-between"
                                  style={{
                                    fontSize: "18px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div className="w-50 justify-end">
                                    <strong>Total</strong>
                                  </div>
                                  <strong className="r_align">
                                    {formatCurrency(
                                      materialData?.lineItems?.reduce(
                                        (a: any, b: any) =>
                                          a + Number(b.netAmount),
                                        0
                                      ) +
                                        materialData?.lineItems?.reduce(
                                          (a: any, b: any) =>
                                            a + Number(b.igst),
                                          0
                                        ) +
                                        materialData?.lineItems?.reduce(
                                          (a: any, b: any) =>
                                            a + Number(b.cgst),
                                          0
                                        ) +
                                        materialData?.lineItems?.reduce(
                                          (a: any, b: any) =>
                                            a + Number(b.sgst),
                                          0
                                        ),
                                      currency?.currency_code
                                    )}
                                  </strong>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row className="m-4 justify-content-end ">
                          {signature?.isActive && (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                              </div>
                              <div
                                style={{
                                  minHeight: signature?.image ? "0px" : "120px",
                                  display: signature?.image ? "block" : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {signature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={signature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>

                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {}}
                                          disabled
                                          defaultValue={
                                            signature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Other Information" key="3">
                      <Descriptions
                        title=""
                        className="custom-description-title"
                      >
                        <Descriptions.Item label="Instructions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setInstructionsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="Terms and Conditions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setTermsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>
                        {attachments?.length > 0 && (
                          <Descriptions.Item label="">
                            <div>
                              <strong className="">
                                Attachments &nbsp;
                                <PaperClipOutlined />
                              </strong>
                              <div>
                                {attachments?.map(
                                  (attachment: any, index: number) => (
                                    <div
                                      key={index}
                                      className="w-100 d-flex justify-content-between"
                                    >
                                      <div>
                                        <strong>{index + 1}.</strong>
                                        &nbsp;
                                        {attachment.name}
                                        &nbsp; &nbsp; &nbsp; &nbsp;
                                      </div>
                                      <div>
                                        <a
                                          href={attachment.link}
                                          download={attachment.link}
                                          style={{
                                            color: "black",
                                            fontSize: "18px",
                                          }}
                                        >
                                          <DownloadOutlined />
                                        </a>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    </Panel>
                  </AntCollapse>
                  {showEmailModal && (
                    <EmailCreationModal
                      modal={showEmailModal}
                      toggle={() => setShowEmailModal(!showEmailModal)}
                      sendEmail={TriggerEmail}
                      body={
                        <div className="w-100 p-2">
                          {currentUser?.orgLogo && (
                            <img
                              src={currentUser?.orgLogo}
                              alt={""}
                              width="200"
                              style={{ marginTop: "20px" }}
                            />
                          )}
                          <div
                            style={{ backgroundColor: "#fff", padding: "15px" }}
                          >
                            {/* <h2 style={{ color: "#2263b3" }}>
                              Purchase Order Accepted
                            </h2> */}
                            <p style={{ color: "#1ee0ac", fontSize: "20px" }}>
                              Dear {OriginalData?.orgName},
                            </p>
                            <p>
                              We are pleased to confirm our acceptance of your
                              Purchase Order (
                              <strong>{OriginalData?.orderId}</strong>) dated{" "}
                              {dayjs(OriginalData?.orderDate).format(
                                "DD-MMM-YYYY"
                              )}
                              . We appreciate your trust in us and are committed
                              to delivering the items as specified in the order.
                            </p>

                            <p>
                              <strong>Order Details:</strong>
                            </p>
                            <p>
                              <strong>PO Number:</strong>{" "}
                              {OriginalData?.orderId}
                              <br />
                              <strong>Delivery Date:</strong>{" "}
                              {dayjs(OriginalData?.needByDate).format(
                                "DD-MMM-YYYY"
                              )}
                              <br />
                              <strong>Delivery Address: </strong>{" "}
                              {returnPoDetails()?.deliveryAddressString}
                            </p>

                            <p>
                              Our team is already processing your order, and we
                              will keep you updated on the progress. For any
                              further details or questions, please feel free to
                              contact us at{" "}
                              <a href={`mailto:{${currentUser?.email}}}`}>
                                {currentUser?.email}
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                      }
                      defaultAttachments={Object.assign(
                        {},
                        pdfBlobData.current,
                        {
                          name: OriginalData?.orderId + ".pdf",
                          type: "application/pdf",
                        }
                      )}
                    />
                  )}
                  <AntModal
                    title="Instructions"
                    visible={instructionsVisible}
                    footer={null}
                    onCancel={() => setInstructionsVisible(false)}
                    width="60%"
                    bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
                  >
                    {instructions ? (
                      <pre>{instructions}</pre>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <Empty description="No Instructions Provided" />
                      </div>
                    )}
                  </AntModal>
                  <AntModal
                    title="Terms and Conditions"
                    visible={termsVisible}
                    footer={null}
                    onCancel={() => setTermsVisible(false)}
                    width="60%"
                    bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
                  >
                    {termsAndConditions ? (
                      <pre>{termsAndConditions}</pre>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <Empty description="No Terms and Conditions Provided" />
                      </div>
                    )}
                  </AntModal>

                  <Block>
                    {showSignatureModel && (
                      <SignatureModal
                        modal={showSignatureModel}
                        toggle={() =>
                          setShowSignatureModel(!showSignatureModel)
                        }
                        onSave={(data: string) => {
                          setSignature((pre: any) => {
                            return { ...pre, image: data };
                          });
                          setShowSignatureModel(false);
                        }}
                      />
                    )}
                    {showCropModel && (
                      <CropperComponent
                        modal={showCropModel}
                        toggle={() => setShowCropModel(!showCropModel)}
                        onSave={(data: string) => {
                          setSignature((pre: any) => {
                            return { ...pre, image: data };
                          });
                          setShowCropModel(false);
                        }}
                      />
                    )}
                    {addNewRowModel && (
                      <EditPoModal
                        modal={addNewRowModel}
                        mode={materialData?.mode}
                        closeModal={() => {
                          setAddNewRowModel(false);
                          setEditLineRow(null);
                        }}
                        status={
                          [
                            "Approved",
                            "To be Invoiced",
                            "Changes to Buyer",
                            "Change request accepted by Buyer",
                            "Rejected from Buyer",
                          ].includes(poStatus)
                            ? "edit"
                            : "view"
                        }
                        formData={{
                          action: "edit",
                          index: editLineRow?.uuid,
                          item: editLineRow,
                        }}
                        taxConfiguration={taxConfiguration}
                        append={(data: any) => {}}
                        update={(id: any, data: any) => {
                          console.log("update", id, data);
                          // UpdatePurchaseOrderItem(data, id);
                          let newLineItems = materialData?.lineItems;
                          newLineItems = newLineItems.map((item: any) => {
                            if (item.uuid == id) {
                              return {
                                ...item,
                                ...data,
                              };
                            }
                            return item;
                          });
                          setMaterialData((pre: any) => {
                            let newData = { ...pre, lineItems: newLineItems };
                            return newData;
                          });
                          setData(newLineItems);
                          setShowSubmitBtm(true);
                        }}
                        needByDateType={materialData?.needByDateType}
                        deliveryAddressType={materialData?.deliveryAddressType}
                      />
                    )}
                    {changeFromBuyerData && (
                      <ChangeFromBuyerModal
                        changeToBuyerData={changeFromBuyerData}
                        needByDateType={materialData?.needByDateType}
                        modal={changeFromBuyerData != null}
                        closeModal={() => {
                          setChangeFromBuyerData(null);
                        }}
                      />
                    )}
                  </Block>
                </div>
              )}
            </div>
          </div>
        </Card>
      </Content>

      <QuotationApprovalDrawer
        open={approvals.open}
        onClose={() => {
          setApprovals((pre) => {
            return {
              ...pre,
              open: false,
            };
          });
        }}
        onSubmit={(data: any) => {
          // call api here
          setApprovals({
            open: false,
            data: data,
          });
        }}
        setApprovalSubmissionStatus={(status: boolean) => {}}
        approvalData={approvals.data} // Pass the current approval data
      />

      <Modal
        isOpen={generalInformationEdit}
        toggle={() => setGeneralInformationEdit(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setGeneralInformationEdit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>
          {tab == "2" && (
            <>
              <h5 className="title"> Update Delivery Info</h5>
              <div style={{ marginTop: "15px" }}></div>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit((data) => {
                    setMaterialData((pre: any) => {
                      return {
                        ...pre,
                        ...data,
                      };
                    });
                    setGeneralInformationEdit(false);
                    setAddNewRowModel(false);
                    setEditLineRow(null);
                    console.log(data);
                    setShowSubmitBtm(true);
                  })}
                >
                  <Row className="gy-4 mt-1">
                    <Col lg="4">
                      <FormSelect
                        name="inwardRequired"
                        label="GRN Required ?"
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                        disabled={true}
                        placeholder="-Select-"
                        tooltipText=" If GRN (Inward Process) is required for the line items then you can select 'Yes' otherwise select 'No'."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="needByDateType"
                        disabled
                        label="Line Level Delivery ?"
                        options={[
                          { label: "Yes", value: "Line Level" },
                          { label: "No", value: "Order Level" },
                        ]}
                        placeholder="-Select-"
                        tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
                      />
                    </Col>
                    {watch("needByDateType") == "Order Level" && (
                      <Col lg="4">
                        <FormDatePicker
                          name="needByDate"
                          label="Delivery Date"
                          required={true}
                          minDate={new Date()}
                        />
                      </Col>
                    )}
                    <Col lg="4">
                      <FormInput
                        name="billingAddress"
                        label={`Billing Address`}
                        disabled
                        placeholder="-Select-"
                        tooltipText="Select the billing address for the purchase order."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="deliveryAddressType"
                        label={`Delivery Address`}
                        disabled
                        options={[
                          { label: "Order Level", value: "Order Level" },
                          { label: "Line Level", value: "Line Level" },
                        ]}
                        placeholder="-Select-"
                        tooltipText="Select the delivery address type for the purchase order."
                      />
                    </Col>
                    {watch("deliveryAddressType") === "Order Level" && (
                      <Col lg="4">
                        <FormInput
                          name="deliveryAddress"
                          label="Delivery Address"
                          placeholder="-Select-"
                          tooltipText="Select the delivery address for the purchase order."
                          disabled
                        />
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            className="btn btn-primary btn-md"
                            type="submit"
                            color="primary"
                          >
                            Save
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setGeneralInformationEdit(false);
                              reset();
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

const RejectPo = ({ isModalVisible, setIsModalVisible, rejectPo }: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        rejectPo(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <AntModal
      title="Reject Purchase Order"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Submit"
      cancelText="Cancel"
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Reason for rejection"
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
      </Form>
    </AntModal>
  );
};
export default ViewOrderDetails;
