// firebaseConfig.ts
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCTfcdLriHLvvXrIIG4WrHc-iAvwpQPVx0",
  authDomain: "pushnotifications-876c1.firebaseapp.com",
  projectId: "pushnotifications-876c1",
  storageBucket: "pushnotifications-876c1.firebasestorage.app",
  messagingSenderId: "721976851046",
  appId: "1:721976851046:web:8637d8bb57aa0c39d4cba7",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
