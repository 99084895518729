import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"; // Adjust this import based on your store configuration

interface InvoiceRequestAsideProps {
    updateSm: (value: boolean) => void; // Function to update state
    sm: boolean; // Boolean state
}

const InvoiceAside: FC<InvoiceRequestAsideProps> = ({ updateSm, sm }) => {
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    const location = useLocation();

    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "add-purchase-invoice":
                return (
                    <Link
                        to="/add-purchase-invoice"
                        className={isActive(`${process.env.PUBLIC_URL}/add-purchase-invoice`)}
                    >
                        <Icon name="plus-fill-c" />
                        <span>Create Invoice</span>
                    </Link>
                );
            case "view-invoice-list":
                return (
                    <Link
                        to="/view-invoice-list"
                        className={isActive(`${process.env.PUBLIC_URL}/view-invoice-list`)}
                    >
                        <Icon name="eye-fill" />
                        <span>View Invoices</span>
                    </Link>
                );
            case "approvals-purchase-invoice":
                return (
                    <Link
                        to="/approvals-purchase-invoice"
                        className={isActive(`${process.env.PUBLIC_URL}/approvals-purchase-invoice`)}
                    >
                        <Icon name="check-fill-c" />
                        <span>Approvals</span>
                    </Link>
                );
            case "track-purchase-invoice":
                return (
                    <Link
                        to="/track-purchase-invoice"
                        className={isActive(`${process.env.PUBLIC_URL}/track-purchase-invoice`)}
                    >
                        <Icon name="activity-round-fill" />
                        <span>Tracking</span>
                    </Link>
                );
            case "analytics-purchase-invoice":
                return (
                    <Link
                        to="/analytics-purchase-invoice"
                        className={isActive(`${process.env.PUBLIC_URL}/analytics-purchase-invoice`)}
                    >
                        <Icon name="growth-fill" />
                        <span>Analytics</span>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                        Invoice
                    </h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "add-purchase-invoice",
                        "view-invoice-list",
                        "approvals-purchase-invoice",
                        // "track-purchase-invoice",
                        // "analytics-purchase-invoice",
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default InvoiceAside;
