import makeApiCall from "./axiosIndex";
const customersApis = {
  fetchCustomersWithPagenation: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/seller/fetchCustomerWithPagenation",
      method: "get",
      params,
    }),
  verifyLegalDetails: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/verifyLegalDetails`,
      data: data,
    }),
  createCustomer: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createCustomer",
      data,
    }),

  viewCustomerDetails: (id: any): Promise<any> =>
    makeApiCall<any>({
      url: "/seller/fetchCustomerById",
      method: "get",
      params: { id: id },
    }),

  UpdateCustomerById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: "/seller/updateCustomerById",
      params: { id },
      data,
    }),
  deleteCustomerById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteCustomerById?id=${id}`,
    }),
};

export default customersApis;
