import React, { useState, useEffect } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import FormCheckbox from "../../form-components/CheckboxComponent";
import FormDatePicker from "../../form-components/DatePicker";
import usersApis from "../../../api/master/users";
import moment from "moment";
import rolesApi from "../../../api/master/roles";

interface SelectOptions {
  label: string;
  value: string;
}
const Accessibility: React.FC = (props: any) => {
  const { role } = props; // Destructure the data

  const navigate = useNavigate();

  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [data, setData] = useState<any>();
  const [formData, setFormData] = useState({
    accessibility: [],
  });

  const formWatch = watch();
  const [accessibilityKeys, setaccessibilityKeys] = useState([]);
  const updatedAccessibilityOptions = [
    {
      label: "Sub Feature",
      value: "Sub Feature",
    },
    {
      label: "Create / Add",
      value: "ADD",
    },
    {
      label: "View",
      value: "VIEW",
    },
    {
      label: "Edit / Update",
      value: "EDIT",
    },
    {
      label: " Delete / Inactive",
      value: "CANCEL",
    },
    {
      label: "Approve / Reject",
      value: "APPROVE",
    },
  ];

  const readOnlyAddModules = [
    "Dashboard",
    "Sales By Customer",
    "Sales By Material",
    "Sales Invoice Details",
    "Customer Balances",
    "Receivables Aging Summary",
    "Payments Received By Customer",
    "Delivery Note Details",
    "Awarded By Customer",
    "Purchase Order",
  ];
  const readOnlyUpdateModules = [
    "Dashboard",
    "Sales By Customer",
    "Sales By Material",
    "Sales Invoice Details",
    "Customer Balances",
    "Receivables Aging Summary",
    "Payments Received By Customer",
    "Delivery Note Details",
    "Awarded By Customer",
    "E-Way Bill",
    "IRN",
  ];
  const readOnlyRejectModules = [
    "Dashboard",
    "GRN",
    "Sales By Customer",
    "Sales By Material",
    "Sales Invoice Details",
    "Customer Balances",
    "Receivables Aging Summary",
    "Payments Received By Customer",
    "Delivery Note Details",
    "Awarded By Customer",
    "E-Way Bill",
    "IRN",
  ];
  const readOnlyDeleteModules = [
    "Dashboard",
    "Purchase Request",
    "Quotation",
    "Purchase Order",
    "Invoice",
    "GRN",
    "Payments",
    "Credit Note",
    "Sales By Customer",
    "Sales By Material",
    "Sales Invoice Details",
    "Customer Balances",
    "Receivables Aging Summary",
    "Payments Received By Customer",
    "Delivery Note Details",
    "Awarded By Customer",
    "E-Way Bill",
    "IRN",
  ];

  const submitForm = (data: any) => {
    const accessibility: {
      module: string;
      add: boolean;
      view: boolean;
      edit: boolean;
      cancel: boolean;
      approve: boolean;
      reject: boolean;
    }[] = [];
    accessibilityKeys.forEach((key: SelectOptions) => {
      accessibility.push({
        module: key.value,
        add: data[`${key.value}_add`],
        view: data[`${key.value}_view`],
        edit: data[`${key.value}_edit`],
        cancel: data[`${key.value}_cancel`],
        approve: data[`${key.value}_approve`],
        reject: data[`${key.value}_approve`],
      });
      delete data[`${key.value}_add`];
      delete data[`${key.value}_view`];
      delete data[`${key.value}_edit`];
      delete data[`${key.value}_cancel`];
      delete data[`${key.value}_approve`];
      delete data[`${key.value}_reject`];
      delete data[`${key.value}_create`];
    });
    data.accessebility = accessibility;
   
    if(!data.prefferedStates){
      data.prefferedStates = []
    }
    console.log("::::::",data);
    // return

    data.DOB = moment(data.DOB).format("YYYY-MM-DD");
    data.DOJ = moment(data.DOJ).format("YYYY-MM-DD");
    console.log("1111", data);

    createUser(data);
    // Add your API submission logic here
  };

  const createUser = async (data: any) => {
    delete data.reportingManagerRoleName;
    delete data.departmentName;
    const response = await usersApis.createBuyer(data);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "User has been created", "success");
      navigate(`/users`);
    } else {
      console.log("Error in form submission", message, response);
      let errorMessage = "An error occurred";
      let fullErrorMessage;
      if (typeof message == "string") {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
      } else {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${response?.message?.join(
          ", "
        )}`;
      }
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const data = res.data;
      setaccessibilityKeys(
        data.map((obj: any) => ({
          label: obj.moduleName,
          value: obj.moduleID.toString(),
          isReport: obj.isReport,
          reportType: obj.reportType,
          isSupplier: obj.isSupplier,
          isMaster : obj.isMaster
        }))
      );
    } catch (err) {
      throw err;
    }
  };

  const fetchRoleById = async (id: any, Data: any) => {
    try {
      const { data, status, message } = await rolesApi.fetchRoleById(id);
      if (status) {
        const newData = data;
        setData(newData); // Update data with fetched response
        setFormData({
          accessibility: [],
        });
        data?.accessibility?.forEach((moduleObj: any) => {
          let moduleName = moduleObj?.module?.toString();
          setValue(
            `${moduleName}_add`,
            Data[`${moduleObj?.module}_add`]
              ? Data[`${moduleObj?.module}_add`]
              : moduleObj.add
          );
          setValue(
            `${moduleName}_view`,
            Data[`${moduleObj?.module}_view`] ?? moduleObj.view
          );
          setValue(
            `${moduleName}_edit`,
            Data[`${moduleObj?.module}_edit`] ?? moduleObj.edit
          );
          setValue(
            `${moduleName}_cancel`,
            Data[`${moduleObj?.module}_cancel`] ?? moduleObj.cancel
          );
          setValue(
            `${moduleName}_approve`,
            Data[`${moduleObj?.module}_approve`]
              ? Data[`${moduleObj?.module}_approve`]
              : moduleObj.approve
          );
          setValue(
            `${moduleName}_reject`,
            Data[`${moduleObj?.module}_reject`]
              ? Data[`${moduleObj?.module}_reject`]
              : moduleObj.reject
          );
        });
        // setValue("roleName", data.roleName);
        // setValue("description", data.description);
        // setValue("deptID", data?.deptID?.toString());
        // setValue("referenceID", data.referenceID);
        // setGIModal(false);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchModuleConfig();
    console.log("formWatch", formWatch);
    if (formWatch.role) {
      fetchRoleById(formWatch.role, formWatch);
    }
  }, []);

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <table className="table table-hover" id="accessesbility">
            <thead>
              <tr>
                {updatedAccessibilityOptions.map((item: SelectOptions) => {
                  return <th key={item.value}>{item.label}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {(() => {
                const reportGroups = accessibilityKeys.reduce(
                  (acc: any, item: any) => {
                    if (
                      item.isReport === "true" &&
                      item.isSupplier === "true"
                    ) {
                      if (!acc[item.reportType]) {
                        acc[item.reportType] = [];
                      }
                      acc[item.reportType].push(item);
                    }
                    return acc;
                  },
                  {}
                );
                return (
                  <>
                    {accessibilityKeys
                      .filter(
                        (item: any) =>
                          item.isReport !== "true" && item.label !== "Reports" && item.isMaster === "true"
                      )
                      .map((item: any, index: number) => (
                        <tr key={`item-${index}`}>
                          <td>{item.label}</td>
                          <td>
                            <FormCheckbox
                              disabled={readOnlyAddModules.includes(item.label)}
                              name={`${item.value}_add`}
                              optionLabel=""
                              noGap={true}
                              size={"small"}
                            />
                          </td>
                          <td>
                            <FormCheckbox
                              name={`${item.value}_view`}
                              optionLabel=""
                              noGap={true}
                              size={"small"}
                            />
                          </td>
                          <td>
                            <FormCheckbox
                              disabled={readOnlyUpdateModules.includes(
                                item.label
                              )}
                              name={`${item.value}_edit`}
                              optionLabel=""
                              noGap={true}
                              size={"small"}
                            />
                          </td>
                          <td>
                            <FormCheckbox
                              disabled={readOnlyDeleteModules.includes(
                                item.label
                              )}
                              name={`${item.value}_cancel`}
                              optionLabel=""
                              noGap={true}
                              size={"small"}
                            />
                          </td>
                          <td>
                            <FormCheckbox
                              disabled={readOnlyRejectModules.includes(
                                item.label
                              )}
                              name={`${item.value}_approve`}
                              optionLabel=""
                              noGap={true}
                              size={"small"}
                            />
                          </td>
                        </tr>
                      ))}

                    {/* Render reports grouped by reportType */}
                    {Object.keys(reportGroups).map((reportType, index) => (
                      <React.Fragment key={`report-type-${index}`}>
                        <tr>
                          <td colSpan={6}>
                            <h4
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                color: "black",
                              }}
                            >
                              {reportType || "Other Reports"}
                            </h4>
                          </td>
                        </tr>

                        {reportGroups[reportType].map(
                          (item: any, idx: number) => (
                            <tr key={`report-item-${idx}`}>
                              <td>{item.label}</td>
                              <td>
                                <FormCheckbox
                                  disabled={readOnlyAddModules.includes(
                                    item.label
                                  )}
                                  name={`${item.value}_add`}
                                  optionLabel=""
                                  noGap={true}
                                  size={"small"}
                                />
                              </td>
                              <td>
                                <FormCheckbox
                                  name={`${item.value}_view`}
                                  optionLabel=""
                                  noGap={true}
                                  size={"small"}
                                />
                              </td>
                              <td>
                                <FormCheckbox
                                  disabled={readOnlyUpdateModules.includes(
                                    item.label
                                  )}
                                  name={`${item.value}_edit`}
                                  optionLabel=""
                                  noGap={true}
                                  size={"small"}
                                />
                              </td>
                              <td>
                                <FormCheckbox
                                  disabled={readOnlyDeleteModules.includes(
                                    item.label
                                  )}
                                  name={`${item.value}_cancel`}
                                  optionLabel=""
                                  noGap={true}
                                  size={"small"}
                                />
                              </td>
                              <td>
                                <FormCheckbox
                                  disabled={readOnlyRejectModules.includes(
                                    item.label
                                  )}
                                  name={`${item.value}_approve`}
                                  optionLabel=""
                                  noGap={true}
                                  size={"small"}
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </>
                );
              })()}
            </tbody>
          </table>
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default Accessibility;
