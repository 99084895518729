import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Button } from "reactstrap";
import { TableColumnsType, Table, Input, Flex, Spin, Empty } from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import InvoiceAside from "./InvoiceAside";
import invoiceApis from "../../api/Invoice/invoice";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";

const PathToTitleMap: any = {
  "/view-e-invoice-list": "Invoices without e-way bills",
  "/view-e-invoiced-list": "Invoices with e-way bills",
  "/view-e-invoice-cancelled-list": "Cancelled Invoices",
  "/view-e-invoice-expired-list": "Expired Invoices",
  "/view-e-invoice-excluded-list": "Excluded Invoices",
};

const VieweInvoiceList: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      // fixed: "left",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "IRN #",
      dataIndex: "irn",
      key: "irn",
      hidden: ![
        "/view-e-invoiced-list",
        "/view-e-invoice-cancelled-list",
        "/view-e-invoice-expired-list",
      ]?.includes(window.location.pathname),
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`irn-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`irn-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
      // width: 200,
      // render: (text: any) => (
      //   <div className="align-left">
      //     <span>{text}</span>
      //   </div>
      // ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    // {
    //   title: " Date",
    //   dataIndex: "orderDate",
    //   key: "orderDate",
    //   width: 120,
    //   fixed: "left",
    //   render: (date) => <span>{FormatDate(date)}</span>,
    // },
    {
      title: "Customer",
      dataIndex: "orgName",
      key: "orgName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    // {
    //   title: "Delivery Date",
    //   dataIndex: "needByDate",
    //   key: "needByDate",
    //   width: 200,
    //   render: (date) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    // },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "GRN Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
      render(value: any, record: any, index: any) {
        const grnStatus: any = {
          FullyDelivered: "Fully Delivered",
          PartiallyDelivered: "Partially Delivered",
        };
        return <p className="tb-status">{grnStatus[value] || value}</p>;
      },
    },
    {
      title: "Payment Days",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 130,
      render: (text: any, record: any) => {
        const paymentStatus: any = {
          FullyPaid: "FULLY PAID",
          PartiallyPaid: "PARTIALLY PAID",
        };
        if (text == "Pending") {
          const { days } = record?.due;
          if (days <= 0) {
            return (
              <p
                style={{ color: "blue", fontSize: "14px" }}
                className="tb-status"
              >
                {" "}
                DUE IN {days * -1} DAYS
              </p>
            );
          }
          return (
            <p className="text-danger"> OVERDUE BY {record?.due?.days} DAYS</p>
          );
        }
        return (
          <p style={{ color: "green", fontSize: "14px" }} className="tb-status">
            {paymentStatus[text] || text}
          </p>
        );
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   fixed: "right",
    //   hidden: [
    //     "/view-e-invoiced-list",
    //     "/view-e-invoice-cancelled-list",
    //     "/view-e-invoice-expired-list",
    //   ]?.includes(window.location.pathname),
    //   width: 160,
    //   render: (text: any, record: any) => (
    //     <>
    //       <Button
    //         size="sm"
    //         color="success"
    //         onClick={() => {
    //           Swal.fire({
    //             title: "Are you sure?",
    //             text: "You won't be able to revert this!",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Yes, Exclude This Invoice !",
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //             }
    //           });
    //         }}
    //         className="mx-1"
    //       >
    //         Exclude
    //       </Button>
    //       <>
    //         <Button
    //           size="sm"
    //           color=""
    //           onClick={() => {
    //             navigate(`/generate-e-invoice/${record.invoiceNumber}`, {
    //               state: { previousPath: window?.location?.pathname },
    //             });
    //           }}
    //         >
    //           <Icon name="focus" />
    //         </Button>
    //       </>
    //     </>
    //   ),
    // },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      // hidden: ![
      //   "/view-e-invoiced-list",
      //   "/view-e-invoice-cancelled-list",
      //   "/view-e-invoice-expired-list",
      // ]?.includes(window.location.pathname),
      width: 80,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/generate-e-invoice/${record.invoiceNumber}`, {
                state: { previousPath: window?.location?.pathname },
              });
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  // const fetchAllInvoices = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await invoiceApis.fetchAllInvoicesByFlag({
  //       eInvoiceStatus: true,
  //       eInvoiceCancelStatus: false,
  //       ewbStatus: [
  //         "/view-e-invoiced-list",
  //         "/view-e-invoice-cancelled-list",
  //         "/view-e-invoice-expired-list",
  //       ]?.includes(window.location.pathname),
  //       ewbCancelStatus: ["/view-e-invoice-cancelled-list"]?.includes(
  //         window.location.pathname
  //       ),
  //       ewbExpiry: ["/view-e-invoice-expired-list"]?.includes(
  //         window.location.pathname
  //       ),
  //       exact: ["/view-e-invoiced-list"]?.includes(window.location.pathname),
  //     });
  //     const { data, status, message } = res;
  //     if (status) {
  //       setOriginalData(data);
  //       setFilteredData(data);
  //     } else {
  //       Swal.fire("Error", message, "error");
  //     }
  //     setIsLoading(false);
  //   } catch (error) {}
  // };

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="eInvoice"
      title={PathToTitleMap[window.location.pathname]}
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <InvoiceAside updateSm={setSm} sm={sm} />
        </div>
      }
      extra={{
        tableScrollX: 1100,
      }}
    />
  );

  // return (
  //   <>
  //     <Head title="All Invoices - View" />
  //     <Content>
  //       <Card className="card-bordered">
  //         <div className="card-aside-wrap">
  //           <div
  //             className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
  //               sm ? "content-active" : ""
  //             }`}
  //           >
  //             <InvoiceAside updateSm={setSm} sm={sm} />
  //           </div>
  //           <div className="card-inner card-inner-lg">
  //             {sm && mobileView && (
  //               <div
  //                 className="toggle-overlay"
  //                 onClick={() => setSm(!sm)}
  //               ></div>
  //             )}
  //             <BlockHead size="lg">
  //               <BlockBetween>
  //                 <BlockHeadContent>
  //                   <BlockTitle tag="h4">
  //                     {PathToTitleMap[window.location.pathname]}
  //                   </BlockTitle>
  //                 </BlockHeadContent>
  //                 <BlockHeadContent className="align-self-start d-lg-none">
  //                   <Button
  //                     className={`toggle btn btn-icon btn-trigger mt-n1 ${
  //                       sm ? "active" : ""
  //                     }`}
  //                     onClick={() => setSm(!sm)}
  //                   >
  //                     <Icon name="menu-alt-r" />
  //                   </Button>
  //                 </BlockHeadContent>
  //               </BlockBetween>
  //             </BlockHead>
  //             {isLoading == true ? (
  //               <>
  //                 <Flex align="center" gap="middle" className="pageLoader">
  //                   <Spin size="large" className="m-auto" />
  //                 </Flex>
  //               </>
  //             ) : originalData?.length == 0 ? (
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   justifyContent: "center",
  //                   alignItems: "center",
  //                   height: "200px",
  //                 }}
  //               >
  //                 <Empty description="No Invoices Found" />
  //               </div>
  //             ) : (
  //               <div>
  //                 <Block>
  //                   <div
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "space-between",
  //                       alignItems: "center",
  //                       marginBottom: "20px",
  //                     }}
  //                   >
  //                     <div></div>
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         alignItems: "center",
  //                         width: "400px",
  //                       }}
  //                     >
  //                       <Input
  //                         placeholder="Search by Site Name &amp; Invoice Number"
  //                         value={searchText}
  //                         onChange={handleSearchChange}
  //                         prefix={<SearchOutlined />}
  //                       />
  //                     </div>
  //                   </div>
  //                 </Block>
  //                 {filteredData.length == 0 ? (
  //                   <div
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       height: "200px",
  //                     }}
  //                   >
  //                     <Empty description="No Data Found for this search" />
  //                   </div>
  //                 ) : (
  //                   <div
  //                     style={{
  //                       width: screenSize - 330 + "px",
  //                       minHeight: "200px",
  //                       marginTop: "20px",
  //                     }}
  //                   >
  //                     <Table
  //                       className="customTable"
  //                       columns={columns.map((item: any, index: any) => ({
  //                         ...item,
  //                         index,
  //                       }))}
  //                       dataSource={filteredData}
  //                       pagination={{
  //                         pageSize: 10,
  //                         showSizeChanger: true,
  //                         pageSizeOptions: ["10", "20", "30", "50"],
  //                       }}
  //                       bordered
  //                       size="middle"
  //                       scroll={{ x: 1400 }}
  //                     />
  //                   </div>
  //                 )}
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </Card>
  //     </Content>
  //   </>
  // );
};

export default VieweInvoiceList;
