import makeApiCall from "./axiosIndex";

const sequenceApis = {
    createSequence: (data: any): Promise<any> => makeApiCall<any>({
        method: "post",
        url: "/seller/createSequence",
        data
    }),
    fetchSequence: (): Promise<any> => makeApiCall<any>({
        method: "get",
        url: "/seller/fetchSequence"
    }),
    fetchSequenceById: (id: any): Promise<any> => makeApiCall<any>({
        method: "get",
        url: "/seller/fetchSequenceById",
        params: { deptId:id }
    }),
    updateSequenceById: (id: any, data: any): Promise<any> => makeApiCall<any>({
        method: "put",
        url: "/seller/updateSequenceById",
        params: { id },
        data
    }),
    updateSequenceStatusById: (id: any): Promise<any> => makeApiCall<any>({
        method: "put",
        url: "/seller/updateSequenceStatusById",
        params: { id },
    }),
    deleteSequenceById: (id: any): Promise<any> => makeApiCall<any>({
        method: "delete",
        url: "/seller/deleteSequenceById",
        params: { id }
    })
};
export default sequenceApis
