import React, { useEffect, useState, useCallback } from "react";
import { useFormContext, FormProvider } from "react-hook-form";
import { Row, Col, Button, Alert } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import usersApis from "../../../api/master/users";
import { misc } from "../../../api/misc/miscService";
import sites from "../../../api/master/sites";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Block, Icon } from "../../../components/Component";
interface SelectOptions {
  label: string;
  value: string;
}
const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const navigate = useNavigate();

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const selectedCountry = watch("country");
  const BillingEntityOptions = [
    {
      value: "true",
      label: "Yes",
    },
    {
      value: "false",
      label: "No",
    },
  ];

  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    delete data.Blocks;
    delete data.plannedEndDate;
    delete data.plannedStartDate;
    delete data.currentStatus;
    delete data.name;
    createSites(data);
  };

  const createSites = async (data: any) => {
    const response = await sites.createSite(data);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Sites has been created", "success");
      navigate(`/sites`);
    } else {
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const [categories, setCategories] = useState<SelectOptions[]>([
    { label: "Category1", value: "Category1" },
    { label: "Category2", value: "Category2" },
  ]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([
    { label: "Supplier", value: "Supplier" },
    { label: "Customer", value: "Customer" },
  ]);

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await sites.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onStateChange = (e: any) => {
    console.log("e", e);
    getDistricts(e);
  };

  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data.data.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (!country) {
          return;
        }
        setValue("country", id);
      }
    } catch (error) {}
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCountrys();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);
  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="Location ID"
              required={true}
              type="text"
              pattern={/^[a-zA-Z0-9./_-]+$/}
              maxLength={10}
              placeholder="Enter Location ID"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="locationType"
              label="Select Location Type"
              required={true}
              options={[
                { label: "Plant", value: "Plant" },
                { label: "Ware House", value: "Ware House" },
              ]}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="locationName"
              label="Location Name"
              required={true}
              type="text"
              placeholder="Enter Location Name"
            />
          </Col>

          <Col lg="4">
            <FormSelect
              name="country"
              label="Country"
              options={countries}
              required={true}
              placeholder="Select Country"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="state"
              label="State"
              options={states}
              required={true}
              placeholder="Select State"
              onChange={(ev) => onStateChange(ev)}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="district"
              label="District"
              options={districtOptions}
              required={true}
              placeholder="Select District"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="city"
              label="City"
              required={true}
              placeholder="Enter City"
              type="text"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="addressLine1"
              label="Address Line 1"
              required={true}
              placeholder="Enter Site Address"
              type="text"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="addressLine2"
              label="Address Line 2"
              // required={true}
              placeholder="Enter Address Line 2"
              type="text"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="pincode"
              label="Zip/Pincode"
              required={true}
              type="text"
              pattern={/^\d+$/} // Allows only digits
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
              }}
              placeholder="Enter Zip or pin code"
            />
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
